import React from "react";
import CustomModal from "../../components/Modal";
import { useForm } from "react-hook-form";
import Input, { DateInput, NumberInput } from "../../components/Input";
import Icon from "../../components/Icon";

const ModalCaja = ({ ejerState, modalCaja, setModalCaja, handleSubmitCaja }) => {
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitCaja(data));

  const disabled = !(ejerState === 1);
  return (
    <CustomModal
      show={modalCaja.show}
      setModal={setModalCaja}
      title={modalCaja.action === "ADD" ? "Agregar Caja" : `Editar ${modalCaja.data.CAJA}`}
      handleSubmit={onSubmit}
      size="md"
    >
      <form onSubmit={onSubmit}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "0 20px" }}>
          <Input
            label="Nombre"
            name="CAJA"
            errors={errors}
            errMsg="Nombre requerido"
            autoFocus
            value={modalCaja.data.CAJA}
            register={register({ required: true })}
            disabled={disabled}
          />
          <Input
            label="Descripcion"
            name="DESCRIPCION"
            value={modalCaja.data.DESCRIPCION}
            register={register}
            disabled={disabled}
          />
          <Input
            label="Titular"
            name="TITULAR"
            value={modalCaja.data.TITULAR}
            register={register}
            disabled={disabled}
          />
          <Input
            label="Nro de Cuenta"
            name="CUENTA"
            value={modalCaja.data.CUENTA}
            register={register}
            disabled={disabled}
          />
          <DateInput
            label="Inicio"
            name="INICIO"
            errors={errors}
            errMsg="Inicio requerido"
            value={modalCaja.data.INICIO}
            register={register({ required: true })}
            disabled={disabled}
          />
          <DateInput
            label="Cierre"
            name="CIERRE"
            errors={errors}
            errMsg="Inicio requerido"
            value={modalCaja.data.CIERRE}
            register={register}
            disabled={disabled}
          />
          <div style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <label
              style={{
                fontSize: 12,
                marginTop: -20,
                marginBottom: 10,
                display: "block",
                color: "#828282",
              }}
            >
              <Icon icon="exclamation" /> Fecha de inicio tiene que estar incluida en algun ejercicio
            </label>
          </div>
          <div style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
            <Input
              label="CBU"
              name="CBU"
              errors={errors}
              errMsg="El CBU debe tener 22 digitos"
              value={modalCaja.data.CBU}
              register={register({ maxLength: 22, minLength: 22 })}
              disabled={disabled}
            />
          </div>
          <div style={{ gridColumnStart: 1, gridColumnEnd: -1, marginTop: 20 }}>
            <Input label="Alias" name="ALIAS" value={modalCaja.data.ALIAS} register={register} disabled={disabled} />
          </div>
          <div style={{ gridColumnStart: 1, gridColumnEnd: -1, marginTop: 20 }}>
            <NumberInput
              label="Saldo Inicial"
              name="SALDO_INICIAL"
              value={modalCaja.data.SALDO_INICIAL}
              register={register}
              disabled={disabled || modalCaja.action === "EDIT"}
            />
          </div>
          <input type="submit" style={{ display: "none" }} />
        </div>
      </form>
    </CustomModal>
  );
};

export default ModalCaja;
