import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import customDate from "../../hooks/customDate";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import authFetch from "../../hooks/authFetch";
import Modal from "./Modal";
import ModalVinculation from "./ModalVinculation";
import { useToasts } from "react-toast-notifications";
import customNumber from "../../hooks/customNumber";
import Input from "../../components/Input";
import ModalInfo from "./ModalInfo";
import { confirmAlert } from "../../components/Confirm";

const Proveedores = ({ match, edificios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const edificio = edificios.find((ed) => ed.ID_EDIF === edif) || {};

  const [proveedores, setProveedores] = useState([]);
  const [modal, setModal] = useState({ show: false, action: false, data: {} });
  const [modalInfo, setModalInfo] = useState({ show: false, action: false, data: {} });
  const [modalVinculation, setModalVinculation] = useState({ show: false, action: false, data: {} });
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    authFetch(`/proveedores/${edif}`).then((data) => setProveedores(data));
  }, [edif]);

  const handleSubmitProveedor = (formData) => {
    switch (modal.action) {
      case "ADD":
        authFetch("/proveedores", { method: "POST", body: formData })
          .then((data) => authFetch(`/proveedores/${edif}`))
          .then((data) => setProveedores(data))
          .catch((err) => {
            console.log(err);
            addToast("Error al crear Proveedor!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/proveedores/${modal.data.ID_PROV}`, { method: "PUT", body: formData })
          .then((data) => authFetch(`/proveedores/${edif}`))
          .then((data) => setProveedores(data))
          .catch((err) => {
            console.log(err);
            addToast("Error al editar Proveedor!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      default:
        break;
    }
  };

  const handleDelete = (prov) => {
    authFetch("/proveedores/" + prov, { method: "DELETE" })
      .then((data) => authFetch(`/proveedores/${edif}`))
      .then((data) => setProveedores(data))
      .catch((err) => {
        console.log(err);
        addToast("Error al borrar proveedor!", { appearance: "error" });
      });
  };

  const handleReactive = (prov) => {
    authFetch("/proveedores/reactive/" + prov, { method: "PUT" })
      .then((data) => authFetch(`/proveedores/${edif}`))
      .then((data) => setProveedores(data))
      .catch((err) => {
        console.log(err);
        addToast("Error al reactivar proveedor!", { appearance: "error" });
      });
  };

  const handleSubmitVinculation = (formData) => {
    console.log(modalVinculation, formData);
    switch (modalVinculation.action) {
      case "VINCULAR":
        authFetch("/proveedores/vinculacion", {
          method: "POST",
          body: { ID_EDIF: edif, ID_PROV: modalVinculation.data.ID_PROV, ...formData },
        })
          .then((data) => authFetch(`/proveedores/${edif}`))
          .then((data) => setProveedores(data))
          .catch((err) => {
            console.log(err);
            addToast("Error al vincular Proveedor!", { appearance: "error" });
          });
        setModalVinculation((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        // authFetch(`/proveedores/vinculacion/${setModalVinculation}`, { method: "PUT", body: formData })
        //   .then((data) => authFetch(`/proveedores/${edif}`))
        //   .then((data) => setProveedores(data))
        //   .catch((err) => {
        //     console.log(err);
        //     addToast("Error al editar Proveedor!", { appearance: "error" });
        //   });
        // setModalVinculation((prev) => ({ ...prev, show: false }));
        break;
      default:
        break;
    }
  };

  const handleDeleteVinculo = (data) => {
    authFetch(`/proveedores/unlink/${data.ID_EDIF}/${data.ID_PROV}`)
      .then((data) => authFetch("/proveedores/" + edif))
      .then((data) => setProveedores(data))
      .catch((err) => {
        console.log(err);
        addToast("Error al desvincular!", { appearance: "error" });
      });
    setModalVinculation((prev) => ({ ...prev, show: false }));
  };

  const dataTable = (
    search
      ? proveedores.filter(
          (i) =>
            (i.NOMBRE || "").toString().toLowerCase().includes(search.toLowerCase()) ||
            (i.DESCRIPCION || "").toString().toLowerCase().includes(search.toLowerCase()) ||
            (i.TITULO_DEF || "").toString().toLowerCase().includes(search.toLowerCase()) ||
            (i.MAIL || "").toString().toLowerCase().includes(search.toLowerCase())
        )
      : proveedores
  ).map((row, i) => {
    return {
      className: row.ESTADO === 0 ? "finalizado" : row.ESTADO === -1 ? "eliminado" : "",
      cells: [
        {
          className: "actions-col",
          content: (
            <Actions
              row={row}
              setModal={setModal}
              setModalVinculation={setModalVinculation}
              handleDelete={handleDelete}
              handleReactive={handleReactive}
              setModalInfo={setModalInfo}
            />
          ),
        },
        { className: "", content: row.NOMBRE },
        { className: "", content: row.DESCRIPCION },
        { className: "", content: row.TITULO_DEF || row.TITULO || "" },
        { className: "", content: row.MAIL },
        { className: "text-center", content: row.TELEFONO },
        { className: "text-right", content: customNumber(row.SUMA_SALDO_REST), order: row.SALDO },
      ],
    };
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight={830}
          header={
            <Header
              setModal={setModal}
              setFilter={setFilter}
              filter={filter}
              edif={edif}
              search={search}
              setSearch={setSearch}
            />
          }
        >
          <Table
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Nombre", sortable: true, filterable: true },
              { content: "Descripción", sortable: true, filterable: true },
              { content: "Titulo Def.", sortable: true, filterable: true },
              { content: "Mail", sortable: true, filterable: true },
              { content: "Telefono", sortable: true, filterable: true },
              { content: "Saldo", sortable: true, filterable: true },
            ]}
            data={dataTable}
            filterStatus={filter}
            style={{ minWidth: 630 }}
          />
        </Panel>
      </div>
      <Modal modal={modal} setModal={setModal} handleSubmit={handleSubmitProveedor} />
      <ModalInfo modal={modalInfo} setModal={setModalInfo} />
      <ModalVinculation
        edificio={edificio}
        modal={modalVinculation}
        setModal={setModalVinculation}
        handleSubmit={handleSubmitVinculation}
        handleDeleteVinculo={handleDeleteVinculo}
      />
    </>
  );
};

const Header = ({ setModal, setFilter, filter, edif, search, setSearch }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Proveedores</span>
        <Button
          size="xs"
          onClick={() =>
            setModal({
              show: true,
              action: "ADD",
              data: { ID_EDIF: edif, NOMBRE: "", DESDE: "", HASTA: "", VENC: "" },
            })
          }
        >
          Agregar
        </Button>
        <Input
          type="search"
          value={search}
          style={{ lineHeight: 1, padding: "5px 11px", marginLeft: 15 }}
          placeholder="Busqueda"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
        <Icon icon="filter" />
      </Button>
    </div>
  );
};

const Actions = ({ row, setModal, setModalVinculation, setModalInfo, handleDelete, handleReactive }) => {
  if (row.ESTADO === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                DESDE: customDate(row.DESDE)[1],
                HASTA: customDate(row.HASTA)[1],
                VENC: customDate(row.VENC)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button
          icon
          onClick={() =>
            setModalInfo({
              action: "SEE",
              data: { proveedor: row.NOMBRE, edif: row.ID_EDIF, provId: row.ID_PROV },
              show: true,
            })
          }
          size="xs"
          color="yellow"
        >
          <Icon icon="info" />
        </Button>
        <Button
          icon
          onClick={() => setModalVinculation({ show: true, action: "VINCULAR", data: row })}
          size="xs"
          text="black"
          color={row.ID_PROV_EDIF > 0 ? "green" : "dark"}
        >
          <Icon icon="link" />
        </Button>
        <Button
          icon
          onClick={() =>
            confirmAlert({
              title: `Borrar ${row.NOMBRE}`,
              message: "No aparecerá en el buscador de proveedores de gastos. (Puedes reactivarlo luego)",
              buttons: [
                {
                  label: "Confirmar",
                  onClick: () => handleDelete(row.ID_PROV),
                },
                {
                  label: "Cancelar",
                  onClick: () => {},
                  color: "transparent",
                },
              ],
            })
          }
          size="xs"
          color={"red"}
        >
          <Icon icon="trash" />
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          icon
          size="xs"
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                DESDE: customDate(row.DESDE)[1],
                HASTA: customDate(row.HASTA)[1],
                VENC: customDate(row.VENC)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button icon size="xs" text="black" color="yellow" onClick={() => handleReactive(row.ID_PROV)}>
          <Icon icon="check" />
        </Button>
      </>
    );
  }
};

export default Proveedores;
