import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import customNumber from "../../hooks/customNumber";

const Modal = ({ modal, setModal, handleSubmit }) => {
  const { addToast } = useToasts();
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (modal.show)
      authFetch(`/unidades/info/${modal.data.ID_UNID}`)
        .then((data) => setInfo(data))
        .catch((err) => {
          addToast("Error al cargar info!", { appearance: "error" });
        });
    //eslint-disable-next-line
  }, [modal.data.ID_UNID]);

  const dataTable = info.map((row) => {
    return {
      cells: [
        { content: row.EJERCICIO },
        { className: "text-right", content: customNumber(row.DEUDA_ANT_CAP), order: row.DEUDA_ANT_CAP },
        { className: "text-right", content: customNumber(row.DEUDA_ANT_INT), order: row.DEUDA_ANT_INT },
        { className: "text-right", content: customNumber(-row.PAGOS), order: row.PAGOS },
        { className: "text-right", content: customNumber(row.AJUSTES), order: row.AJUSTES },
        { className: "text-right", content: customNumber(row.CAN_CAP), order: row.CAN_CAP },
        { className: "text-right", content: customNumber(row.CAN_INT), order: row.CAN_INT },
        {
          className: "text-right",
          content: customNumber(row.SALDO_CAP),
          style: { backgroundColor: "#e6e6e6" },
          order: row.SALDO_INT,
        },
        {
          className: "text-right",
          content: customNumber(row.SALDO_INT),
          style: { backgroundColor: "#e6e6e6" },
          order: row.SALDO_INT,
        },

        { className: "text-right", content: customNumber(row.AMENITIES || 0), order: row.AMENITIES },
        { className: "text-right", content: customNumber(row.EXPENSAS || 0), order: row.EXPENSAS },
        { className: "text-right", content: customNumber(row.INTERESES || 0), order: row.INTERESES },
        { className: "text-right", content: customNumber(row.REDONDEO || 0), order: row.REDONDEO },
        {
          className: "text-right",
          content: customNumber(row.DEUDA_TOT_CAP),
          order: row.DEUDA_TOT_CAP,
          style: { backgroundColor: "#e6e6e6" },
        },
        {
          className: "text-right",
          content: customNumber(row.DEUDA_TOT_INT),
          order: row.DEUDA_TOT_INT,
          style: { backgroundColor: "#e6e6e6" },
        },
      ],
    };
  });

  return (
    <CustomModal
      disabled
      show={modal.show}
      title={`Ver historial de ${modal.data.DEPTO} (${modal.data.UF})`}
      setModal={setModal}
      size="xl"
    >
      <Panel bordered>
        <Table
          columns={[
            { content: "Ejercicio", sortable: false, filterable: false },
            { content: "Deuda Ant Cap", sortable: false, filterable: false },
            { content: "Deuda Ant Int", sortable: false, filterable: false },
            { content: "Pagos", sortable: false, filterable: false },
            { content: "Ajustes", sortable: false, filterable: false },
            { content: "Cancel. Cap", sortable: false, filterable: false },
            { content: "Cancel. Int", sortable: false, filterable: false },
            {
              content: "Saldo Parc. Cap",
              style: { backgroundColor: "#e6e6e6" },
              sortable: false,
              filterable: false,
            },
            {
              content: "Saldo Parc. Int",
              style: { backgroundColor: "#e6e6e6" },
              sortable: false,
              filterable: false,
            },
            { content: "Amenities", sortable: false, filterable: false },
            { content: "Expensas", sortable: false, filterable: false },
            { content: "Intereses", sortable: false, filterable: false },
            { content: "Redondeo", sortable: false, filterable: false },
            {
              content: "Deuda tot Cap",
              style: { backgroundColor: "#e6e6e6" },
              sortable: false,
              filterable: false,
            },
            {
              content: "Deuda tot Int",
              style: { backgroundColor: "#e6e6e6" },
              sortable: false,
              filterable: false,
            },
          ]}
          data={dataTable}
          style={{ minWidth: 630 }}
        />
      </Panel>
    </CustomModal>
  );
};

export default Modal;
