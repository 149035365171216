import React, { useState } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import customNumber from "../../hooks/customNumber";
import "../../assets/styles/sueldo.css";
import Input from "../../components/Input";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Sueldo = ({ ejer, sueldo, setModal, setSueldos }) => {
  const { addToast } = useToasts();
  const [editDetails, setEditDetails] = useState(false);

  const inputStyle = {
    padding: "0 10px",
    margin: "0 -10px",
    borderRadius: 0,
    border: "none",
    top: 0,
    fontSize: "inherit",
    textAlign: "left",
    width: "calc(100% + 20px)",
  };

  const toggleEditDetails = () => {
    if (editDetails) {
      authFetch(`/sueldos/details/${editDetails.data.ID_MOV}`, { method: "PUT", body: editDetails })
        .then((data) => {
          authFetch(`/sueldos/${ejer}`)
            .then((data) => setSueldos(data))
            .catch((err) => {
              addToast("Error al cargar sueldos!", { appearance: "error" });
            });
        })
        .catch((err) => addToast("Error al editar detalle de sueldos!", { appearance: "error" }));

      setEditDetails(false);
    } else {
      setEditDetails({ data: { ...sueldo, details: [...sueldo.details.map((a) => ({ ...a }))] }, add: 0 });
    }
  };

  const handleEditDetails = (conceptoId, name, value) => {
    setEditDetails((prev) => {
      const prevSueldo = { ...prev.data };

      if (prevSueldo.details.findIndex((d) => d.ID_MOVS === conceptoId) === -1) {
        prevSueldo.details.push({
          ID_MOVS: conceptoId,
          MONTO: 0,
          DESCRIPCION: "",
          DETALLE: "",
          ORDEN: prevSueldo.details.length + 1,
        });
      }
      prevSueldo.details.find((d) => d.ID_MOVS === conceptoId)[name] = value;

      return { data: prevSueldo, add: prevSueldo.details.length - sueldo.details.length };
    });
  };

  const handleDelete = (mov) => {
    authFetch(`/sueldos/${mov}`, { method: "DELETE" })
      .then((data) => {
        setSueldos((prev) => prev.filter((sueldo) => sueldo.ID_MOV !== mov));
      })
      .catch((err) => {
        addToast("Error al borrar sueldo!", { appearance: "error" });
      });
  };

  let total = 0;

  const dataTable =
    (editDetails.data || sueldo).details.map((concepto) => {
      total += parseFloat(concepto.MONTO);
      return {
        cells: [
          {
            content: editDetails ? (
              <Input
                style={inputStyle}
                onChange={(e) => handleEditDetails(concepto.ID_MOVS, e.target.name, e.target.value)}
                name="DETALLE"
                value={concepto.DETALLE}
              />
            ) : (
              concepto.DETALLE
            ),
          },
          {
            style: { width: "20%" },
            className: "text-right",
            content: editDetails ? (
              <Input
                style={inputStyle}
                onChange={(e) => handleEditDetails(concepto.ID_MOVS, e.target.name, e.target.value)}
                name="DESCRIPCION"
                value={concepto.DESCRIPCION}
              />
            ) : (
              concepto.DESCRIPCION
            ),
          },
          {
            style: { width: "25%" },
            className: `text-right ${
              concepto.DESCRIPCION &&
              concepto.BRUTO &&
              concepto.DESCRIPCION.includes("%") &&
              Math.abs(
                (parseFloat(concepto.DESCRIPCION.split("%")[0]) * concepto.BRUTO) / 100 + parseInt(concepto.MONTO)
              ) > 1
                ? "text-red"
                : ""
            }`,
            content: editDetails ? (
              <Input
                style={inputStyle}
                onChange={(e) => handleEditDetails(concepto.ID_MOVS, e.target.name, e.target.value)}
                name="MONTO"
                value={concepto.MONTO}
              />
            ) : (
              customNumber(concepto.MONTO)
            ),
          },
        ],
      };
    }) || [];

  if (editDetails)
    dataTable.push({
      cells: [
        {
          content: (
            <Input
              style={inputStyle}
              onChange={(e) => handleEditDetails(editDetails.add, e.target.name, e.target.value)}
              placeholder="Nuevo"
              name="DETALLE"
            />
          ),
        },
        {
          style: { width: "20%" },
          className: "text-right",
          content: (
            <Input
              style={inputStyle}
              onChange={(e) => handleEditDetails(editDetails.add, e.target.name, e.target.value)}
              name="DESCRIPCION"
            />
          ),
        },
        {
          style: { width: "25%" },

          content: (
            <Input
              style={inputStyle}
              onChange={(e) => handleEditDetails(editDetails.add, e.target.name, e.target.value)}
              name="MONTO"
            />
          ),
        },
      ],
    });

  dataTable.push({
    className: "total",
    cells: [
      { colspan: 2, content: "Totales" },
      { className: `text-right${Math.abs(total - sueldo.MONTO) < 1 ? "" : " text-red"}`, content: customNumber(total) },
    ],
  });

  return (
    <div className="sueldo">
      <div className="header">
        <div style={{ display: "flex" }}>
          <h2 style={{ fontSize: 18 }}>
            {sueldo.TITULO} ({sueldo.PROVEEDOR})
          </h2>
        </div>
        <div style={{ minWidth: 65 }}>
          <Button icon size="sm" onClick={() => setModal({ data: sueldo, action: "EDIT", show: true })}>
            <Icon icon="edit" />
          </Button>
          <Button icon size="sm" color="red" style={{ marginLeft: 5 }} onClick={() => handleDelete(sueldo.ID_MOV)}>
            <Icon icon="trash" />
          </Button>
        </div>
      </div>
      <div className="body">
        <table className="info">
          <thead>
            <tr>
              <th>Caja</th>
              <th>Tipo Exp</th>
              <th>Monto Neto</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{sueldo.CAJA}</td>
              <td>{sueldo.TIPO_EXP}</td>
              <td>{sueldo.MONTO ? customNumber(sueldo.MONTO) : "0,00"}</td>
            </tr>
          </tbody>
        </table>
        <Panel style={{ margin: "20px 0 20px 0" }} bordered>
          <Table columns={[{ content: "Detalle" }, { content: "Cant / %" }, { content: "Monto" }]} data={dataTable} />
        </Panel>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button size="sm" onClick={toggleEditDetails}>
            Editar Detalle
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sueldo;
