import React from "react";
import CustomModal from "../../components/Modal";
import { SelectInput } from "../../components/Input";
import { useForm } from "react-hook-form";

const ModalEjercicio = ({ modal, ejercicios, ejer, edif, setModal, handleSubmit: handleSubmitModal }) => {
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  return (
    <CustomModal show={modal.show} title={`Editar Ejercicio`} setModal={setModal} handleSubmit={onSubmit}>
      <form onSubmit={onSubmit} style={{ marginBottom: -15 }}>
        <SelectInput
          name="ID_EJER"
          label="Ejercicio"
          data={ejercicios
            .filter((ej) => ej.ID_EDIF === edif)
            .map((ej) => ({ label: ej.NOMBRE, value: ej.ID_EJER }))}
          value={ejer}
          register={register({ required: true })}
          errors={errors}
          errMsg="Campo obligatorio"
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default ModalEjercicio;
