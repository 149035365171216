import React from "react";
import CustomModal from "../../components/Modal";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";

const Modal = ({ modal, setModal, handleSubmit: handleSubmitModal }) => {
  const { register, errors, handleSubmit } = useForm({
    defaultValues: modal.data,
  });
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? "Agregar Persona" : `Editar ${modal.data.NOMBRE}`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <Input
          label="Nombre"
          name="NOMBRE"
          errors={errors}
          autoFocus
          errMsg="Nombre Requerido"
          value={modal.data.NOMBRE}
          register={register({ required: true })}
        />
        <Input
          label="CUIT"
          name="CUIT"
          errors={errors}
          errMsg="El CUIT debe tenes 10 o 11 caracteres"
          value={modal.data.CUIT === 0 || modal.data.CUIT === "0" ? "" : modal.data.CUIT}
          register={register({ minLength: 10, maxLength: 11 })}
        />
        <Input label="Mail" name="MAIL" value={modal.data.MAIL} register={register} />
        <Input label="Telefono" name="TELEFONO" value={modal.data.TELEFONO} register={register} />
        <Input label="Celular" name="CELULAR" value={modal.data.CELULAR} register={register} />

        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
