import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Modal from "./Modal";
import ModalGasto from "../Gastos/Modal";
import customNumber from "../../hooks/customNumber";
import customDate from "../../hooks/customDate";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import Input from "../../components/Input";

const Pagos = ({ location, match, ejercicios, edificios, setEjercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);
  const ejerState = ejercicios.find((ej) => ej.ID_EJER === ejer)?.ESTADO || 0;
  const edificio = edificios.find((ed) => ed.ID_EDIF === edif);

  const [pagos, setPagos] = useState([]);
  const [modal, setModal] = useState({ show: false, action: false, data: {} });
  const [modalGasto, setModalGasto] = useState({ show: false, action: false, data: {} });
  const [order, setOrder] = useState(false);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    authFetch(`/pagos/${ejer}`)
      .then((data) => setPagos(data))
      .catch((err) => {
        addToast("Error al cargar pagos!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif, ejer]);

  const handleSubmitPago = (formData) => {
    console.log(formData);
    switch (modal.action) {
      case "ADD":
        authFetch(`/pagos`, {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, ID_MOVP: modal.data.ID_MOVP, ...formData },
        })
          .then((res) => authFetch(`/pagos/${ejer}`))
          .then((data) => setPagos(data))
          .catch((err) => {
            addToast("Error agregando pago!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/pagos/${modal.data.ID_MOV}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then((res) => {
            authFetch(`/pagos/${ejer}`)
              .then((data) => setPagos(data))
              .catch((err) => {
                addToast("Error al cargar pagos!", { appearance: "error" });
              });
          })
          .catch((err) => {
            addToast("Error editando pago!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }
  };

  const handleDeletePago = (data) => {
    authFetch(`/pagos/${data.ID_MOV}`, { method: "DELETE" })
      .then((res) => {
        const pagoIndex = pagos.findIndex((pago) => pago.ID_MOV === data.ID_MOV);
        if (pagoIndex > -1) {
          const prevPagos = [...pagos];
          prevPagos.splice(pagoIndex, 1);
          setPagos(prevPagos);
        }
      })
      .catch((err) => {
        addToast("Error borrando pago!", { appearance: "error" });
      });
  };

  const handleOpenGasto = (row) => {
    authFetch(`/gastos/detalle/${row.ID_MOVP}`)
      .then((data) => setModalGasto({ show: true, action: "EDIT", data: { ...data[0], ID_MOV: row.ID_MOV } }))
      .catch((err) => {
        addToast("Error al cargar detalle!", { appearance: "error" });
      });
  };

  const handleSubmitGasto = (formData) => {
    switch (modalGasto.action) {
      case "ADD":
        authFetch(`/gastos`, {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, ...formData },
        })
          .then((res) => authFetch(`/gastos/${ejer}`))
          .then((data) => {
            if (formData.payNow) {
              const gasto = data.find(
                (g) =>
                  (formData.TITULO.includes("{") || g.TITULO === formData.TITULO) &&
                  g.MONTO === parseFloat(formData.MONTO) &&
                  g.FECHA === formData.FECHA &&
                  g.ID_PROV === parseFloat(formData.ID_PROV)
              );
              if (!gasto) return;
              setModal({
                show: true,
                action: "ADD",
                data: {
                  ID_MOVP: gasto.ID_MOVP,
                  TITULO: gasto.TITULO,
                  MONTO: gasto.PAGO_REST,
                  FECHA: gasto.FECHA,
                  MONTO_FAC: gasto.PAGO_REST,
                  CLASIFICACION_DEF: gasto.CLASIFICACION_DEF,
                  TIPO_EXP_DEF: gasto.TIPO_EXP_DEF,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            addToast("Error agregando gasto!", { appearance: "error" });
          });
        setModalGasto((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/gastos/${modalGasto.data.ID_MOVP}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then(() => authFetch(`/pagos/${ejer}`))
          .then((data) => setPagos(data))
          .catch((err) => {
            addToast("Error editando gasto!", { appearance: "error" });
          });
        setModalGasto((prev) => ({ data: {}, action: "", show: false }));
        break;

      default:
        break;
    }
  };

  const handleOrder = (cancel = false) => {
    if (filter) setFilter(false);
    if (order && !cancel) {
      authFetch("/pagos/order", { method: "POST", body: order })
        .then((data) => authFetch(`/pagos/${ejer}`))
        .then((data) => setPagos(data))
        .catch((err) => {
          addToast("Error al cargar los ordenes", { appearance: "error" });
        });
    }
    setOrder((prev) => (prev ? false : [...pagos.map((a) => ({ ...a }))]));
  };

  const inputStyle = {
    padding: "0 10px",
    margin: "-1px",
    borderRadius: 0,
    position: "absolute",
    top: 0,
    textAlign: "center",
    width: "calc(100% + 2px)",
  };

  let lastClasificacion;
  let cantClas = 0;
  const dataTable = [];
  (order || pagos).forEach((row, i) => {
    if (lastClasificacion !== row.CLASIFICACION && lastClasificacion) {
      cantClas++;
      dataTable.push({
        id: cantClas,
        className: "separator",
        position: "top",
        header: true,
        title: lastClasificacion,
        total: [3],
      });
    }
    lastClasificacion = row.CLASIFICACION;
    dataTable.push({
      id: row.ID_MOV,
      clasificacion: row.CLASIFICACION,
      className: (location?.state?.sibilings || []).includes(row.ID_MOV) ? "selected" : "",
      cells: [
        {
          className: "actions-col",
          content:
            row.CLASIFICACION !== "SUELDO" ? (
              <Actions
                row={row}
                modal={modal}
                setModal={setModal}
                handleDeletePago={handleDeletePago}
                handleOpenGasto={handleOpenGasto}
                ejerState={ejerState}
              />
            ) : (
              "-"
            ),
        },
        {
          className: "text-center",
          content: (
            <>
              {order && (
                <div style={{ position: "relative" }}>
                  <Input
                    style={inputStyle}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOrder();
                      }
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      setOrder((prev) => {
                        const prevOrder = [...prev];
                        prevOrder.find((pago) => pago.ID_MOV === row.ID_MOV).ORDEN = value;
                        return prevOrder;
                      });
                    }}
                    value={row.ORDEN}
                    tabIndex={1}
                  />
                </div>
              )}
              <span style={{ height: 20, display: "block" }} onDoubleClick={handleOrder}>
                {row.ORDEN}
              </span>
            </>
          ),
          handleDrag: true,
          order: row.ORDEN,
        },
        {
          className:
            "text-truncate" +
            (!["APORTE", "SUELDO", "SEGURO"].includes(row.CLASIFICACION) && row.NECESITA_FACTURA === 1 && !row.FACTURA
              ? " text-red"
              : ""),
          style: { maxWidth: window.innerWidth * 0.3 },
          title: `${row.DEPTO ? `Piso ${row.DEPTO}` : ""}
              ${row.TITULO} (${row.PROVEEDOR})${row.FACTURA ? ` Fac. ${row.FACTURA}` : ""}`,
          content: `${row.DEPTO ? `Piso ${row.DEPTO}` : ""}
              ${row.TITULO} (${row.PROVEEDOR})${row.FACTURA ? ` Fac. ${row.FACTURA}` : ""}`,
        },
        { className: "text-right", content: customNumber(row.MONTO), order: row.MONTO },
        { className: "text-center", content: customDate(row.FECHA)[0], order: customDate(row.FECHA)[1] },
        { className: "text-center", content: row.CAJA || "" },
        { className: "", content: row.TIPO_EXP },
      ],
    });
  });

  dataTable.push({
    id: cantClas + 1,
    className: "separator",
    position: "top",
    header: true,
    title: lastClasificacion,
    total: [3],
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight="calc(100vh - 55px - 48px - 80px)"
          header={
            <Header
              ejerState={ejerState}
              setFilter={setFilter}
              filter={filter}
              setModalGasto={setModalGasto}
              handleOrder={handleOrder}
              order={order}
            />
          }
        >
          <Table
            separator
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Orden", sortable: true, filterable: true },
              {
                content: "Titulo",
                style: { maxWidth: window.innerWidth * 0.3 },
                sortable: true,
                filterable: true,
              },
              { style: { minWidth: 100 }, content: "Monto", sortable: true, filterable: true },
              { style: { minWidth: 100 }, content: "Fecha", sortable: true, filterable: true },
              { style: { minWidth: 100 }, content: "Caja", sortable: true, filterable: true },
              { style: { minWidth: 100 }, content: "Tipo Exp.", sortable: true, filterable: true },
            ]}
            data={dataTable}
            stateData={pagos}
            setData={setPagos}
            filterStatus={filter}
            tableTotal
            colTotals={[3]}
            style={{ minWidth: 630 }}
          />
        </Panel>
      </div>
      <Modal
        modal={modal}
        edif={edif}
        ejer={ejer}
        ejerState={ejerState}
        edificio={edificio}
        setModal={setModal}
        handleSubmit={handleSubmitPago}
      />
      <ModalGasto
        modal={modalGasto}
        edif={edif}
        ejerState={ejerState}
        setModal={setModalGasto}
        handleSubmit={handleSubmitGasto}
      />
    </>
  );
};

const Header = ({ ejerState, setFilter, filter, setModalAmenities, setModalGasto, handleOrder, order }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Pagos</span>
        {ejerState === 1 && (
          <>
            <Button
              size="xs"
              onClick={() =>
                setModalGasto({
                  show: true,
                  action: "ADD",
                  data: {},
                })
              }
            >
              Agregar Gasto
            </Button>
            <Button size="xs" onClick={() => handleOrder(false)} style={{ marginLeft: 5 }}>
              {order ? "Guardar Orden" : "Ordenar"}
            </Button>
            {order && (
              <Button icon size="xs" color="secondary" style={{ marginLeft: 5 }} onClick={() => handleOrder(true)}>
                <Icon icon="close" style={{ fontSize: 10 }} />
              </Button>
            )}
          </>
        )}
      </div>
      {!order && (
        <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
          <Icon icon="filter" />
        </Button>
      )}
    </div>
  );
};

const Actions = ({ row, ejerState, setModal, handleDeletePago, handleOpenGasto }) => {
  if (ejerState === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          color="green"
          onClick={(e) => {
            e.preventDefault();
            setModal({
              show: true,
              action: "EDIT",
              data: row,
            });
          }}
        >
          <Icon icon="edit" />
        </Button>
        <Button
          size="xs"
          icon
          color="red"
          onClick={(e) => {
            e.preventDefault();
            handleDeletePago(row);
          }}
        >
          <Icon icon="trash" />
        </Button>
        <Button
          size="xs"
          icon
          onClick={(e) => {
            e.preventDefault();
            handleOpenGasto(row);
          }}
        >
          <Icon icon="edit" />
        </Button>
      </>
    );
  } else {
    return (
      <Button
        size="xs"
        icon
        color="green"
        onClick={() =>
          setModal({
            show: true,
            action: "EDIT",
            data: row,
          })
        }
      >
        <Icon icon="info" />
      </Button>
    );
  }
};

export default Pagos;
