import React, { useEffect, useState } from "react";
import authFetch from "../../hooks/authFetch";
import Button from "../../components/Button";
import Panel from "../../components/Panel";
import Input, { NumberInput, SelectInput } from "../../components/Input";
import "../../assets/styles/Edificio.css";
import { useForm } from "react-hook-form";
import CajasSelect from "../../components/CajasSelect";

const Edificio = ({ history, match, edificios, setEdificios }) => {
  const edif = parseInt(match.params.edif || 0);
  const ejer = parseInt(match.params.ejer || 0);
  const { register, reset, handleSubmit, errors } = useForm();
  const [step, setStep] = useState(1);
  const [unidades, setUnidades] = useState(5);
  const action = edif === 0 ? "ADD" : "EDIT";

  const edificio = edificios.find((ed) => ed.ID_EDIF === edif) || {};

  useEffect(() => {
    reset({ EXP_DEF: edificio.EXP_DEF });
    //eslint-disable-next-line
  }, [edif]);

  const onSubmit = handleSubmit((formData) => {
    if (action === "ADD") {
      if (step === 1) {
        authFetch("/edificios", { method: "POST", body: { step: 1, ...formData } })
          .then((data) => authFetch("/edificios"))
          .then((data) => {
            setEdificios(data);
            setUnidades(formData.UNIDADES);
            setStep(2);
          })
          .catch((err) => {});
      } else if (step === 2) {
        authFetch("/edificios", {
          method: "POST",
          body: {
            step: 2,
            ...formData,
            edif: edificios.sort((a, b) => (a.ID_EDIF > b.ID_EDIF ? -1 : 1))[0].ID_EDIF,
          },
        })
          .then((data) => {
            setStep(3);
          })
          .catch((err) => {});
      } else if (step === 3) {
        authFetch("/edificios", {
          method: "POST",
          body: {
            step: 3,
            ...formData,
            unidades,
            edif: edificios.sort((a, b) => (a.ID_EDIF > b.ID_EDIF ? -1 : 1))[0].ID_EDIF,
          },
        })
          .then((data) => authFetch("/edificios"))
          .then((data) => {
            setEdificios(data);
            history.push({
              pathname: "/edificio/" + edificios.sort((a, b) => (a.ID_EDIF > b.ID_EDIF ? -1 : 1))[0].ID_EDIF,
            });
          })
          .catch((err) => {});
      }
    } else if (action === "EDIT") {
      authFetch(`/edificios/${edif}`, { method: "PUT", body: formData })
        .then((data) => authFetch("/edificios"))
        .then((data) => setEdificios(data))
        .catch((err) => {});
    }
  });

  return (
    <>
      <div className="container">
        <form onSubmit={onSubmit}>
          <div className="edificio">
            {action === "EDIT" || step === 1 ? (
              <>
                <div className="step-header">
                  <h3>Edificio</h3>
                  <h4>Datos del consorcio, seguro, intereses, etc.</h4>
                </div>
                <Panel flat={true}>
                  <div className="form-grid">
                    <Input
                      register={register({ required: true })}
                      errors={errors}
                      errMsg="Campo requerido"
                      label="Nombre"
                      name="NOMBRE"
                      value={edificio.NOMBRE}
                    />
                    <Input
                      register={register({ required: true })}
                      errors={errors}
                      errMsg="Campo requerido"
                      label="Dirección"
                      name="DIRECCION"
                      value={edificio.DIRECCION}
                    />
                    <Input
                      register={register({ required: true })}
                      errors={errors}
                      errMsg="Campo requerido"
                      label="CUIT"
                      name="CUIT"
                      value={edificio.CUIT}
                    />
                    <Input
                      register={register}
                      errors={errors}
                      errMsg="Campo requerido"
                      label="Seguro"
                      name="SEGURO"
                      value={edificio.SEGURO}
                    />
                    <Input
                      register={register({ required: action === "ADD" })}
                      errors={errors}
                      type="number"
                      errMsg="Campo requerido"
                      label="Unidades"
                      name="UNIDADES"
                      disabled={action === "EDIT"}
                      value={edificio.UNIDADES}
                    />
                    <Input
                      register={register({ required: true })}
                      errors={errors}
                      type="number"
                      errMsg="Campo requerido"
                      label="Intereses"
                      name="INTERES"
                      value={edificio.INTERES}
                    />
                  </div>
                </Panel>
                <hr />
                <div className="step-header">
                  <h3>Expensas</h3>
                  <h4>Nombre y Cantidad de Tipos de Expensa.</h4>
                </div>
                <Panel flat={true}>
                  <div className="form-grid">
                    <Input
                      register={register({ required: true })}
                      errors={errors}
                      errMsg="Campo requerido"
                      label="Expensas A"
                      name="A"
                      value={edificio.TIPO_EXP?.split(";")[0]}
                    />
                    <Input
                      register={register}
                      label="Expensas B"
                      name="B"
                      value={edificio.TIPO_EXP?.split(";")[1]}
                    />
                    <Input
                      register={register}
                      label="Expensas C"
                      name="C"
                      value={edificio.TIPO_EXP?.split(";")[2]}
                    />
                    <Input
                      register={register}
                      label="Expensas D"
                      name="D"
                      value={edificio.TIPO_EXP?.split(";")[3]}
                    />
                    <Input
                      register={register}
                      label="Expensas E"
                      name="E"
                      value={edificio.TIPO_EXP?.split(";")[4]}
                    />
                    <Input
                      register={register}
                      label="Expensas F"
                      name="F"
                      value={edificio.TIPO_EXP?.split(";")[5]}
                    />
                    <Input
                      register={register}
                      label="Expensas G"
                      name="G"
                      value={edificio.TIPO_EXP?.split(";")[6]}
                    />
                  </div>
                </Panel>
              </>
            ) : (
              <></>
            )}
            {action === "EDIT" || step === 2 ? (
              <>
                {action === "EDIT" && <hr />}
                <div className="step-header">
                  <h3>Cuentas</h3>
                  <h4>Configuracion del mail y servicios de Google.</h4>
                </div>
                <Panel flat={true}>
                  <div className="form-grid" style={{ gridTemplateColumns: "1fr" }}>
                    <Input
                      register={register({ required: true })}
                      errors={errors}
                      errMsg="Campo requerido"
                      label="Mail"
                      name="MAIL"
                      value={edificio.MAIL}
                    />
                    <div className="form-group">
                      <label style={{ display: "block" }}>Google</label>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() =>
                          authFetch(
                            `/edificios/google?edif=${
                              edif || edificios.sort((a, b) => (a.ID_EDIF > b.ID_EDIF ? -1 : 1))[0].ID_EDIF
                            }${ejer ? `&ejer=${ejer}` : ""}`
                          ).then((res) => window.open(res.loginLink, "", "toolbar=no"))
                        }
                      >
                        {edificio.OAUTH2 ? "Logged with Google" : "Login with Google"}
                      </Button>
                    </div>
                  </div>
                </Panel>
              </>
            ) : (
              <></>
            )}
            {action === "EDIT" && (
              <>
                <hr />
                <div className="step-header">
                  <h3>Predeterminados</h3>
                  <h4>Datos predeterminados del Edificio.</h4>
                </div>
                <Panel flat={true}>
                  <div className="form-grid" style={{ gridTemplateColumns: "1fr" }}>
                    <SelectInput
                      label="Tipo Expensa Def."
                      name="EXP_DEF"
                      register={register}
                      value={edificio.EXP_DEF}
                      data={[
                        { label: "Exp A", value: 1 },
                        { label: "Exp B", value: 2 },
                        { label: "Exp C", value: 3 },
                        { label: "Exp D", value: 4 },
                        { label: "Exp E", value: 5 },
                        { label: "Exp F", value: 6 },
                        { label: "Exp G", value: 7 },
                      ]}
                    />
                    <CajasSelect
                      errors={errors}
                      reset={reset}
                      value={edificio.ID_CAJA}
                      register={register}
                      ejer={ejer}
                    />
                  </div>
                </Panel>
              </>
            )}

            {action === "ADD" && step === 3 ? (
              <>
                <div className="step-header">
                  <h3>Unidades</h3>
                  <h4>Carga de Depto, UF y saldo inicial.</h4>
                </div>
                <Panel flat={true}>
                  <div className="form-grid unidades" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                    <h4>UF</h4>
                    <h4>Depto</h4>
                    <h4>Saldo Inicial</h4>
                    {Array.from({ length: unidades }).map((a, i) => (
                      <React.Fragment key={i}>
                        <Input register={register({ required: true })} name={`UF_${i + 1}`} value={i + 1} />
                        <Input register={register({ required: true })} name={`DEPTO_${i + 1}`} />
                        <NumberInput register={register({ required: true })} name={`SALDO_${i + 1}`} />
                      </React.Fragment>
                    ))}
                  </div>
                </Panel>
              </>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", gridColumn: "1 / -1" }}>
              <Button>{action === "EDIT" ? "Guardar" : step === 3 ? "Crear" : "Siguiente"}</Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Edificio;
