import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Input from "../../components/Input";
import ModalIngreso from "./ModalIngreso";
import ModalAjuste from "./ModalAjuste";
import ModalAmenities from "./ModalAmenities";
import ModalInfo from "./ModalInfo";
import customNumber from "../../hooks/customNumber";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

// TODO
// identificar ingresos

const Ingresos = ({ location, match, ejercicios, setEjercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);
  const ejerState = ejercicios.find((ej) => ej.ID_EJER === ejer)?.ESTADO || 0;

  const [ingresos, setIngresos] = useState([]);
  const [modalIngreso, setModalIngreso] = useState({ show: false, action: false, data: {} });
  const [modalAjuste, setModalAjuste] = useState({ show: false, action: false, data: {} });
  const [modalAmenities, setModalAmenities] = useState({ show: false, action: false, data: {} });
  const [modalInfo, setModalInfo] = useState({ show: false, action: false, data: {} });
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    authFetch(`/ingresos/${ejer}`)
      .then((data) => setIngresos(data))
      .catch((err) => {
        addToast("Error al cargar los ingresos!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif, ejer]);

  const handleSubmitIngreso = (formData) => {
    switch (modalIngreso.action) {
      case "ADD":
        authFetch(`/ingresos`, {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, ID_UNID: modalIngreso.data.unid, ...formData },
        })
          .then((res) => {
            const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === modalIngreso.data.unid);
            if (unidIndex > -1) {
              const prevIngresos = [...ingresos];
              prevIngresos[unidIndex].PAGOS_EXP -= parseFloat(formData.MONTO);
              prevIngresos[unidIndex].DEUDA_TOT -= parseFloat(formData.MONTO);
              setIngresos(prevIngresos);
            }
          })
          .catch((err) => {
            addToast("Error agregando ingreso!", { appearance: "error" });
          });
        setModalIngreso((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/ingresos/${modalIngreso.data.ID_MOV}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then((res) => {
            const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === modalIngreso.data.ID_UNID);
            if (unidIndex > -1) {
              const prevIngresos = [...ingresos];
              prevIngresos[unidIndex].PAGOS_EXP -= parseFloat(formData.MONTO) - modalIngreso.data.MONTO;
              prevIngresos[unidIndex].DEUDA_TOT -= parseFloat(formData.MONTO) - modalIngreso.data.MONTO;
              setIngresos(prevIngresos);
            }
          })
          .catch((err) => {
            addToast("Error editando ingreso!", { appearance: "error" });
          });
        setModalIngreso((prev) => ({ ...prev, show: false }));
        setModalInfo((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }
  };

  const handleDeleteIngreso = (data) => {
    console.log("delete ingreso " + data.ID_MOV);
    authFetch(`/ingresos/${data.ID_MOV}`, { method: "DELETE" })
      .then((res) => {
        const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === data.ID_UNID);
        if (unidIndex > -1) {
          const prevIngresos = [...ingresos];
          prevIngresos[unidIndex].PAGOS_EXP += parseFloat(data.MONTO);
          prevIngresos[unidIndex].DEUDA_TOT += parseFloat(data.MONTO);
          setIngresos(prevIngresos);
        }
      })
      .catch((err) => {
        addToast("Error borrando ingreso!", { appearance: "error" });
      });
    setModalInfo((prev) => ({ ...prev, show: false }));
  };

  const handleSubmitAjuste = (formData) => {
    switch (modalAjuste.action) {
      case "ADD":
        authFetch("/ingresos/ajuste", {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, ID_UNID: modalAjuste.data.unid, ...formData },
        })
          .then((res) => {
            const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === modalAjuste.data.unid);
            if (unidIndex > -1) {
              const prevIngresos = [...ingresos];
              prevIngresos[unidIndex].AJUSTES += parseFloat(formData.MONTO);
              prevIngresos[unidIndex].DEUDA_TOT += parseFloat(formData.MONTO);
              setIngresos(prevIngresos);
            }
          })
          .catch((err) => {
            addToast("Error al ajustar!", { appearance: "error" });
          });
        setModalAjuste((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/ingresos/ajuste/${modalAjuste.data.ID_AJUSTE}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then((res) => {
            const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === modalAjuste.data.ID_UNID);
            if (unidIndex > -1) {
              const prevIngresos = [...ingresos];
              prevIngresos[unidIndex].AJUSTES += parseFloat(formData.MONTO) - modalAjuste.data.MONTO;
              prevIngresos[unidIndex].DEUDA_TOT += parseFloat(formData.MONTO) - modalAjuste.data.MONTO;
              setIngresos(prevIngresos);
            }
          })
          .catch((err) => {
            addToast("Error al editar ajuste!", { appearance: "error" });
          });

        setModalAjuste((prev) => ({ ...prev, show: false }));
        setModalInfo((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }
  };

  const handleDeleteAjuste = (data) => {
    console.log("delete ajuste " + data.ID_AJUSTE);
    authFetch(`/ingresos/ajuste/${data.ID_AJUSTE}`, { method: "DELETE" })
      .then((res) => {
        const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === data.ID_UNID);
        if (unidIndex > -1) {
          const prevIngresos = [...ingresos];
          prevIngresos[unidIndex].AJUSTES -= parseFloat(data.MONTO);
          prevIngresos[unidIndex].DEUDA_TOT -= parseFloat(data.MONTO);
          setIngresos(prevIngresos);
        }
      })
      .catch((err) => {
        addToast("Error borrando ajuste!", { appearance: "error" });
      });
    setModalInfo((prev) => ({ ...prev, show: false }));
  };

  const handleSubmitAmenities = (formData) => {
    switch (modalAmenities.action) {
      case "ADD":
        authFetch("/ingresos/amenity", {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, ID_UNID: modalAmenities.data.unid, ...formData },
        })
          .then((res) => {
            const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === parseInt(formData.ID_UNID));
            if (unidIndex > -1) {
              const prevIngresos = [...ingresos];
              prevIngresos[unidIndex].AMENITIES += parseFloat(formData.MONTO);
              prevIngresos[unidIndex].DEUDA_TOT += parseFloat(formData.MONTO);
              setIngresos(prevIngresos);
            }
          })
          .catch((err) => {
            addToast("Error al cargar amenity!", { appearance: "error" });
          });
        setModalAmenities((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/ingresos/amenity/${modalAmenities.data.ID_AMEN}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then((res) => {
            const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === parseInt(modalAmenities.data.ID_UNID));
            if (unidIndex > -1) {
              const prevIngresos = [...ingresos];
              prevIngresos[unidIndex].AMENITIES += parseFloat(formData.MONTO) - modalAmenities.data.MONTO;
              prevIngresos[unidIndex].DEUDA_TOT += parseFloat(formData.MONTO) - modalAmenities.data.MONTO;
              setIngresos(prevIngresos);
            }
          })
          .catch((err) => {
            addToast("Error al editar amenity!", { appearance: "error" });
          });
        setModalAmenities((prev) => ({ ...prev, show: false }));
        setModalInfo((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }
  };

  const handleDeleteAmenities = (data) => {
    console.log("delete amenity " + data.ID_AMEN);
    authFetch(`/ingresos/amenity/${data.ID_AMEN}`, { method: "DELETE" })
      .then((res) => {
        const unidIndex = ingresos.findIndex((ing) => ing.ID_UNID === data.ID_UNID);
        if (unidIndex > -1) {
          const prevIngresos = [...ingresos];
          prevIngresos[unidIndex].AMENITIES -= parseFloat(data.MONTO);
          prevIngresos[unidIndex].DEUDA_TOT -= parseFloat(data.MONTO);
          setIngresos(prevIngresos);
        }
      })
      .catch((err) => {
        addToast("Error borrando amenity!", { appearance: "error" });
      });
    setModalInfo((prev) => ({ ...prev, show: false }));
  };

  const dataTable = (
    search
      ? ingresos.filter(
          (i) =>
            (i.UF || "").toString().toLowerCase().includes(search.toLowerCase()) ||
            (i.DEPTO || "").toString().toLowerCase().includes(search.toLowerCase()) ||
            (i.PROPIETARIO || "").toString().toLowerCase().includes(search.toLowerCase()) ||
            (i.INQUILINO || "").toString().toLowerCase().includes(search.toLowerCase())
        )
      : ingresos
  ).map((row, i) => {
    return {
      className: (location?.state?.sibilings || []).includes(row.ID_UNID) ? "selected" : "",
      cells: [
        {
          className: "actions-col",
          content: (
            <Actions
              row={row}
              ejerState={ejerState}
              setModalIngreso={setModalIngreso}
              setModalAjuste={setModalAjuste}
              setModalInfo={setModalInfo}
            />
          ),
        },
        { className: "", content: `(${row.UF}) ${row.DEPTO}` },
        { className: "", content: row.PROPIETARIO },
        { className: "", content: row.INQUILINO || "-" },
        { className: "text-right", content: customNumber(row.DEUDA_ANT), order: row.DEUDA_ANT },
        { className: "text-right", content: customNumber(row.PAGOS_EXP), order: row.PAGOS_EXP },
        { className: "text-center", content: row.PAGOS_CAJA || "" },
        { className: "text-right", content: customNumber(row.AJUSTES), order: -row.AJUSTES },
        { className: "text-right", content: customNumber(row.AMENITIES), order: row.AMENITIES },
        { className: "text-right", content: customNumber(row.EXPENSAS), order: row.EXPENSAS },
        { className: "text-right", content: customNumber(row.DEUDA_TOT), order: row.DEUDA_TOT },
      ],
    };
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight="calc(100vh - 55px - 48px - 80px)"
          header={
            <Header
              search={search}
              setSearch={setSearch}
              filter={filter}
              setFilter={setFilter}
              setModalAmenities={setModalAmenities}
            />
          }
        >
          <Table
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Unidad", sortable: true, filterable: true },
              { content: "Propietario", sortable: true, filterable: true },
              { content: "Inquilino", sortable: true, filterable: true },
              { content: "Deuda Ant", sortable: true, filterable: true },
              { content: "Ingresos", sortable: true, filterable: true },
              { content: "Caja", sortable: true, filterable: true },
              { content: "Ajustes", sortable: true, filterable: true },
              { content: "Amenities", sortable: true, filterable: true },
              { content: "Expensas", sortable: true, filterable: true },
              { content: "Deuda Tot", sortable: true, filterable: true },
            ]}
            data={dataTable}
            filterStatus={filter}
            colTotals={[4, 5, 7, 8, 9, 10]}
            tableTotal
            style={{ minWidth: 1000 }}
          />
        </Panel>
      </div>
      <ModalIngreso modal={modalIngreso} ejer={ejer} setModal={setModalIngreso} handleSubmit={handleSubmitIngreso} />
      <ModalAjuste modal={modalAjuste} setModal={setModalAjuste} handleSubmit={handleSubmitAjuste} />
      <ModalAmenities
        modal={modalAmenities}
        unidades={ingresos.map((ing) => ({ ID_UNID: ing.ID_UNID, depto: `(${ing.UF}) ${ing.DEPTO}` }))}
        setModal={setModalAmenities}
        handleSubmit={handleSubmitAmenities}
      />
      <ModalInfo
        modal={modalInfo}
        ejer={ejer}
        ejerState={ejerState}
        setModal={setModalInfo}
        setModalIngreso={setModalIngreso}
        setModalAjuste={setModalAjuste}
        setModalAmenities={setModalAmenities}
        handleDeleteIngreso={handleDeleteIngreso}
        handleDeleteAjuste={handleDeleteAjuste}
        handleDeleteAmenities={handleDeleteAmenities}
      />
    </>
  );
};

const Header = ({ setFilter, filter, setModalAmenities, search, setSearch }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Ingresos</span>
        <Button
          size="xs"
          onClick={() =>
            setModalAmenities({
              show: true,
              action: "ADD",
              data: {},
            })
          }
          active={filter}
        >
          Amenities
        </Button>
        <Input
          type="search"
          value={search}
          style={{ lineHeight: 1, padding: "5px 11px", marginLeft: 15 }}
          placeholder="Busqueda"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
        <Icon icon="filter" />
      </Button>
    </div>
  );
};

const Actions = ({ row, ejerState, setModalIngreso, setModalAjuste, setModalInfo }) => {
  if (ejerState === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          color="green"
          onClick={() =>
            setModalIngreso({
              show: true,
              action: "ADD",
              data: { unid: row.ID_UNID, depto: `(${row.UF}) ${row.DEPTO}`, MONTO: row.DEUDA_TOT },
            })
          }
        >
          <Icon icon="cash" />
        </Button>
        <Button
          size="xs"
          icon
          color="yellow"
          onClick={() =>
            setModalInfo({
              show: true,
              action: "INFO",
              data: { unid: row.ID_UNID, depto: `(${row.UF}) ${row.DEPTO}` },
            })
          }
        >
          <Icon icon="info" />
        </Button>
        <Button
          size="xs"
          icon
          onClick={() =>
            setModalAjuste({
              show: true,
              action: "ADD",
              data: { unid: row.ID_UNID, depto: `(${row.UF}) ${row.DEPTO}` },
            })
          }
        >
          <Icon icon="config" />
        </Button>
      </>
    );
  } else {
    return (
      <Button
        size="xs"
        icon
        color="yellow"
        onClick={() =>
          setModalInfo({
            show: true,
            action: "INFO",
            data: { unid: row.ID_UNID, depto: `(${row.UF}) ${row.DEPTO}` },
          })
        }
      >
        <Icon icon="info" />
      </Button>
    );
  }
};

export default Ingresos;
