import React, { useEffect, useState } from "react";
import "../assets/styles/Progress.css";

const Progress = ({
  radius,
  progress,
  style = {},
  steps,
  color,
  lineWidth = 20,
  initialAnimation = "false",
  initialAnimationDelay,
  transition,
  trackTransition,
  className,
  children,
  title,
}) => {
  console.log(style);
  const [animationInited, setAnimationInited] = useState(false);

  useEffect(() => {
    if (initialAnimation) {
      setTimeout(() => setAnimationInited(true), initialAnimationDelay);
    }
  }, [initialAnimation, initialAnimationDelay]);

  const getProgress = () => (initialAnimation && !animationInited ? 0 : progress);

  const getStrokeDashoffset = (strokeLength) => {
    const progress = getProgress();
    const progressLength = (strokeLength / steps) * (steps - progress);

    return progressLength;
  };

  const getStrokeDashArray = (strokeLength, circumference) => {
    return `${strokeLength}, ${circumference}`;
  };

  const getTrackStrokeDashArray = (strokeLength, circumference) => {
    if (initialAnimation && !animationInited) {
      return `0, ${circumference}`;
    }

    return `${strokeLength}, ${circumference}`;
  };

  const getExtendedWidth = () => {
    return lineWidth * 2;
  };

  const d = 2 * radius;
  const width = d + getExtendedWidth();

  const circumference = 2 * Math.PI * radius;
  const strokeLength = (circumference / 360) * 240;

  return (
    <div
      className="progress"
      style={{
        ...style,
        position: "relative",
        width: `${width}px`,
      }}
    >
      <div
        className={`RCP ${className}`}
        style={{
          position: "relative",
          width: `${width}px`,
        }}
      >
        <svg width={width} height={width} viewBox={`0 0 ${width} ${width}`} style={{ transform: `rotate(-210deg)` }}>
          <circle
            cx={width / 2}
            cy={width / 2}
            r={radius}
            fill="none"
            stroke={"#F3F3F3"}
            strokeWidth={lineWidth}
            strokeDasharray={getTrackStrokeDashArray(strokeLength, circumference)}
            strokeLinecap="round"
            className="RCP__track"
            style={{ transition: trackTransition }}
          />
          <circle
            cx={width / 2}
            cy={width / 2}
            r={radius}
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
            strokeDasharray={getStrokeDashArray(strokeLength, circumference)}
            strokeDashoffset={getStrokeDashoffset(strokeLength)}
            strokeLinecap="round"
            className="RCP__progress"
            style={{ transition }}
          ></circle>
        </svg>
        <div
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-45%, -60%)", fontSize: 35 }}
        >
          {children}
        </div>
      </div>
      <h4 style={{ fontSize: 16, color: "#717171", fontWeight: 500, textAlign: "center", marginTop: radius * -0.4 }}>
        {title}
      </h4>
    </div>
  );
};

export default Progress;
