import React, { useState, useEffect } from "react";
import Icon from "../../components/Icon";
import "../../assets/styles/userCard.css";
import Button from "../../components/Button";

const UserCard = ({ data, position, setUserCard, show }) => {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    function keyClose(e) {
      if (e.key === "Escape") setUserCard((prev) => ({ ...prev, show: false }));
    }
    if (show) {
      setTimeout(() => setShowing(true), 0);
      document.addEventListener("keydown", keyClose);
    } else {
      setTimeout(() => {
        setShowing(false);
        document.removeEventListener("keydown", keyClose);
      }, 300);
    }
  }, [show, setUserCard]);

  return showing || show ? (
    <div className={`user-card ${showing && show ? "open" : ""}`} style={{ top: position.y, left: position.x }}>
      <div className="user-card-header">
        <div className="icon-div">
          <Icon icon="user" style={{ color: "#fff", fontSize: 27 }} />
        </div>
        <button onClick={() => setUserCard((prev) => ({ ...prev, show: false }))}>
          <Icon icon="close" />
        </button>
        <h4>{data.name}</h4>
      </div>
      <div className="body">
        <p>{data.mail}</p>
        <Button icon size="xs" color="dark" onClick={() => window.open(`mailto:${data.mail}`, "emailWindow")}>
          <Icon icon="mail" />
        </Button>
      </div>
    </div>
  ) : (
    ""
  );
};

export default UserCard;
