import React, { useState } from "react";
import Icon from "./Icon";
import Button from "./Button";
import "../assets/styles/Navbar.css";
import { withRouter } from "react-router";

const Navbar = ({ location, history, edificios, ejercicios = [], user }) => {
  const handleEdif = (newEdif, e) => {
    const filteredEjer = ejercicios.filter((ej) => ej.ID_EDIF === parseInt(newEdif));
    const newEjer =
      filteredEjer
        ?.slice()
        .reverse()
        .find((ej) => ej.ESTADO === 1).ID_EJER || "";
    history.push({ pathname: `/${section}/${newEdif}${newEjer ? "/" + newEjer : ""}` });
    e.target.blur();
    setOpen(false);
  };

  const handleEjer = (newEjer, e) => {
    history.push({ pathname: `/${section}/${edif}/${newEjer}` });
    e.target.blur();
    setOpen(false);
  };

  const handleSection = (name) => {
    const newEdif = edif > 0 ? `${edif}/${ejer || ""}` : ``;
    history.push({ pathname: `/${name}/${newEdif}` });
    if (window.innerWidth < 650) setOpen(false);
  };

  const section = location.pathname.split("/")[1] || "principal";
  const edif = parseInt(location.pathname.split("/")[2] || 0);
  const ejer = parseInt(location.pathname.split("/")[3] || 0);

  const [open, setOpen] = useState(false);

  return (
    <>
      <nav className={open ? "open" : ""}>
        <div className="nav-header">
          <a href="/" className="navbar-brand logo">
            Administracion
          </a>
        </div>
        <div className="nav-main">
          <div className="nav-right">
            <div className="avatar-div">
              <div className="avatar">{user.logoText}</div>
            </div>
            <Button className="nav-burger" color="secondary" onClick={() => setOpen(!open)}>
              <Icon icon="bars" style={{ fontSize: 14 }} />
            </Button>
          </div>
          <div className="nav-body">
            <ul>
              <li onClick={() => handleSection("proveedores")} className={section === "proveedores" ? "active" : ""}>
                Proveedores
              </li>
              <li onClick={() => handleSection("personas")} className={section === "personas" ? "active" : ""}>
                Personas
              </li>
            </ul>
            <div className="selects">
              <div className="select-div">
                <select value={edif} onChange={(e) => handleEdif(e.target.value, e)}>
                  <option value="" style={{ fontWeight: 600 }}>
                    {section === "edificio" ? "Nuevo" : "Edificio"}
                  </option>
                  {edificios.map((ed, i) => (
                    <option key={i} value={ed.ID_EDIF}>
                      {ed.NOMBRE}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-div">
                <select value={ejer} onChange={(e) => handleEjer(e.target.value, e)}>
                  <option value="" style={{ fontWeight: 600 }}>
                    Ejercicio
                  </option>
                  {ejercicios
                    .filter((ej) => ej.ID_EDIF === edif)
                    .map((ej, i) => (
                      <option
                        key={i}
                        style={{ color: ej.ESTADO === 0 ? "#08b308" : ej.ESTADO === -1 ? "#da0000" : "" }}
                        value={ej.ID_EJER}
                      >
                        {ej.NOMBRE}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className={open ? "navbar-space open" : "navbar-space"}></div>
    </>
  );
};

export default withRouter(Navbar);
