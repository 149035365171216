import React, { useState } from "react";
import CustomModal from "../../components/Modal";

const Modal = ({ modal, setModal, setUnidades, unidades }) => {
  const [totals, setTotals] = useState({ prop: true, inq: true });

  return (
    <CustomModal
      show={modal.show}
      size="md"
      title="Seleccionar Personas"
      saveMsg="Cerrar"
      setModal={setModal}
      handleSubmit={() => setModal((prev) => ({ ...prev, show: false }))}
    >
      <div style={{ display: "grid", gridTemplateColumns: "150px 1fr 1fr", gap: 10 }}>
        <h4 style={{ paddingLeft: 30 }}>Unidades</h4>
        <h4>Propietarios</h4>
        <h4>Inquilinos</h4>

        <span></span>
        <label style={{ display: "flex", cursor: "pointer", userSelect: "none", alignItems: "center" }}>
          <input
            style={{ width: "auto", marginRight: 10 }}
            type="checkbox"
            onChange={() => {
              setUnidades((prev) => {
                return [...prev].map((u) => ({ ...u, send_P: u.Prop ? !totals.prop : false }));
              });
              setTotals((prev) => ({ ...prev, prop: !prev.prop }));
            }}
            checked={totals.prop}
          />
          Todos
        </label>
        <label style={{ display: "flex", cursor: "pointer", userSelect: "none", alignItems: "center" }}>
          <input
            style={{ width: "auto", marginRight: 10 }}
            type="checkbox"
            onChange={() => {
              setUnidades((prev) => {
                return [...prev].map((u) => ({ ...u, send_I: u.Inq ? !totals.inq : false }));
              });
              setTotals((prev) => ({ ...prev, inq: !prev.inq }));
            }}
            checked={totals.inq}
          />
          Todos
        </label>

        <hr
          style={{
            gridColumn: "1 / -1",
            borderWidth: "1px 0 0 0",
            borderColor: "rgb( 0 0 0 / 10%)",
            width: "90%",
            margin: "0 0 5px 30px",
          }}
        />
        {unidades.map((un) => (
          <React.Fragment key={un.ID_UNID}>
            <h5 style={{ paddingLeft: 30 }}>{un.DEPTO}</h5>
            <label style={{ display: "flex", cursor: "pointer", userSelect: "none", alignItems: "center" }}>
              <input
                style={{ width: "auto", marginRight: 10 }}
                type="checkbox"
                onChange={() =>
                  setUnidades((prev) => {
                    const newUnidades = [...prev];
                    newUnidades.find((u) => u.ID_UNID === un.ID_UNID).send_P = !un.send_P;
                    return newUnidades;
                  })
                }
                checked={un.send_P}
              />
              {un.Prop}
            </label>
            {un.Inq ? (
              <label style={{ display: "flex", cursor: "pointer", userSelect: "none", alignItems: "center" }}>
                <input
                  style={{ width: "auto", marginRight: 10 }}
                  type="checkbox"
                  onChange={() =>
                    setUnidades((prev) => {
                      const newUnidades = [...prev];
                      newUnidades.find((u) => u.ID_UNID === un.ID_UNID).send_I = !un.send_I;
                      return newUnidades;
                    })
                  }
                  checked={un.send_I}
                />
                {un.Inq}
              </label>
            ) : (
              <span></span>
            )}
          </React.Fragment>
        ))}
      </div>
    </CustomModal>
  );
};

export default Modal;
