import React, { useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import customNumber from "../../hooks/customNumber";
import customDate from "../../hooks/customDate";
import ModalCaja from "./ModalCaja";
import ModalMov from "./ModalMov";
import ModalInfo from "./ModalInfo";
import ModalTrans from "./ModalTrans";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const CajasDiv = ({ edif, ejer, ejercicios, cajas, setCajas, setFinanciero }) => {
  const { addToast } = useToasts();
  const ejercicio = ejercicios.find((ej) => ej.ID_EJER === ejer);
  const ejerState = ejercicio ? ejercicio.ESTADO : 0;
  const [modalCaja, setModalCaja] = useState({ action: false, data: {}, show: false });
  const [modalInfo, setModalInfo] = useState({ action: false, data: {}, show: false });
  const [modalTrans, setModalTrans] = useState({ action: false, data: {}, show: false });
  const [modalMov, setModalMov] = useState({ action: false, data: {}, show: false });

  const handleSubmitCaja = (formData) => {
    console.table(modalCaja.action, modalCaja.data);
    setModalCaja((prev) => ({ ...prev, show: false }));
    switch (modalCaja.action) {
      case "EDIT":
        authFetch(`/cajas/${modalCaja.data.ID_CAJA}`, { method: "PUT", body: formData })
          .then((data) =>
            Promise.all([authFetch(`/cajas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)])
          )
          .then(([dataCaja, dataFinanciero]) => {
            setCajas(dataCaja.filter((ca) => ca.ESTADO === 1));
            setFinanciero(dataFinanciero);
          })
          .catch((err) => {
            addToast("Error editando caja", { appearance: "error" });
          });
        break;
      case "ADD":
        authFetch(`/cajas`, { method: "POST", body: { ...formData, ID_EDIF: edif, ID_EJER: ejer } })
          .then((data) =>
            Promise.all([authFetch(`/cajas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)])
          )
          .then(([dataCaja, dataFinanciero]) => {
            setCajas(dataCaja.filter((ca) => ca.ESTADO === 1));
            setFinanciero(dataFinanciero);
          })
          .catch((err) => {
            addToast("Error agregando caja", { appearance: "error" });
          });
        break;
      default:
        break;
    }
  };

  const handleSubmitTrans = (formData) => {
    console.log(formData);
    if (modalTrans.action === "ADD") {
      authFetch("/cajas/transferencia", {
        method: "POST",
        body: { ID_EDIF: edif, ID_EJER: ejer, ID_CAJA: modalTrans.data.ID_CAJA, ...formData },
      })
        .then((data) =>
          Promise.all([authFetch(`/cajas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)])
        )
        .then(([dataCaja, dataFinanciero]) => {
          setCajas(dataCaja.filter((ca) => ca.ESTADO === 1));
          setFinanciero(dataFinanciero);
        })
        .catch((err) => {
          addToast("Error Transfiriendo", { appearance: "error" });
        });
    } else if (modalTrans.action === "EDIT") {
      authFetch("/cajas/transferencia/" + modalTrans.data.ID_MOV, {
        method: "PUT",
        body: { ID_EDIF: edif, ID_EJER: ejer, ID_CAJA: modalTrans.data.ID_CAJA, ...formData },
      })
        .then((data) =>
          Promise.all([authFetch(`/cajas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)])
        )
        .then(([dataCaja, dataFinanciero]) => {
          setCajas(dataCaja.filter((ca) => ca.ESTADO === 1));
          setFinanciero(dataFinanciero);
        })
        .catch((err) => {
          addToast("Error Transfiriendo", { appearance: "error" });
        });
      setModalInfo((prev) => ({ ...prev, show: false }));
    }

    setModalTrans((prev) => ({ ...prev, show: false }));
  };

  const deleteTrans = (mov) => {
    authFetch("/cajas/transferencia/" + mov, {
      method: "DELETE",
    })
      .then((data) =>
        Promise.all([authFetch(`/cajas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)])
      )
      .then(([dataCaja, dataFinanciero]) => {
        setCajas(dataCaja.filter((ca) => ca.ESTADO === 1));
        setFinanciero(dataFinanciero);
      })
      .catch((err) => {
        addToast("Error Transfiriendo", { appearance: "error" });
      });
    setModalInfo((prev) => ({ ...prev, show: false }));
  };

  const handleSubmitMov = (formData) => {
    authFetch(`/cajas/movimiento`, { method: "POST", body: { ...formData, ID_EDIF: edif, ID_EJER: ejer } })
      .then((data) =>
        Promise.all([authFetch(`/cajas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)])
      )
      .then(([dataCaja, dataFinanciero]) => {
        setCajas(dataCaja.filter((ca) => ca.ESTADO === 1));
        setFinanciero(dataFinanciero);
      })
      .catch((err) => {
        addToast("Error agregando caja", { appearance: "error" });
      });
    setModalMov((prev) => ({ ...prev, show: false }));
  };

  const handleDelete = () => {};

  const dataTable = cajas.map((row, i) => {
    return {
      className: row.ESTADO === 0 ? "finalizado" : row.ESTADO === -1 ? "eliminado" : "",
      cells: [
        {
          style: { width: 130 },
          className: "actions-col",
          content: (
            <Actions
              row={row}
              ejerState={ejerState}
              setModalCaja={setModalCaja}
              setModalTrans={setModalTrans}
              setModalInfo={setModalInfo}
              edif={edif}
              ejer={ejer}
              handleDelete={handleDelete}
            />
          ),
        },
        { className: "", content: row.CAJA },
        { className: "", content: row.DESCRIPCION },
        { className: "text-right", content: customNumber(row.SALDO_INICIAL), order: row.SALDO_INICIAL },
        { className: "", content: row.ALIAS },
        {
          className: "text-center",
          content: row.CBU.toString().slice(0, 8) + " " + row.CBU.toString().slice(8, 22),
          order: row.CBU,
        },
        { className: "text-right", content: customNumber(row.SALDO_TOTAL), order: row.SALDO_TOTAL },
        { className: "text-right", content: customNumber(row.RESERVA), order: row.RESERVA },
        { className: "text-right", content: customNumber(row.SALDO), order: row.SALDO },
      ],
    };
  });

  return (
    <>
      <Panel
        header={
          <Header ejerState={ejerState} setModalCaja={setModalCaja} setModalMov={setModalMov} edif={edif} />
        }
        style={{ backgroundColor: "white" }}
        bodyFill
      >
        <Table
          columns={[
            { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
            { content: "Nombre" },
            { content: "Descripción" },
            { content: "Saldo Inicial" },
            { content: "Alias" },
            { content: "CBU" },
            { content: "Saldo Inicial" },
            { content: "Reserva" },
            { content: "Saldo Disponible" },
          ]}
          data={dataTable}
          filterStatus={false}
          style={{ minWidth: 1050 }}
        />
      </Panel>
      <ModalCaja
        ejerState={ejerState}
        modalCaja={modalCaja}
        setModalCaja={setModalCaja}
        handleSubmitCaja={handleSubmitCaja}
        ejercicio={ejercicio}
      />
      <ModalTrans
        modalTrans={modalTrans}
        setModalTrans={setModalTrans}
        handleSubmitTrans={handleSubmitTrans}
        edif={edif}
        ejer={ejer}
        ejercicio={ejercicio}
      />
      <ModalMov
        modalMov={modalMov}
        ejercicio={ejercicio}
        setModalMov={setModalMov}
        handleSubmitMov={handleSubmitMov}
        cajas={cajas}
      />
      <ModalInfo
        modal={modalInfo}
        setModal={setModalInfo}
        setModalTrans={setModalTrans}
        deleteTrans={deleteTrans}
      />
    </>
  );
};

const Actions = ({ ejerState, row, setModalCaja, setModalTrans, setModalInfo, edif, ejer }) => {
  if (ejerState === 1) {
    return (
      <>
        <Button
          icon
          size="xs"
          onClick={() =>
            setModalCaja({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                INICIO: customDate(row.INICIO)[1],
                CIERRE: customDate(row.CIERRE)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button
          icon
          size="xs"
          color="green"
          onClick={() =>
            setModalTrans({
              show: true,
              action: "ADD",
              data: {
                ID_EJER: ejer,
                ID_EDIF: edif,
                ID_CAJA: row.ID_CAJA,
                TIPO: 0,
                MONTO: "",
                FECHA: "",
                DESCRIPCION: "",
                MOSTRAR: true,
              },
            })
          }
        >
          <Icon icon="exchange" />
        </Button>
        <Button
          icon
          size="xs"
          color="yellow"
          onClick={() =>
            setModalInfo({
              show: true,
              action: "ADD",
              data: row,
            })
          }
        >
          <Icon icon="info" />
        </Button>
        <Button icon size="xs" color="red">
          <Icon icon="trash" />
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          icon
          size="xs"
          onClick={() =>
            setModalCaja({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                INICIO: customDate(row.INICIO)[1],
                CIERRE: customDate(row.CIERRE)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button
          icon
          size="xs"
          color="yellow"
          onClick={() =>
            setModalInfo({
              show: true,
              action: "ADD",
              data: row,
            })
          }
        >
          <Icon icon="info" />
        </Button>
      </>
    );
  }
};

const Header = ({ ejerState, setModalCaja, setModalMov, edif }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Cajas</span>
        {ejerState === 1 && (
          <>
            <Button
              size="xs"
              onClick={() =>
                setModalCaja({
                  show: true,
                  action: "ADD",
                  data: {
                    ID_EDIF: edif,
                    CAJA: "",
                    DESCRIPCION: "",
                    TITULAR: "",
                    CUENTA: "",
                    INICIO: "",
                    CIERRE: "",
                    CBU: "",
                    ALIAS: "",
                    SALDO_INICIAL: "",
                  },
                })
              }
            >
              Agregar
            </Button>
            <Button
              size="xs"
              style={{ marginLeft: 5 }}
              onClick={() =>
                setModalMov({
                  show: true,
                  action: "ADD",
                  data: {
                    ID_EDIF: edif,
                    MONTO: "",
                    FECHA: "",
                    DE: "",
                    A: "",
                  },
                })
              }
            >
              Mov. Caja
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CajasDiv;
