import React, { useState, useEffect } from "react";
import Icon from "../components/Icon";
import "../assets/styles/Sidebar.css";
import { withRouter } from "react-router";
import { useQuery } from "react-query";
import authFetch from "../hooks/authFetch";

const Sidebar = ({ location, history, expanded, setExpanded, edificios, ejercicios }) => {
  const [shortcut, setShortcut] = useState(false);

  const sections = [
    {
      letter: "P",
      label: "Principal",
      name: "principal",
    },
    {
      letter: "U",
      label: "Unidades",
      name: "unidades",
    },
    {
      letter: "Ej",
      label: "Ejercicios",
      name: "ejercicios",
    },
    {
      letter: "B",
      label: "Banco",
      name: "banco",
    },
    {
      letter: "G",
      label: "Gastos",
      name: "gastos",
    },
    {
      letter: "P",
      label: "Pagos",
      name: "pagos",
    },
    {
      letter: "S",
      label: "Sueldos",
      name: "sueldos",
    },
    {
      letter: "I",
      label: "Ingresos",
      name: "ingresos",
    },
    {
      letter: "C",
      label: "Cajas",
      name: "cajas",
    },
    {
      letter: "X",
      label: "Expensas",
      name: "expensas",
    },
    {
      letter: "Ed",
      label: "ABM Edificio",
      name: "edificio",
    },
    {
      letter: "D",
      label: "Digitalizacion",
      name: "digitalizacion",
    },
    {
      letter: "M",
      label: "Mails",
      name: "mail",
    },
  ];

  const activeSection = location.pathname.split("/")[1] || "principal";
  const edif = parseInt(location.pathname.split("/")[2] || 0);
  const ejer = parseInt(location.pathname.split("/")[3] || 0);
  const edificio = edificios.find((e) => e.ID_EDIF === edif);
  const ejercicio = ejercicios.find((e) => e.ID_EJER === ejer);
  const { data: bankNotUpdating } = useQuery(["bancoUpdated", edif], () => authFetch("/banco/updated/" + edif), {
    refetchInterval: 1000 * 60,
    enabled: edif > 0,
  });

  useEffect(() => {
    let keys = "";
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("visibilitychange", handleVisibility);

    function handleKeyUp(e) {
      if (e.key === "Alt") {
        setShortcut(false);
        if (keys.length > 0 && parseInt(keys) && sections[parseInt(keys) - 1]) {
          handleSelect(parseInt(keys) - 1);
        }
        if (keys.length === 0) e.preventDefault();
        keys = "";
      }
    }

    function handleKeyDown(e) {
      if (e.key === "Alt") {
      } else if (e.altKey) {
        if (parseInt(e.key)) {
          keys += e.key;
        } else if (e.key === "=") {
          setShortcut(true);
        } else {
          setShortcut(false);
          keys = "";
        }
      }
    }

    function handleVisibility(e) {
      if (document.visibilityState === "hidden" && shortcut) {
        setShortcut(false);
      }
    }

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("visibilitychange", handleVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer]);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const handleSelect = (newSection) => {
    const newEdif = edif > 0 ? `${edif}/${ejer || ""}` : ``;
    if (window.innerWidth < 650) setExpanded(false);
    history.push({ pathname: `/${sections[newSection].name}/${newEdif}` });
  };

  return (
    <>
      <div className={`sidebar ${expanded ? "sidebar-expanded" : ""}`}>
        <h3>Menú</h3>
        <button className="toggle-button" aria-label="Toggle sidebar" onClick={handleToggle}>
          <Icon icon="arrow-circle-left" className={!expanded ? `rotated` : ""} />
        </button>
        {sections.map((section, i) => (
          <div
            key={i}
            className={`nav-element${activeSection === section.name ? " active" : ""}${shortcut ? " shortcut" : ""}${
              bankNotUpdating && section.name === "banco" ? " not-updating" : ""
            }`}
            onClick={() => handleSelect(i)}
          >
            {i + 1}
            <i className="nav-element-label">{section.label}</i>
          </div>
        ))}
      </div>
      <div className="breadcrumb">
        <span className="mobile-title" style={{ alignSelf: "center" }}>
          {sections.find((o) => o.name === activeSection)?.label || "Ninguna seleccionada"}
          {edificio && <span> / {edificio.NOMBRE}</span>}
          {ejercicio && <span> / {ejercicio.NOMBRE}</span>}
        </span>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
