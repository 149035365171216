import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import { Table } from "../../components/Table";
import authFetch from "../../hooks/authFetch";
import customDate from "../../hooks/customDate";
import customNumber from "../../hooks/customNumber";

const ModalInfo = ({ modal, setModal }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (modal.show) authFetch(`/proveedores/info/${modal.data.provId}`).then((data) => setData(data));
    //eslint-disable-next-line
  }, [modal.data.edif, modal.data.provId]);

  return (
    <CustomModal show={modal.show} title={`Informacion ${modal.data.proveedor}`} setModal={setModal} size="xl">
      <Table
        columns={[
          { content: "Edificio", sortable: true, filterable: true },
          { content: "Ejercicio", sortable: true, filterable: true },
          { content: "Titulo", sortable: true, filterable: true },
          { content: "Factura", sortable: true, filterable: true },
          { content: "Fecha", sortable: true, filterable: true },
          { content: "Caja", sortable: true, filterable: true },
          { content: "Monto", sortable: true, filterable: true },
          { content: "Calsificacion", sortable: true, filterable: true },
        ]}
        data={data.map((row) => ({
          cells: [
            { content: row.EDIFICIO },
            { content: row.NOMBRE },
            { content: row.TITULO },
            { content: row.FACTURA },
            { className: "text-center", content: customDate(row.FECHA)[0], order: row.FECHA },
            { className: "text-center", content: row.CAJA },
            { className: "text-right", content: customNumber(row.MONTO), order: row.MONTO },
            { className: "text-center", content: row.CLASIFICACION },
          ],
        }))}
      />
    </CustomModal>
  );
};

export default ModalInfo;
