import React from "react";
import CustomModal from "../../components/Modal";
import { useForm } from "react-hook-form";
import Input, { NumberInput } from "../../components/Input";
import CajasSelect from "../../components/CajasSelect";

const ModalAlquiler = ({ modalAlquiler, ejer, setModalAlquiler, handleSubmitAlquiler }) => {
  const { register, reset, errors, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitAlquiler(data));

  return (
    <CustomModal
      show={modalAlquiler.show}
      setModal={setModalAlquiler}
      title={modalAlquiler.action === "ADD" ? "Agregar Alquiler" : `Editar ${modalAlquiler.data.DETALLE}`}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <Input
          label="Detalle"
          name="DETALLE"
          autoFocus
          errors={errors}
          errMsg="Detalle requerido"
          value={modalAlquiler.data.DETALLE}
          register={register({ required: true })}
        />
        <CajasSelect reset={reset} ejer={ejer} register={register} errors={errors} />
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          errMsg="Monto Requerido"
          value={modalAlquiler.data.MONTO}
          register={register({ required: true })}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default ModalAlquiler;
