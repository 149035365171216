import React, { useEffect, useState } from "react";
import Files from "react-butterfiles";
import Masonry from "../../components/Masonry";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import Sueldo from "./Sueldo";
import Button from "../../components/Button";
import Modal from "./Modal";
import ModalEditEmp from "./ModalEditEmp";
import ModalListEmp from "./ModalListEmp";

const Sueldos = ({ match, ejercicios, edificios, setEjercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);
  const edificio = edificios.find((a) => a.ID_EDIF === edif) || {};
  const ejercicio = ejercicios.find((a) => a.ID_EJER === ejer);

  const [sueldos, setSueldos] = useState([]);
  const [modal, setModal] = useState({ data: {}, action: "", show: false });
  const [modalListEmp, setModalListEmp] = useState({ data: {}, action: "", show: false });
  const [modalEditEmp, setModalEditEmp] = useState({ data: {}, action: "", show: false });
  const [empleados, setEmpleados] = useState([]);

  const handleSuccess = (data) => {
    const file = data[0].src ? data[0].src.file : data[0].file.src.file;
    const formData = new FormData();
    formData.append("image", file);
    formData.append("ID_EDIF", edif);
    formData.append("ID_EJER", ejer);
    formData.append("ID_CAJA", edificio.ID_CAJA);
    formData.append("ID_EJER_ANT", ejercicio.ID_EJER_ANT);
    formData.append("TITULO", "Haberes " + ejercicio.NOMBRE);
    formData.append("TIPO_EXP", edificio.TIPO_EXP.split(";")[edificio.EXP_DEF - 1]);
    authFetch("/sueldos/uploadFile", {
      method: "POST",
      body: formData,
    })
      .then((data) => authFetch(`/sueldos/${ejer}`))
      .then((data) => setSueldos(data))
      .catch((err) => {
        console.log(err.response.data?.err?.msg);
        if (err.response.data?.err?.msg === "INCORRECT EDIF")
          addToast("Sueldo en Edificio equivocado!", { appearance: "error" });
        else addToast("Error al cargar los sueldos!", { appearance: "error" });
      });
  };

  useEffect(() => {
    authFetch(`/sueldos/${ejer}`)
      .then((data) => setSueldos(data))
      .catch((err) => {
        addToast("Error al cargar sueldos!", { appearance: "error" });
      });

    authFetch(`/sueldos/empleados/${edif}/${ejer}`)
      .then((data) => setEmpleados(data))
      .catch((err) => {
        addToast("Error al cargar los empleados!", { appearance: "error" });
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer]);

  const handleModal = (formData) => {
    switch (modal.action) {
      case "ADD":
        authFetch(`/sueldos`, {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, DESDE: ejercicio.DESDE, ...formData },
        })
          .then((res) => {
            authFetch(`/sueldos/${ejer}`)
              .then((data) => setSueldos(data))
              .catch((err) => {
                addToast("Error al cargar sueldos!", { appearance: "error" });
              });
          })
          .catch((err) => addToast("Error al agregar Sueldo!", { appearance: "error" }));
        setModal((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/sueldos/${modal.data.ID_MOV}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then((res) => {
            authFetch(`/sueldos/${ejer}`)
              .then((data) => setSueldos(data))
              .catch((err) => {
                addToast("Error al cargar sueldos!", { appearance: "error" });
              });
          })
          .catch((err) => {
            addToast("Error editando pago!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }
  };

  const handleEmp = (value) => {
    if (modalEditEmp.action === "EDIT") {
      console.log(value);
      authFetch("/sueldos/empleados/" + modalEditEmp.data.ID_EMP, { method: "PUT", body: value })
        .then((data) => authFetch(`/sueldos/empleados/${edif}/${ejer}`))
        .then((data) => setEmpleados(data))
        .catch((err) => {
          addToast("Error al editar empleado!", { appearance: "error" });
        });
      setModalEditEmp((prev) => ({ ...prev, show: false }));
    } else {
      console.log(value);
      authFetch("/sueldos/empleados", { method: "POST", body: { ...value, edif: edif } })
        .then((data) => authFetch(`/sueldos/empleados/${edif}/${ejer}`))
        .then((data) => setEmpleados(data))
        .catch((err) => {
          addToast("Error al editar empleado!", { appearance: "error" });
        });
      setModalEditEmp((prev) => ({ ...prev, show: false }));
    }
  };

  return (
    <>
      <div className="container">
        <Files
          multiple={false}
          maxSize="2mb"
          multipleMaxSize="10mb"
          accept={[".xlsx", ".txt"]}
          onSuccess={handleSuccess}
          onError={handleSuccess}
        >
          {({ browseFiles, getDropZoneProps }) => {
            return (
              <div>
                <div
                  {...getDropZoneProps({
                    style: {
                      position: "relative",
                      width: "100%",
                      minHeight: 90,
                      marginBottom: 20,
                      border: "2px lightgray dashed",
                    },
                  })}
                >
                  <div
                    style={{
                      position: "absolute",
                      userSelect: "none",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    Arrastre aquí su archivo.
                    <div style={{ display: "flex" }}>
                      <Button onClick={browseFiles} color="secondary" size="xs" active>
                        Abrir Archivos
                      </Button>
                      <span style={{ margin: "auto 10px" }}>o</span>
                      <Button onClick={(e) => setModal({ data: {}, action: "ADD", show: true })} size="xs">
                        Cargar manual
                      </Button>
                      <span style={{ margin: "auto 10px" }}>|</span>
                      <Button
                        onClick={(e) => setModalListEmp({ data: empleados, action: "SHOW", show: true })}
                        size="xs"
                      >
                        Ver Empleados
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Files>

        <Masonry
          breakpoints={{
            1350: 2,
            1000: 1,
          }}
          defaultCols={3}
          gap={20}
        >
          {sueldos.map((sueldo, i) => (
            <div key={i}>
              {sueldo && <Sueldo ejer={ejer} setModal={setModal} setSueldos={setSueldos} sueldo={sueldo} />}
            </div>
          ))}
        </Masonry>
      </div>
      <Modal
        modal={modal}
        setModal={setModal}
        empleados={empleados}
        ejer={ejer}
        handleSubmit={handleModal}
        edificio={edificio}
      />
      <ModalEditEmp modal={modalEditEmp} setModal={setModalEditEmp} handleSubmit={handleEmp} />
      <ModalListEmp
        modal={modalListEmp}
        setModal={setModalListEmp}
        setModalEditEmp={setModalEditEmp}
        empleados={empleados}
        handleSubmit={handleEmp}
      />
    </>
  );
};

export default Sueldos;
