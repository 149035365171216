import React, { useEffect } from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput, NumberInput, SelectInput } from "../../components/Input";
import { useForm } from "react-hook-form";

const Modal = ({ modal, edif, setModal, handleSubmit: handleSubmitModal, unidades }) => {
  const { register, errors, handleSubmit, reset } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  useEffect(() => {
    reset({ ID_UNID: modal.data.ID_UNID });
  }, [unidades, modal.data.ID_UNID, reset]);

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? `Agregar gasto de Amenities` : `Editar gasto de Amenities`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          errMsg="Monto requerido"
          autoFocus
          value={modal.data.MONTO}
          register={register({ required: true })}
        />
        <SelectInput
          label="Unidad"
          name="ID_UNID"
          errors={errors}
          errMsg="Unidad requerida"
          disabled={modal.action === "EDIT"}
          data={unidades.map((ca) => ({ label: `${ca.depto}`, value: ca.ID_UNID }))}
          register={register({ required: true, minLength: 1 })}
        />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha Requerido"
          value={modal.data.FECHA}
          autoComplete={{ mm: true, yyyy: true }}
          register={register({ required: true })}
        />
        <Input label="Detalle" name="DETALLE" value={modal.data.DETALLE} register={register} />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
