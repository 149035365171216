import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput, SelectInput } from "../../components/Input";
import { useForm } from "react-hook-form";

const Modal = ({ modal, setModal, handleSubmit: handleSubmitModal, edificio }) => {
  const disabled = modal.action === "ADD" ? false : modal.data.ESTADO === 1 ? false : true;
  const [modsData, setModsData] = useState([]);
  const { register, errors, handleSubmit } = useForm({
    defaultValues: modal.data,
  });
  const onSubmit = handleSubmit((data) => handleSubmitModal(data, modsData));

  const tipoExp = [];

  (edificio.TIPO_EXP || "").split(";").forEach((te) => {
    if (te && te.length >= 1) {
      tipoExp.push({ value: te, label: te });
    }
  });

  tipoExp.push({ value: "DIRECTO", label: "Directo" });

  useEffect(() => {
    setModsData(
      Array.from({ length: 7 }).map((a, i) => {
        const data = modal.data["MOD_" + (i + 1)]?.split(";") || [];
        return {
          num: i + 1,
          tipoExp: data[0] || "",
          tipo: data[1] || "",
          monto: data[2] || 0,
          texto: data[3] || "",
          data: data,
        };
      })
    );
  }, [modal.show, modal.data]);

  let add = undefined;
  const mods = modsData.map((mod, i) => {
    if (add === undefined && mod.monto === 0) add = i;
    if (add === undefined || i <= add)
      return (
        <div key={i} style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 10 }}>
          <label style={{ gridColumn: "1 / -1" }}>Modificación {mod.num}</label>
          <SelectInput
            value={mod.tipoExp}
            data={tipoExp}
            onChange={(e) => {
              const value = e.target.value;
              setModsData((prev) => {
                const newMods = [...prev];
                newMods[i].tipoExp = value;
                return newMods;
              });
            }}
          />
          <SelectInput
            value={mod.tipo}
            data={[
              { label: "Monto Extra", value: "EXTRA" },
              { label: "Monto Fijo", value: "FIJO" },
            ]}
            onChange={(e) => {
              const value = e.target.value;
              setModsData((prev) => {
                const newMods = [...prev];
                newMods[i].tipo = value;
                return newMods;
              });
            }}
          />
          <Input
            placeholder="Monto"
            value={mod.monto || ""}
            onChange={(e) => {
              const value = e.target.value;
              setModsData((prev) => {
                const newMods = [...prev];
                newMods[i].monto = value;
                return newMods;
              });
            }}
          />
          <div style={{ gridColumn: "1 / -1", marginTop: -24, marginBottom: 24 }}>
            <Input
              placeholder="Descripcion"
              value={mod.texto || ""}
              onChange={(e) => {
                const value = e.target.value;
                setModsData((prev) => {
                  const newMods = [...prev];
                  newMods[i].texto = value;
                  return newMods;
                });
              }}
            />
          </div>
        </div>
      );
    else return <></>;
  });

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? "Agregar Ejercicio" : `Editar ${modal.data.NOMBRE}`}
      setModal={setModal}
      handleSubmit={onSubmit}
      size="md"
    >
      <form onSubmit={onSubmit}>
        <Input
          label="Nombre"
          name="NOMBRE"
          errors={errors}
          autoFocus
          errMsg="Nombre Requerido"
          value={modal.data.NOMBRE}
          register={register({ required: true })}
          disabled={disabled}
        />
        <DateInput
          label="Desde"
          name="DESDE"
          errors={errors}
          errMsg="Desde Requerido"
          autoComplete={{ dd: "01", yyyy: true }}
          value={modal.data.DESDE}
          register={register({ required: true })}
          disabled={disabled}
        />
        <DateInput
          label="Hasta"
          name="HASTA"
          errors={errors}
          errMsg="Hasta Requerido"
          autoComplete={{ dd: new Date(2020, new Date().getMonth() + 1, 0).getDate(), yyyy: true }}
          value={modal.data.HASTA}
          register={register({ required: true })}
          disabled={disabled}
        />
        <DateInput
          label="Vencimiento"
          name="VENC"
          errors={errors}
          errMsg="Vencimiento Requerido"
          autoComplete={{ dd: 10, yyyy: true }}
          value={modal.data.VENC}
          register={register({ required: true })}
          disabled={disabled}
        />
        {mods}
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
