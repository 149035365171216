import React, { useState } from "react";
import Select from "react-select";

const ReactSelect = ({ setValue, getValues, control, autoFocus = false, value, data, label }) => {
  const [filter, setFilter] = useState(true);

  const handleChange = (selected) => {
    setValue("ID_PROV", selected.value);
    setValue("PROVEEDOR", selected.label);
    setValue("TITULO", selected.TITULO_DEF || selected.TITULO || "");
    if (selected.MONTO_DEF && selected.MONTO_DEF !== 0) setValue("MONTO", selected.MONTO_DEF || "");
  };

  const dataFiltered = filter ? data.filter((prov) => prov.VINCULO > 0) : data;

  return (
    <div className="form-group" style={{ position: "relative" }}>
      <label>{label}</label>
      <Select
        options={dataFiltered}
        autoFocus={autoFocus}
        onChange={handleChange}
        defaultValue={data.find((op) => op.value === value)}
      />
      <input
        type="checkbox"
        checked={filter}
        onChange={() => setFilter((prev) => !prev)}
        tabIndex={-1}
        style={{ position: "absolute", top: 32, right: 20 }}
      />
    </div>
  );
};

export default ReactSelect;
