import React, { useState, useEffect } from "react";
import Icon from "../components/Icon";
import Options from "../components/Options";
import "../assets/styles/Secondbar.css";
import { withRouter } from "react-router";

const Secondbar = ({ location, history, expanded, setExpanded, edificios, ejercicios }) => {
  const [shortcut, setShortcut] = useState(false);

  const sections = [
    {
      letter: "P",
      label: "Principal",
      name: "principal",
    },
    {
      letter: "U",
      label: "Unidades",
      name: "unidades",
    },
    {
      letter: "Ej",
      label: "Ejercicios",
      name: "ejercicios",
    },
    {
      letter: "B",
      label: "Banco",
      name: "banco",
    },
    {
      letter: "G",
      label: "Gastos",
      name: "gastos",
    },
    {
      letter: "P",
      label: "Pagos",
      name: "pagos",
    },
    {
      letter: "S",
      label: "Sueldos",
      name: "sueldos",
    },
    {
      letter: "I",
      label: "Ingresos",
      name: "ingresos",
    },
    {
      letter: "C",
      label: "Cajas",
      name: "cajas",
    },
    {
      letter: "X",
      label: "Expensas",
      name: "expensas",
    },
    {
      letter: "Ed",
      label: "ABM Edificio",
      name: "edificio",
    },
    {
      letter: "D",
      label: "Digitalizacion",
      name: "digitalizacion",
    },
    {
      letter: "M",
      label: "Mails",
      name: "mail",
    },
  ];

  const activeSection = location.pathname.split("/")[1] || "principal";
  const edif = parseInt(location.pathname.split("/")[2] || 0);
  const ejer = parseInt(location.pathname.split("/")[3] || 0);
  const edificio = edificios.find((e) => e.ID_EDIF === edif);
  const ejercicio = ejercicios.find((e) => e.ID_EJER === ejer);

  const [mobile, setMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    window.onresize = () => setMobile(window.innerWidth < 1000);
  }, []);

  useEffect(() => {
    let keys = "";
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("visibilitychange", handleVisibility);

    function handleKeyUp(e) {
      if (e.key === "Alt") {
        setShortcut(false);
        if (keys.length > 0 && parseInt(keys) && sections[parseInt(keys) - 1]) {
          handleSelect(parseInt(keys) - 1);
        }
        if (keys.length === 0) e.preventDefault();
        keys = "";
      }
    }

    function handleKeyDown(e) {
      if (e.key === "Alt") {
      } else if (e.altKey) {
        if (parseInt(e.key)) {
          keys += e.key;
        } else if (e.key === "=") {
          setShortcut(true);
        } else {
          setShortcut(false);
          keys = "";
        }
      }
    }

    function handleVisibility(e) {
      if (document.visibilityState === "hidden" && shortcut) {
        setShortcut(false);
      }
    }

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("visibilitychange", handleVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer]);

  const handleSelect = (newSection) => {
    console.log(newSection);
    const newEdif = edif > 0 ? `${edif}/${ejer || ""}` : ``;
    history.push({ pathname: `/${newSection}/${newEdif}` });
  };

  return mobile ? (
    <>
      <div className="secondbar">
        <div className={`mobile-tab tabs-pills`} onClick={() => setExpanded((prev) => !prev)}>
          <span className="mobile-title" style={{ alignSelf: "center" }}>
            {sections.find((o) => o.name === activeSection)?.label || "Ninguna seleccionada"}
            {edificio && <span> / {edificio.NOMBRE}</span>}
            {ejercicio && <span> / {ejercicio.NOMBRE}</span>}
          </span>

          <Icon icon="arrow-down" />
          <Options
            data={sections}
            show={expanded}
            setShow={setExpanded}
            value={activeSection}
            handleSubmit={handleSelect}
          />
        </div>
      </div>
      <div className="secondbar-space"></div>
    </>
  ) : (
    <>
      <div className="secondbar">
        <ul className={`tabs tabs-pills`}>
          {sections.map((option, i) => (
            <li
              key={i}
              className={option.name === activeSection ? "active" : ""}
              onClick={() => handleSelect(option.name)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="secondbar-space"></div>
    </>
  );
};

export default withRouter(Secondbar);
