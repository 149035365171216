import React from "react";
import Pills from "./Pills";
import Icon from "./Icon";

const CheckedPills = ({ checked = false, children, handleClick }) => {
  return (
    <Pills size="xl" color={checked ? "blue" : "grey"} onClick={handleClick} style={{ marginRight: 4 }}>
      {checked && <Icon icon="check" style={{ marginRight: 4, opacity: checked ? 1 : 0 }} />}
      {children}
    </Pills>
  );
};

export default CheckedPills;
