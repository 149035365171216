import React, { useEffect } from "react";
import CustomModal from "../../components/Modal";
import Input, { NumberInput } from "../../components/Input";
import { useForm } from "react-hook-form";

const Modal = ({ modal, setModal, handleSubmit: handleSubmitModal }) => {
  const disabled = modal.action === "ADD" ? false : modal.data.ESTADO === 1 ? false : true;

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: modal.data,
  });

  useEffect(() => {
    if (modal.show) {
      register("patrimonioCont1");
      setValue("patrimonioCont1", modal.data.PATRI_EXTRA_CONT1 === 1 ? true : false);
      register("patrimonioCont2");
      setValue("patrimonioCont2", modal.data.PATRI_EXTRA_CONT2 === 1 ? true : false);
      register("patrimonioCont3");
      setValue("patrimonioCont3", modal.data.PATRI_EXTRA_CONT3 === 1 ? true : false);

      register("aCobrarCont");
      setValue("aCobrarCont", modal.data.A_COBRAR_CONT === 1 ? true : false);
      register("activosCont");
      setValue("activosCont", modal.data.ACTIVOS_CONT === 1 ? true : false);
      register("noPagadosCont");
      setValue("noPagadosCont", modal.data.NO_PAGADOS_CONT === 1 ? true : false);
      register("novedadesCont");
      setValue("novedadesCont", modal.data.NOVEDADES_CONT === 1 ? true : false);
      register("juiciosCont");
      setValue("juiciosCont", modal.data.JUICIOS_CONT === 1 ? true : false);
    }
  }, [modal.data, modal.show, register, setValue]);

  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  return (
    <CustomModal
      show={modal.show}
      title="Opciones"
      setModal={setModal}
      handleSubmit={onSubmit}
      size="lg"
      disabled={disabled}
    >
      <p className="text-right" style={{ marginBottom: -10 }}>
        Mantener
      </p>
      <form onSubmit={onSubmit}>
        <>
          <div style={{ marginBottom: "24px" }}>
            <div style={{ display: "flex", width: "100%", alignItems: "end", gap: 24 }}>
              <div style={{ width: "100%", display: "flex", gap: 12 }}>
                <div style={{ width: "100%" }}>
                  <Input
                    label={"Patrimonio Extra 1"}
                    name={"patrimonioNombre1"}
                    value={modal.data.PATRI_EXTRA_NOMBRE1}
                    register={register({ required: false })}
                    disabled={disabled}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <NumberInput
                    label={"Monto"}
                    name={"patrimonioMonto1"}
                    value={modal.data.PATRI_EXTRA_MONTO1}
                    register={register}
                    disabled={disabled}
                  />
                </div>
              </div>
              <input
                type="checkbox"
                checked={watch("patrimonioCont1")}
                onChange={(e) => setValue("patrimonioCont1", e.target.checked)}
                style={{ width: 14, margin: "12px 22px 12px 8px" }}
              />
            </div>
            <div style={{ display: "flex", width: "100%", alignItems: "end", gap: 24 }}>
              <div style={{ width: "100%", display: "flex", gap: 12 }}>
                <div style={{ width: "100%" }}>
                  <Input
                    label={"Patrimonio Extra 2"}
                    name={"patrimonioNombre2"}
                    value={modal.data.PATRI_EXTRA_NOMBRE2 ?? ""}
                    register={register({ required: false })}
                    disabled={disabled}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <NumberInput
                    label={"Monto"}
                    name={"patrimonioMonto2"}
                    value={modal.data.PATRI_EXTRA_MONTO2}
                    register={register}
                    disabled={disabled}
                  />
                </div>
              </div>
              <input
                type="checkbox"
                checked={watch("patrimonioCont2")}
                onChange={(e) => setValue("patrimonioCont2", e.target.checked)}
                style={{ width: 14, margin: "12px 22px 12px 8px" }}
              />
            </div>
            <div style={{ display: "flex", width: "100%", alignItems: "end", gap: 24 }}>
              <div style={{ width: "100%", display: "flex", gap: 12 }}>
                <div style={{ width: "100%" }}>
                  <Input
                    label={"Patrimonio Extra 3"}
                    name={"patrimonioNombre3"}
                    value={modal.data.PATRI_EXTRA_NOMBRE3 ?? ""}
                    register={register({ required: false })}
                    disabled={disabled}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <NumberInput
                    label={"Monto"}
                    name={"patrimonioMonto3"}
                    value={modal.data.PATRI_EXTRA_MONTO3}
                    register={register}
                    disabled={disabled}
                  />
                </div>
              </div>
              <input
                type="checkbox"
                checked={watch("patrimonioCont3")}
                onChange={(e) => setValue("patrimonioCont3", e.target.checked)}
                style={{ width: 14, margin: "12px 22px 12px 8px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "end", gap: 24 }}>
            <div style={{ width: "100%" }}>
              <NumberInput
                label="Expensas y otros conceptos a cobrar"
                name="aCobrar"
                value={modal.data.A_COBRAR}
                register={register}
                disabled={disabled}
              />
            </div>
            <input
              type="checkbox"
              checked={watch("aCobrarCont")}
              onChange={(e) => setValue("aCobrarCont", e.target.checked)}
              style={{ width: "inherit", margin: "12px 22px 12px 8px" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "end", gap: 24 }}>
            <div style={{ width: "100%" }}>
              <NumberInput
                label="Otros activos"
                name="activos"
                value={modal.data.ACTIVOS}
                register={register}
                disabled={disabled}
              />
            </div>
            <input
              type="checkbox"
              checked={watch("activosCont")}
              onChange={(e) => setValue("activosCont", e.target.checked)}
              style={{ width: "inherit", margin: "12px 22px 12px 8px" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "end", gap: 24 }}>
            <div style={{ width: "100%" }}>
              <NumberInput
                label="Gastos devegnados no pagados"
                name="noPagados"
                value={modal.data.NO_PAGADOS}
                register={register}
                disabled={disabled}
              />
            </div>
            <input
              type="checkbox"
              checked={watch("noPagadosCont")}
              onChange={(e) => setValue("noPagadosCont", e.target.checked)}
              style={{ width: "inherit", margin: "12px 22px 12px 8px" }}
            />
          </div>
          <div style={{ height: 24 }}></div>
          <div style={{ display: "flex", alignItems: "end", gap: 24 }}>
            <div style={{ width: "100%" }}>
              <div className="form-group">
                <label>Novedades</label>
                <textarea
                  name="novedades"
                  rows={8}
                  ref={register}
                  disabled={disabled}
                  defaultValue={modal.data.NOVEDADES}
                ></textarea>
              </div>
            </div>
            <input
              type="checkbox"
              checked={watch("novedadesCont")}
              onChange={(e) => setValue("novedadesCont", e.target.checked)}
              style={{ width: "inherit", margin: "85px 22px 85px 8px" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "end", gap: 24 }}>
            <div style={{ width: "100%" }}>
              <div className="form-group">
                <label>Juicios</label>
                <textarea
                  name="juicios"
                  rows={8}
                  ref={register}
                  disabled={disabled}
                  defaultValue={modal.data.JUICIOS}
                ></textarea>
              </div>
            </div>
            <input
              type="checkbox"
              checked={watch("juiciosCont")}
              onChange={(e) => setValue("juiciosCont", e.target.checked)}
              style={{ width: "inherit", margin: "85px 22px 85px 8px" }}
            />
          </div>

          <input type="submit" style={{ display: "none" }} />
        </>
      </form>
    </CustomModal>
  );
};

export default Modal;
