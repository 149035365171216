import React from "react";
import CustomModal from "../../components/Modal";
import CajasSelect from "../../components/CajasSelect";
import { useForm } from "react-hook-form";
import { DateInput, NumberInput, SelectInput } from "../../components/Input";

const ModalCaja = ({ modalTrasRes, setModalTrasRes, handleSubmitTrasRes, reservas, ejer }) => {
  const { register, errors, reset, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitTrasRes(data));

  const reservasSelect = [];
  reservas.forEach((resCaja) => {
    if (reservasSelect.findIndex((res) => res.ID_RES === resCaja.ID_RES) === -1) {
      reservasSelect.push({ ID_RES: resCaja.ID_RES, label: resCaja.RESERVA, value: resCaja.ID_RES });
    }
  });

  return (
    <CustomModal
      show={modalTrasRes.show}
      setModal={setModalTrasRes}
      title={modalTrasRes.action === "ADD" ? "Hacer Traspaso a Reserva" : `Editar Traspaso a Reserva`}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          errMsg="Monto requerido"
          register={register({ required: true })}
        />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha requerida"
          autoComplete={{ mm: true, yyyy: true }}
          register={register({ required: true })}
        />
        <SelectInput
          label="Destino"
          name="DESTINO"
          errors={errors}
          errMsg="Destino requerido"
          data={reservasSelect}
          register={register({ required: true, minLength: 1 })}
        />
        <SelectInput
          label="Direccion"
          name="DIRECCION"
          errors={errors}
          errMsg="Direccion requerida"
          data={[
            { label: "Depositar", value: 1 },
            { label: "Retirar", value: -1 },
          ]}
          register={register({ required: true, minLength: 1 })}
        />
        <CajasSelect reset={reset} ejer={ejer} register={register} errors={errors} />

        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default ModalCaja;
