import React, { useEffect } from "react";
import CustomModal from "../../components/Modal";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";

const Modal = ({ modal, setModal, handleSubmit: handleSubmitModal }) => {
  const { register, errors, handleSubmit, watch, setValue } = useForm({
    defaultValues: { NECESITA_FACTURA: modal.data.NECESITA_FACTURA === 1, ...modal.data },
  });

  useEffect(() => {
    register("NECESITA_FACTURA");
    setValue("NECESITA_FACTURA", modal.data.NECESITA_FACTURA === 0 ? false : true);
  }, [modal.data, register, setValue]);

  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? "Agregar Proveedor" : `Editar ${modal.data.NOMBRE}`}
      setModal={setModal}
      handleSubmit={onSubmit}
      size="md"
    >
      <form onSubmit={onSubmit}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0 10px" }}>
          <Input
            label="Nombre"
            name="NOMBRE"
            errors={errors}
            autoFocus
            errMsg="Nombre Requerido"
            value={modal.data.NOMBRE}
            register={register({ required: true })}
          />
          <Input
            label="CUIT"
            name="CUIT"
            errors={errors}
            errMsg="El CUIT debe tenes 10 o 11 caracteres"
            value={modal.data.CUIT}
            register={register({ minLength: 10, maxLength: 11 })}
          />
          <Input label="Descripción" name="DESCRIPCION" value={modal.data.DESCRIPCION} register={register} />
          <Input label="Titulo Default" name="TITULO" value={modal.data.TITULO} register={register} />
          <Input
            label="CBU"
            name="CBU"
            errors={errors}
            errMsg="El CBU debe ser de 22 caracteres"
            value={modal.data.CBU}
            register={register({ minLength: 22, maxLength: 22 })}
          />
          <Input label="Dirección" name="DIRECCION" value={modal.data.DIRECCION} register={register} />
          <Input label="Mail" name="MAIL" value={modal.data.MAIL} register={register} />
          <Input label="Telefono" name="TELEFONO" value={modal.data.TELEFONO} register={register} />
          <Input label="Matricula" name="MATRICULA" value={modal.data.MATRICULA} register={register} />
          <Input label="Seguro" name="SEGURO" value={modal.data.SEGURO} register={register} />
        </div>
        <div>
          <label style={{ display: "flex", gap: 12 }}>
            <input
              style={{ width: 14 }}
              type="checkbox"
              checked={watch("NECESITA_FACTURA")}
              onChange={(e) => setValue("NECESITA_FACTURA", e.target.checked)}
            />
            Necesita Factura
          </label>
        </div>

        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
