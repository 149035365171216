import React from "react";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import customNumber from "../../hooks/customNumber";

const Financiero = ({ ejer, edif, style, financiero, setFinanciero, filas = [] }) => {
  const tableData = [
    { cat: "saldo_inicial", cells: [{ content: "Saldo al Inicio (dd/mm)" }] },
    { cat: "ingresos", cells: [{ content: "Ingresos por Expensas" }] },
    { cat: "traspasos", cells: [{ content: "Traspaso entre Cajas" }] },
    { cat: "gastos_pagados", cells: [{ content: "Gastos Pagados en el Ejercicio" }] },
    { cat: "alquileres", cells: [{ content: "Alquileres" }] },
    { cat: "ingresos_extraordinarios", cells: [{ content: "Ingresos Extraordinarios" }] },
    { cat: "ingresos_no_identificados", cells: [{ content: "Ingresos no Identificados" }] },
    { cat: "saldo_total", className: "header", cells: [{ content: "Saldo Total (dd/mm)" }] },
    { cat: "reservas", cells: [{ content: "Reservas" }] },
    { cat: "saldo_disponible", className: "header", cells: [{ content: "Saldo Disponible" }] },
  ];

  const totalCaja = {};

  tableData.forEach((tr) => {
    let totalCat = 0;
    tr.cells.push(
      ...Object.values(financiero)
        .sort((a, b) => (a.nombre < b.nombre ? 1 : -1))
        .map((caja) => {
          if (!totalCaja[caja.id]) totalCaja[caja.id] = 0;
          totalCaja[caja.id] += caja[tr.cat] || 0;
          totalCat += caja[tr.cat] || 0;
          return {
            className: "text-right",
            content: customNumber(caja[tr.cat] || 0),
          };
        }),
      { className: "text-right", content: customNumber(totalCat) }
    );
  });

  Object.keys(totalCaja).forEach((tot) => {
    if (
      (totalCaja[tot] - financiero[tot]["saldo_disponible"] - financiero[tot]["saldo_total"]).toFixed(2) -
        financiero[tot]["saldo_disponible"].toFixed(2) !==
      0
    ) {
      console.log(
        (totalCaja[tot] - financiero[tot]["saldo_disponible"] - financiero[tot]["saldo_total"]).toFixed(2),
        financiero[tot]["saldo_disponible"].toFixed(2)
      );
      tableData[tableData.length - 1].className += " text-error";
    }
  });

  return (
    <div style={style}>
      <Panel style={{ backgroundColor: "white" }} bodyFill>
        <Table
          data={filas.length > 0 ? filas.map((a) => tableData.find((b) => b.cat === a)) : tableData}
          style={{ minWidth: 500 }}
          columns={[
            { content: "Mov Financiero" },
            ...Object.values(financiero)
              .sort((a, b) => (a.nombre < b.nombre ? 1 : -1))
              .map((d) => ({ content: d.nombre })),
            { content: "Total" },
          ]}
        />
      </Panel>
    </div>
  );
};

export default Financiero;
