import React, { useState } from "react";
import Panel from "../../components/Panel";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Login = ({ history, setUser, setAuth, setSubbar }) => {
  const { addToast } = useToasts();
  const { register, errors, handleSubmit } = useForm();
  const [error, setError] = useState(false);
  const onSubmit = handleSubmit((data) => {
    authFetch("/user/login", { method: "POST", body: data, auth: false })
      .then((user) => {
        window.localStorage.setItem("token", user.accessToken);
        window.localStorage.setItem("refresh-token", user.refreshToken);
        window.localStorage.setItem("subbar", user.subbar);
        setSubbar(user.subbar);
        setUser({ name: user.name, logoText: user.logoText, role: user.role });
        setAuth(user.accessToken);
        history.push("/");
      })
      .catch((err) => {
        if (err.response.status === 500) {
          addToast("Error al verificar con el servidor!", { appearance: "error" });
        }
        if (err.response.status === 400) {
          setError(true);
        }
      });
  });
  return (
    <div
      style={{
        backgroundColor: "#efeff6",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Panel style={{ width: 400, height: 475, position: "relative", overflow: "visible" }}>
        <div
          style={{
            width: 120,
            height: 60,
            borderRadius: " 0 0 60px 60px",
            background: "#efeff6",
            position: "absolute",
            top: 0,
            left: 140,
            border: "2px solid #e5e5ea",
            borderWidth: "0 1px 1px 1px",
          }}
        >
          <div
            style={{
              width: 118,
              height: 118,
              position: "relative",
              borderRadius: "50%",
              background: "#2196f3",
              top: -59,
              left: 0,
              border: "6px solid #efeff6",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon icon="user" style={{ color: "#fff", fontSize: 40, paddingBottom: 5 }} />
          </div>
        </div>
        <h2 style={{ paddingTop: 100, paddingBottom: 30, textAlign: "center" }}>Iniciar Sesión</h2>
        <div style={{ padding: 20 }}>
          <form onSubmit={onSubmit}>
            <Input
              label="Usuario"
              name="username"
              errors={errors}
              errMsg="Usuario Requerido"
              register={register({ required: true })}
            />
            <Input
              label="Contraseña"
              name="password"
              type="password"
              errors={errors}
              errMsg="Contraseña Requerida"
              register={register({ required: true })}
            />
            <Button type="submit" style={{ marginTop: 40, width: "100%" }}>
              Entrar
            </Button>
          </form>
          {error && (
            <div style={{ padding: 10, textAlign: "center", color: "red" }}>Usuario o Contraseña incorrectos</div>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default Login;
