import React, { useCallback, useMemo, useState } from "react";
import Pills from "../../components/Pills";
import Panel from "../../components/Panel";
import CheckedPills from "../../components/CheckedPills";
import Icon from "../../components/Icon";
import { Table } from "../../components/Table";
import { useEffect } from "react";
import authFetch from "../../hooks/authFetch";
import customDate from "../../hooks/customDate";
import customNumber from "../../hooks/customNumber";
import Button from "../../components/Button";
import { useToasts } from "react-toast-notifications";
import ModalRules from "./ModalRules";
import { confirmAlert } from "../../components/Confirm";
import Modal from "./Modal";
import ModalImplementacion from "./ModalImplementacion";
import ModalEjercicio from "./ModalEjercicio";
import ModalPago from "../Pagos/Modal";
import ModalGasto from "../Gastos/Modal";

import ModalIngreso from "../Ingresos/ModalIngresoGenerico";

const colors = {
  PAGO: "blue",
  INGRESO: "green",
  BANCARIO: "yellow",
  ALQUILER: "grey",
  SUELDO: "yellow",
  TRASPASO: "grey",
  ERROR: "red",
  OTRO: "grey",
};

const dataFormat = ({
  data,
  filters,
  history,
  activeRow,
  grupos,
  handleActiveRow,
  setModalEjercicio,
  handleTipo,
  setModalImplementacion,
  setModalIngreso,
  setModalGasto,
}) => {
  let tableData = [...data.map((a) => ({ ...a }))];

  if (filters.BANCARIO_UNIDO) {
    const bancarios = tableData.filter(({ TIPO }) => TIPO === "BANCARIO");
    const totalBancarios = bancarios.reduce((acc, { DEBITO, CREDITO }) => acc + DEBITO - CREDITO, 0);
    const lastGasto = bancarios.pop();
    if (lastGasto) {
      lastGasto.estaAgrupado = true;
      lastGasto.DEBITO = totalBancarios;
      lastGasto.DESCRIPCION = "TOTAL GASTOS BANCARIOS";
    }
    tableData = tableData.filter(({ TIPO, DEBITO }) => TIPO !== "BANCARIO" || DEBITO === totalBancarios);
  }

  return tableData
    .filter((row) => filters[row.TIPO || "NO_RECONOCIDO"] ?? true)
    .map((row, i) => {
      return {
        cells: [
          {
            className: "actions-col",
            content: (
              <Acciones
                row={row}
                grupos={grupos}
                history={history}
                setModalEjercicio={setModalEjercicio}
                setModalImplementacion={setModalImplementacion}
                setModalIngreso={setModalIngreso}
                setModalGasto={setModalGasto}
              />
            ),
          },
          { className: "text-center", content: customDate(row.FECHA)[0], order: row.FECHA },
          { content: row.DESCRIPCION, order: row.DESCRIPCION },
          {
            className: "text-right",
            content: row.DEBITO ? customNumber(row.DEBITO) : "-",
            order: row.DEBITO,
          },
          {
            className: "text-right",
            content: row.CREDITO ? customNumber(row.CREDITO) : "-",
            order: row.CREDITO,
          },
          {
            className: "text-right",
            style: { fontWeight: 600 },
            content: customNumber(row.SALDO),
            order: row.SALDO,
          },
          {
            onClick: (e) => handleActiveRow(e, row),
            content:
              row.ID_EXTRACTO === activeRow ? (
                <select defaultValue={row.TIPO} onChange={(e) => handleTipo("TIPO", e.target.value, row)}>
                  <option value="">------</option>
                  <option value="PAGO">Pago</option>
                  <option value="INGRESO">Ingreso</option>
                  <option value="BANCARIO">Bancario</option>
                  <option value="SUELDO">Sueldo</option>
                  <option value="ALQUILER">Alquiler</option>
                  <option value="TRASPASO">Traspaso</option>
                  <option value="ERROR">Error</option>
                  <option value="OTRO">Otro</option>
                </select>
              ) : row.TIPO ? (
                <Pills color={colors[row.TIPO]}>{row.TIPO.toLowerCase()}</Pills>
              ) : (
                "-"
              ),
            order: row.TIPO,
          },
          {
            title: row.LEYENDAS,
            content: row.LEYENDAS,
            style: { maxWidth: "20vw", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },

            order: row.LEYENDAS,
          },
          {
            content: (
              <input
                type="checkbox"
                onChange={(e) => handleTipo("CHEQUEADO", e.target.checked ? 1 : 0, row)}
                checked={row.CHEQUEADO === 1}
              />
            ),
            order: row.IMPLEMENTADO,
          },
        ],
      };
    });
};

const Banco = ({ ejercicios, edificios, match, history }) => {
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);
  const edificio = edificios.find((ed) => ed.ID_EDIF === edif);
  const ejerState = ejercicios.find((ej) => ej.ID_EJER === ejer)?.ESTADO || 0;

  const [data, setData] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [movimientos, setMovimientos] = useState([]);
  const [info, setInfo] = useState(undefined);
  const [filter, setFilter] = useState(false);
  const [activeRow, setActiveRow] = useState(-1);
  const [modal, setModal] = useState({ show: false, data: {} });
  const [modalEjercicio, setModalEjercicio] = useState({ show: false, data: {} });
  const [modalRules, setModalRules] = useState({ show: false, data: {}, reset: 0 });
  const [modalImplementacion, setModalImplementacion] = useState({ show: false, data: {} });
  const { addToast } = useToasts();

  const [modalIngreso, setModalIngreso] = useState({ show: false, action: false, data: {} });
  const [modalGasto, setModalGasto] = useState({ show: false, action: false, data: {} });
  const [modalPago, setModalPago] = useState({ show: false, action: false, data: {} });

  const [filters, setFilters] = useState(() =>
    JSON.parse(
      window.localStorage.getItem("banco-filters") ||
        JSON.stringify({
          NO_RECONOCIDO: true,
          INGRESO: true,
          SUELDO: true,
          PAGO: true,
          ALQUILER: true,
          OTRO: true,
          ERROR: true,
          TRASPASO: true,
          BANCARIO: true,
          BANCARIO_UNIDO: true,
        })
    )
  );

  const loadInfo = useCallback(async () => {
    try {
      const data = await authFetch(`/banco/${edif}/${ejer}`);
      // handlingImplementation(data.extracto, data.implementaciones);
      setGrupos(data.grupos);
      setMovimientos(data.movimientos);
      setInfo(data.info);
      setData(data.extracto);
    } catch (err) {
      console.log(err);
      addToast("Error cargando Extracto", { appearance: "error", autoDismiss: true });
    }
  }, [addToast, edif, ejer]);

  const changeFilter = (key) => {
    setFilters((prev) => {
      const newFilter = { ...prev, [key]: !prev[key] };
      window.localStorage.setItem("banco-filters", JSON.stringify(newFilter));
      return newFilter;
    });
  };

  useEffect(() => {
    loadInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer, edif]);

  const handleActiveRow = useCallback((e, row) => {
    setActiveRow((prev) => (prev === row.ID_EXTRACTO ? prev : prev !== -1 ? -1 : row.ID_EXTRACTO));
  }, []);

  const handleImplementacion = (rows) => {
    authFetch("/banco/implementacion", {
      method: "PUT",
      body: { extracto: modalImplementacion.data, rows },
    })
      .then((res) => loadInfo())
      .catch((err) => {
        console.log(err.response.data);
        addToast("Error al implementar", { appearance: "error", autoDismiss: true });
      });

    setModalImplementacion({ show: false, data: {} });
  };

  const handleTipo = useCallback(
    (key, value, row) => {
      authFetch("/banco/tipo", {
        method: "PUT",
        body: { ...row, [key]: value },
      })
        .then((res) => loadInfo())
        .catch((err) => {
          console.log(err.response.data);
          addToast("Error al cambiar el tipo", { appearance: "error", autoDismiss: true });
        });
      setActiveRow((prev) => prev === -1);
    },
    [addToast, loadInfo]
  );

  const tableData = useMemo(
    () =>
      dataFormat({
        data,
        filters,
        history,
        activeRow,
        grupos,
        handleActiveRow,
        handleTipo,
        setModalImplementacion,
        setModalEjercicio,
        setModalIngreso,
        setModalGasto,
      }),

    [data, filters, history, grupos, activeRow, handleActiveRow, setModalEjercicio, handleTipo, setModalImplementacion]
  );

  const handleSubmitRule = (data) => {
    const body = {
      TIPO: data.TIPO,
      ID_PROV: data.TIPO === "PAGO" ? data.ID_PROV : null,
      ID_UNID: data.TIPO === "INGRESO" ? data.ID_UNID : null,
      DESCRIPCION: data.DESCRIPCION.length > 0 ? data.DESCRIPCION : null,
      LEYENDAS: data.LEYENDAS.length > 0 ? data.LEYENDAS : null,
      ID_REGLA: modal.data.ID_REGLA,
      ID_EDIF: data.TIPO === "PAGO" && data.GLOBAL ? null : edif,
    };
    authFetch("/banco/rules", {
      method: modal.action === "EDIT" ? "PUT" : "POST",
      body,
    })
      .catch((err) => {
        console.log(err.response.data);
        addToast(`Error al ${modal.action === "EDIT" ? "editar" : "agregar"} la regla`, {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => setModalRules((prev) => ({ ...prev, reset: prev.reset + 1 })));
    setModal({ show: false, data: {} });
  };

  const deleteRule = (rule) => {
    confirmAlert({
      title: "Eliminar regla",
      message: "¿Estás seguro de eliminar la regla?",
      buttons: [
        {
          label: "Elimimar",
          onClick: () => {
            authFetch("/banco/rules", { method: "DELETE", body: rule }).finally(() =>
              setModalRules((prev) => ({ ...prev, reset: prev.reset + 1 }))
            );
            setModal({ show: false, data: {} });
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
          color: "transparent",
        },
      ],
    });
  };

  const handleSubmitEjercicio = (data) => {
    authFetch("/banco/ejercicio", {
      method: "PUT",
      body: {
        ID_EXTRACTO: modalEjercicio.data.ID_EXTRACTO,
        ...data,
      },
    })
      .then((res) => loadInfo())
      .catch((err) => {
        console.log(err.response.data);
        addToast("Error al implementar", { appearance: "error", autoDismiss: true });
      });

    setModalEjercicio({ show: false, data: {} });
  };

  const handleSubmitIngreso = (formData) => {
    authFetch(`/ingresos`, {
      method: "POST",
      body: { ID_EDIF: edif, ID_EJER: ejer, ID_UNID: modalIngreso.data.unid, ...formData },
    })
      .then((res) =>
        authFetch("/banco/implementacion", {
          method: "PUT",
          body: {
            extracto: { ID_EJER: ejer, ID_EXTRACTO: modalIngreso.data.ID_EXTRACTO, TIPO: "INGRESO" },
            rows: [{ ID_MOV: res.id, ID_UNID: formData.ID_UNID }],
          },
        })
      )
      .then((res) => loadInfo())
      .catch((err) => {
        addToast("Error agregando ingreso!", { appearance: "error" });
      });
    setModalIngreso((prev) => ({ ...prev, show: false }));
  };

  const handleSubmitGasto = (formData) => {
    authFetch(`/gastos`, {
      method: "POST",
      body: { ID_EDIF: edif, ID_EJER: ejer, ...formData },
    })
      .then((res) => authFetch(`/gastos/${ejer}`))
      .then((data) => {
        const gasto = data.find(
          (g) =>
            (formData.TITULO.includes("{") || g.TITULO === formData.TITULO) &&
            g.MONTO === parseFloat(formData.MONTO) &&
            g.FECHA === formData.FECHA &&
            g.ID_PROV === parseFloat(formData.ID_PROV)
        );
        if (!gasto) return;
        setModalPago({
          show: true,
          action: "ADD",
          data: {
            ID_MOVP: gasto.ID_MOVP,
            TITULO: gasto.TITULO,
            MONTO: gasto.PAGO_REST,
            FECHA: gasto.FECHA,
            MONTO_FAC: gasto.PAGO_REST,
            CLASIFICACION_DEF: gasto.CLASIFICACION_DEF,
            TIPO_EXP_DEF: gasto.TIPO_EXP_DEF,
            ID_EXTRACTO: modalGasto.data.ID_EXTRACTO,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        addToast("Error agregando gasto!", { appearance: "error" });
      });
    setModalGasto((prev) => ({ ...prev, show: false }));
  };

  const handleSubmitPago = (formData) => {
    authFetch(`/pagos`, {
      method: "POST",
      body: { ID_EDIF: edif, ID_EJER: ejer, ID_MOVP: modalPago.data.ID_MOVP, ...formData },
    })
      .then((res) => {
        return authFetch("/banco/implementacion", {
          method: "PUT",
          body: {
            extracto: { ID_EJER: ejer, ID_EXTRACTO: modalPago.data.ID_EXTRACTO, TIPO: "PAGO" },
            rows: [{ ID_MOV: res.id, ID_PROV: formData.ID_PROV }],
          },
        });
      })
      .then((data) => loadInfo())
      .catch((err) => {
        addToast("Error agregando pago!", { appearance: "error" });
      });
    setModalPago((prev) => ({ ...prev, show: false }));
  };

  return (
    <div className="container">
      {info && (
        <h4
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
            color: "hsl(240, 5%, 60%)",
            marginBottom: 5,
          }}
        >
          <span>Ultima Actualización: {info.LAST_UPDATE ? new Date(info.LAST_UPDATE).toLocaleString() : "Nunca"}</span>
          {info.MOVEMENTS > 0 && (
            <>
              -<span>Agregados: {info.MOVEMENTS || "0"}</span>
            </>
          )}
        </h4>
      )}
      <Panel
        header={
          <Header
            filters={filters}
            changeFilter={changeFilter}
            setFilter={setFilter}
            filter={filter}
            setModalRules={setModalRules}
          />
        }
        maxHeight="calc(100vh - 150px)"
      >
        <Table
          columns={[
            { className: "actions-col", content: "Acciones", sortable: false, filterable: false },
            { content: "Fecha", sortable: true, filterable: true },
            { content: "Descripción", sortable: true, filterable: true },
            { content: "Débito", sortable: true, filterable: true },
            { content: "Crédito", sortable: true, filterable: true },
            { content: "Saldo", sortable: true, filterable: true },
            { content: "Tipo", className: "tipo", sortable: true, filterable: true },
            { content: "Leyenda", sortable: true, filterable: true },
            { content: "Chequeado", sortable: true, filterable: true },
          ]}
          filterStatus={filter}
          tableTotal={true}
          colTotals={[3, 4]}
          data={tableData}
        />
        <ModalRules
          modal={modalRules}
          setModal={setModalRules}
          setModalEdit={setModal}
          edif={edif}
          deleteRule={deleteRule}
        />
        <ModalImplementacion
          modal={modalImplementacion}
          ejer={ejer}
          grupos={grupos}
          movimientos={movimientos}
          setModal={setModalImplementacion}
          handleImplementacion={handleImplementacion}
          extracto={data}
          handleSubmit={() => 1}
        />
        <Modal modal={modal} setModal={setModal} edif={edif} handleSubmit={handleSubmitRule} />
        <ModalEjercicio
          modal={modalEjercicio}
          setModal={setModalEjercicio}
          ejercicios={ejercicios}
          handleSubmit={handleSubmitEjercicio}
          ejer={ejer}
          edif={edif}
        />
        <ModalIngreso
          edif={edif}
          modal={modalIngreso}
          ejer={ejer}
          setModal={setModalIngreso}
          handleSubmit={handleSubmitIngreso}
        />
        <ModalPago
          modal={modalPago}
          edif={edif}
          ejer={ejer}
          ejerState={ejerState}
          edificio={edificio}
          setModal={setModalPago}
          handleSubmit={handleSubmitPago}
        />
        <ModalGasto
          modal={modalGasto}
          edif={edif}
          ejerState={ejerState}
          setModal={setModalGasto}
          handleSubmit={handleSubmitGasto}
        />
      </Panel>
    </div>
  );
};

const Header = ({ filters, changeFilter, setFilter, filter, setModalRules }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Banco</span>
        <CheckedPills checked={filters.INGRESO} handleClick={() => changeFilter("INGRESO")}>
          Ingresos
        </CheckedPills>
        <CheckedPills checked={filters.NO_RECONOCIDO} handleClick={() => changeFilter("NO_RECONOCIDO")}>
          No Reconocidos
        </CheckedPills>
        <CheckedPills checked={filters.SUELDO} handleClick={() => changeFilter("SUELDO")}>
          Sueldos
        </CheckedPills>
        <CheckedPills checked={filters.PAGO} handleClick={() => changeFilter("PAGO")}>
          Pagos
        </CheckedPills>
        <CheckedPills checked={filters.ALQUILER} handleClick={() => changeFilter("ALQUILER")}>
          Alquileres
        </CheckedPills>
        <CheckedPills checked={filters.TRASPASO} handleClick={() => changeFilter("TRASPASO")}>
          Traspasos
        </CheckedPills>
        <CheckedPills checked={filters.ERROR} handleClick={() => changeFilter("ERROR")}>
          Errores
        </CheckedPills>
        <CheckedPills checked={filters.OTRO} handleClick={() => changeFilter("OTRO")}>
          Otros
        </CheckedPills>
        <CheckedPills checked={filters.BANCARIO} handleClick={() => changeFilter("BANCARIO")}>
          Bancarios
        </CheckedPills>
        {filters.BANCARIO && (
          <CheckedPills checked={filters.BANCARIO_UNIDO} handleClick={() => changeFilter("BANCARIO_UNIDO")}>
            Bancarios Unidos
          </CheckedPills>
        )}
      </div>
      <div>
        <Button size="xs" style={{ marginRight: 5 }} onClick={() => setModalRules((data) => ({ ...data, show: true }))}>
          Reglas
        </Button>
        <Button
          size="xs"
          color="secondary"
          style={{ marginRight: 5 }}
          onClick={() => window.open(window.location, "_blank", "titlebar=no,menubar=no")}
        >
          <Icon icon="doc" />
        </Button>
        <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
          <Icon icon="filter" />
        </Button>
      </div>
    </div>
  );
};

const Acciones = ({
  row,
  history,
  setModalEjercicio,
  grupos,
  setModalImplementacion,
  setModalIngreso,
  setModalGasto,
}) => {
  if (row.estaAgrupado) return "-";

  if (row.TIPO === "BANCARIO" || row.TIPO === "TRASPASO" || row.TIPO === "ERROR" || row.TIPO === "ALQUILER")
    return (
      <Button onClick={() => setModalEjercicio({ show: true, data: row })} icon size="xs">
        {" "}
        <Icon icon="edit" />
      </Button>
    );

  const sibilings = grupos.find((g) => g.extractos.includes(row.ID_EXTRACTO))?.movimientos ?? [];
  const sibilingsUnidades = grupos.find((g) => g.extractos.includes(row.ID_EXTRACTO))?.unidades ?? [];
  return (
    <>
      {row.IMPLEMENTACION === "SI" ? (
        <>
          <Button
            size="xs"
            icon
            color="green"
            onClick={() => {
              history.push({
                pathname: `/${row.TIPO === "INGRESO" ? "ingresos" : "pagos"}/${row.ID_EDIF}/${row.ID_EJER}`,
                state: { ...row, sibilings: row.TIPO === "INGRESO" ? sibilingsUnidades : sibilings },
              });
            }}
          >
            <Icon icon="link" />
          </Button>
        </>
      ) : (
        <>
          <Button
            size="xs"
            icon
            color={row.IMPLEMENTACION === "NO" ? "yellow" : "red"}
            onClick={() => setModalImplementacion((prev) => ({ ...prev, show: true, data: row }))}
          >
            <Icon icon="link" />
          </Button>
        </>
      )}
      <Button onClick={() => setModalEjercicio({ show: true, data: row })} icon size="xs">
        {" "}
        <Icon icon="edit" />
      </Button>
      {row.IMPLEMENTACION === "NO" && ["INGRESO", "PAGO"].includes(row.TIPO) && (
        <Button
          icon
          size="xs"
          color="dark"
          onClick={() =>
            row.TIPO === "INGRESO"
              ? setModalIngreso({
                  show: true,
                  action: "ADD",
                  data: {
                    unid: row.ID_UNID,
                    depto: `(${row.UF}) ${row.DEPTO}`,
                    ID_EXTRACTO: row.ID_EXTRACTO,
                    MONTO: row.CREDITO ?? row.DEBITO,
                    FECHA: row.FECHA,
                  },
                })
              : setModalGasto({
                  show: true,
                  action: "ADD",
                  hasToPayNow: true,
                  data: {
                    ID_EXTRACTO: row.ID_EXTRACTO,
                    MONTO: row.DEBITO ?? row.CREDITO,
                    FECHA: row.FECHA,
                  },
                })
          }
        >
          <Icon icon="check" />
        </Button>
      )}
    </>
  );
};

export default Banco;
