import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import Icon from "../../components/Icon";
import ModalAlquiler from "./ModalAlquiler";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import customNumber from "../../hooks/customNumber";

const Alquileres = ({ ejer, ejercicios, edif, style, setFinanciero }) => {
  const { addToast } = useToasts();
  const ejercicio = ejercicios.find((ej) => ej.ID_EJER === ejer) || {};
  const [modalAlquiler, setModalAlquiler] = useState({ show: false, action: false, data: {} });
  const [alquileres, setAlquileres] = useState([]);

  useEffect(() => {
    authFetch(`/cajas/alquileres/${ejer}`)
      .then((data) => setAlquileres(data))
      .catch((err) => {
        addToast("Error al cargar las reservas!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer]);

  const handleSubmitAlquiler = (formData) => {
    switch (modalAlquiler.action) {
      case "EDIT":
        authFetch(`/cajas/alquileres/${modalAlquiler.data.ID_ALQ}`, {
          method: "PUT",
          body: {
            ID_EJER: ejer,
            ID_EDIF: edif,
            ...formData,
          },
        })
          .then((data) => {
            return Promise.all([
              authFetch(`/cajas/alquileres/${ejer}`),
              authFetch(`/cajas/financiero/${edif}/${ejer}`),
            ]);
          })
          .then(([dataAlquileres, dataFinanciero]) => {
            setAlquileres(dataAlquileres);
            setFinanciero(dataFinanciero);
          })
          .catch((err) => addToast("Error editando alquileres", { appearance: "error" }));
        setModalAlquiler((prev) => ({ ...prev, show: false }));
        break;
      case "ADD":
        authFetch(`/cajas/alquileres`, {
          method: "POST",
          body: { ID_EJER: ejer, ID_EDIF: edif, ...formData },
        })
          .then((data) => {
            return Promise.all([
              authFetch(`/cajas/alquileres/${ejer}`),
              authFetch(`/cajas/financiero/${edif}/${ejer}`),
            ]);
          })
          .then(([dataAlquileres, dataFinanciero]) => {
            setAlquileres(dataAlquileres);
            setFinanciero(dataFinanciero);
          })
          .catch((err) => addToast("Error agregando alquileres", { appearance: "error" }));
        setModalAlquiler((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }

    setModalAlquiler((prev) => ({ ...prev, show: false }));
  };

  const handleDelete = (data) => {
    authFetch(`/cajas/alquileres/${data.ID_ALQ}`, {
      method: "DELETE",
      body: { ID_EJER: ejer },
    })
      .then((data) => {
        return Promise.all([
          authFetch(`/cajas/alquileres/${ejer}`),
          authFetch(`/cajas/financiero/${edif}/${ejer}`),
        ]);
      })
      .then(([dataAlquileres, dataFinanciero]) => {
        setAlquileres(dataAlquileres);
        setFinanciero(dataFinanciero);
      })
      .catch((err) => addToast("Error editando alquileres", { appearance: "error" }));
  };

  const tableData = alquileres.map((res) => ({
    cells: [
      {
        content: (
          <Actions
            ejerState={ejercicio.ESTADO}
            handleDelete={handleDelete}
            setModalAlquiler={setModalAlquiler}
            data={res}
          />
        ),
        className: "action-col",
      },
      { content: res.DETALLE },
      { content: res.CAJA, className: "text-center" },
      { content: customNumber(res.MONTO), order: res.MONTO, className: "text-right" },
    ],
  }));

  return (
    <div style={style}>
      <Panel header={<Header setModalAlquiler={setModalAlquiler} ejerState={ejercicio.ESTADO} />}>
        <Table
          data={tableData}
          columns={[
            { content: "Acciones", className: "actions-col" },
            { content: "Detalle", className: "" },
            { content: "Caja", className: "" },
            { content: "Monto", className: "" },
          ]}
          tableTotal
          colTotals={[3]}
        />
      </Panel>
      <ModalAlquiler
        ejer={ejer}
        modalAlquiler={modalAlquiler}
        setModalAlquiler={setModalAlquiler}
        handleSubmitAlquiler={handleSubmitAlquiler}
      />
    </div>
  );
};

const Actions = ({ ejerState, handleDelete, setModalAlquiler, data }) => {
  if (ejerState === 1) {
    return (
      <>
        <Button
          icon
          size="xs"
          onClick={() =>
            setModalAlquiler({
              show: true,
              action: "EDIT",
              data,
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button icon color="red" size="xs" onClick={() => handleDelete(data)}>
          <Icon icon="trash" />
        </Button>
      </>
    );
  } else {
    return null;
  }
};

const Header = ({ ejerState, setModalAlquiler }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Alquileres</span>
        {ejerState === 1 && (
          <Button
            size="xs"
            onClick={() =>
              setModalAlquiler({
                show: true,
                action: "ADD",
                data: {},
              })
            }
          >
            Agregar
          </Button>
        )}
      </div>
    </div>
  );
};

export default Alquileres;
