import React from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput } from "../../components/Input";
import { useForm } from "react-hook-form";

const Modal = ({ modal, setModal, empleados, edificio, ejer, handleSubmit: handleSubmitModal }) => {
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? "Agregar Empleado" : `Editar ${modal.data.NOMBRE}`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <Input
          label="Nombre"
          name="NOMBRE"
          errors={errors}
          errMsg="Nombre Requerido"
          value={modal.data.NOMBRE}
          register={register({ required: true })}
        />
        <Input
          label="Cargo"
          name="CARGO"
          errors={errors}
          errMsg="Cargo Requerido"
          value={modal.data.CARGO}
          register={register({ required: true })}
        />
        <Input
          label="CUIL"
          name="CUIL"
          errors={errors}
          errMsg="Cuil Requerido"
          value={modal.data.CUIL}
          register={register({ required: true })}
        />
        <Input
          label="Categoria Empleado"
          name="CAT_EMP"
          errors={errors}
          errMsg="Categoria Requerida"
          value={modal.data.CAT_EMP}
          register={register({ required: true })}
        />
        <Input
          label="Categoria Edificio"
          name="CAT_EDIF"
          errors={errors}
          errMsg="Categoria Requerida"
          value={modal.data.CAT_EDIF}
          register={register({ required: true })}
        />
        <Input label="Telefono" name="TEL" value={modal.data.TEL} register={register({ required: false })} />
        <DateInput
          label="Inicio"
          name="INICIO"
          errors={errors}
          errMsg="Inicio Requerido"
          value={modal.data.INICIO}
          register={register({ required: true })}
        />
        <DateInput label="Fin (Opcional)" name="FIN" value={modal.data.FIN} register={register({ required: false })} />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
