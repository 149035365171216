import React from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import CustomModal from "../../components/Modal";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";

const Modal = ({ modal, setModal, empleados, handleSubmit: handleSubmitModal, setModalEditEmp }) => {
  return (
    <CustomModal show={modal.show} size="lg" title={`Empleados`} setModal={setModal} disabled={true}>
      <Panel
        bordered
        header={
          <div style={{ display: "flex", alignItems: "center" }}>
            Empleados{" "}
            <Button
              size="xs"
              style={{ marginLeft: 10 }}
              onClick={() => setModalEditEmp({ show: true, action: "ADD", data: {} })}
            >
              Agregar
            </Button>
          </div>
        }
      >
        <Table
          columns={[
            { content: "Acción", filterable: false, sortable: false },
            { content: "Nombre", filterable: true, sortable: true },
            { content: "Cat. Edif.", filterable: true, sortable: true },
            { content: "Cat. Emp.", filterable: true, sortable: true },
            { content: "Cargo", filterable: true, sortable: true },
            { content: "CUIL", filterable: true, sortable: true },
          ]}
          data={empleados.map((emp) => ({
            cells: [
              { content: <Accion row={emp} setModalEditEmp={setModalEditEmp} /> },
              { content: emp.NOMBRE },
              { content: emp.CAT_EDIF },
              { content: emp.CAT_EMP },
              { content: emp.CARGO },
              { content: emp.CUIL },
            ],
          }))}
        />
      </Panel>
    </CustomModal>
  );
};

const Accion = ({ row, setModalEditEmp }) => (
  <>
    <Button size="xs" icon onClick={() => setModalEditEmp({ show: true, action: "EDIT", data: row })}>
      <Icon icon="edit" />
    </Button>
  </>
);

export default Modal;
