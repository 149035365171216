import React from "react";
import CustomModal from "../../components/Modal";
import Input, { NumberInput, SelectInput } from "../../components/Input";
import { useForm } from "react-hook-form";
import CajasSelect from "../../components/CajasSelect";

const Modal = ({ modal, setModal, empleados, edificio, ejer, handleSubmit: handleSubmitModal }) => {
  const { register, reset, errors, handleSubmit } = useForm({
    defaultValues: modal.data,
  });
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  const tipoExp = [];
  (edificio.TIPO_EXP || "").split(";").forEach((te) => {
    if (te && te.length >= 1) {
      tipoExp.push({ value: te, label: te });
    }
  });

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? "Agregar Sueldo" : `Editar ${modal.data.TITULO}`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <SelectInput
          autoFocus
          label="Empleado"
          name="ID_EMP"
          errors={errors}
          errMsg="Empleado Requerido"
          value={modal.data.ID_EMP}
          register={register({ required: true })}
          data={empleados.map((emp) => ({ label: emp.NOMBRE, value: emp.ID_EMP }))}
        />
        <Input
          label="Titulo"
          name="DESCRIPCION"
          errors={errors}
          errMsg="Titulo Requerido"
          value={modal.data.DESCRIPCION}
          register={register({ required: true })}
        />
        <CajasSelect errors={errors} reset={reset} value={modal.data.ID_CAJA} register={register} ejer={ejer} />
        <SelectInput
          label="Tipo Exp"
          name="TIPO_EXP"
          errors={errors}
          errMsg="Tipo Exp Requerido"
          value={modal.data.TIPO_EXP || tipoExp[edificio.EXP_DEF - 1]?.value}
          register={register({ required: true })}
          data={tipoExp}
        />
        <NumberInput
          label="Monto Bruto"
          name="BRUTO"
          errors={errors}
          errMsg="Bruto Requerido"
          value={modal.data.BRUTO}
          register={register({ required: modal.action === "ADD" })}
          disabled={modal.action !== "ADD"}
        />
        <NumberInput
          label="Monto Neto"
          name="MONTO"
          errors={errors}
          errMsg="Neto Requerido"
          value={modal.data.MONTO}
          register={register({ required: true })}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
