import React, { useEffect, useState } from "react";

const Masonry = ({ className, style, breakpoints = {}, defaultCols, gap = 0, children = [] }) => {
  const [cols, setCols] = useState(defaultCols || 1);

  useEffect(() => {
    const handleSize = (size) => {
      let newCols = defaultCols;
      Object.keys(breakpoints)
        .sort((a, b) => parseInt(b) - parseInt(a))
        .forEach((bp) => {
          if (parseInt(bp) > size) {
            newCols = breakpoints[bp];
          }
        });

      setCols(newCols);
    };

    window.addEventListener("resize", (e) => {
      handleSize(window.innerWidth);
    });

    handleSize(window.innerWidth);
  }, [breakpoints, defaultCols]);

  const data = [];
  let separator = 0;
  children.forEach((c) => {
    if (!data[separator]) data[separator] = [];
    data[separator].push(c);
    separator++;
    if (separator >= cols) separator = 0;
  });

  return (
    <div className="masonry" style={{ display: "flex", marginLeft: -gap }}>
      {[...new Array(cols)].map((col, i) => (
        <div key={i} style={{ width: `${100 / cols}%`, marginLeft: gap }}>
          {data[i]}
        </div>
      ))}
    </div>
  );
};

export default Masonry;
