import React from "react";
import ReactDOM from "react-dom";
// import "./assets/styles/__index.css";
import "./assets/styles/index.css";
import App from "./routes/App";
import { ToastProvider } from "react-toast-notifications";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <ToastProvider placement="bottom-right" autoDismiss={true}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </ToastProvider>,
  document.getElementById("root")
);
