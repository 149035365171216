import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Modal from "./Modal";
import ModalInfo from "./ModalInfo";
import { useToasts } from "react-toast-notifications";
import authFetch from "../../hooks/authFetch";
import Input from "../../components/Input";
import UserCard from "./UserCard";

const Unidades = ({ match, edificios, ejercicios, setEjercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);

  const ejercicio = ejercicios.find((ej) => ej.ID_EJER === ejer) || {};
  const edificio = edificios.find((ed) => ed.ID_EDIF === edif) || {};

  const tipoExp = (edificio.TIPO_EXP || "").split(";").filter((tipo) => tipo !== "");

  const [unidades, setUnidades] = useState([]);
  const [modal, setModal] = useState({ show: false, action: false, data: {} });
  const [modalInfo, setModalInfo] = useState({ show: false, action: false, data: {} });
  const [filter, setFilter] = useState(false);
  const [edit, setEdit] = useState(false);
  const [userCard, setUserCard] = useState({ show: false, data: {}, position: {} });

  useEffect(() => {
    authFetch(`/unidades/${edif}`)
      .then((data) => setUnidades(data))
      .catch((err) => {
        addToast("Error al cargar las unidades!", { appearance: "error" });
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif, ejer]);

  const handleSubmit = () => {
    authFetch(`/unidades/${modal.data.ID_UNID}`, { method: "PUT", body: modal.data })
      .then((res) => {
        setUnidades((prev) => {
          const prevUnidades = [...prev];
          const unid = prevUnidades.find((unid) => unid.ID_UNID === modal.data.ID_UNID);
          unid.PROPIETARIO = modal.data.ID_PROP;
          unid.NOMBRE_PROP = modal.data.NOMBRE_PROP;
          unid.INQUILINO = modal.data.ID_INQ;
          unid.NOMBRE_INQ = modal.data.NOMBRE_INQ;

          return prevUnidades;
        });
      })
      .catch((err) => addToast("Error cambiando Propietario e inquilino!", { appearance: "error" }));
    setModal((prev) => ({ ...prev, show: false }));
  };

  const handleEdit = (cancel = false) => {
    if (filter) setFilter(false);
    if (edit && !cancel)
      authFetch("/unidades", { method: "POST", body: edit })
        .then((data) => {
          setUnidades(edit);
        })
        .catch((err) => {
          addToast("Error al cargar los porcentajes", { appearance: "error" });
        });
    setEdit((prev) => (prev ? false : [...unidades.map((a) => ({ ...a }))]));
  };

  const handleChange = (e, row, key, value) => {
    if (!edit) return;
    setEdit((prev) => {
      const newUnidades = [...prev];
      newUnidades.find((unid) => unid.ID_UNID === row.ID_UNID)[key] = value;
      return newUnidades;
    });
  };

  const handleInfo = (row) => {
    setModalInfo({ action: "VIEW", show: true, data: row });
  };

  const dataTable = (edit || unidades).map((row, i) => {
    const inputStyle = {
      padding: "0 10px",
      margin: "-1px",
      borderRadius: 0,
      position: "absolute",
      top: 0,
      textAlign: "center",
      width: "calc(100% + 2px)",
    };
    return {
      className: row.ESTADO === 0 ? "finalizado" : row.ESTADO === -1 ? "eliminado" : "",
      cells: [
        {
          className: "actions-col",
          content: (
            <Actions row={row} setModal={setModal} ejerState={ejercicio.ESTADO} handleInfo={handleInfo} />
          ),
        },
        {
          className: "",
          content: (
            <Input
              type="checkbox"
              form="form"
              name={`PAPEL${row.ID_UNID}`}
              onChange={(e) => edit && handleChange(e, row, "EXP_PAPEL", row.EXP_PAPEL === 1 ? 0 : 1)}
              checked={row.EXP_PAPEL === 1}
            />
          ),
        },
        {
          className: "",
          content: (
            <Input
              type="checkbox"
              form="form"
              name={`MAILP${row.ID_UNID}`}
              onChange={(e) => edit && handleChange(e, row, "EXP_MAILP", row.EXP_MAILP === 1 ? 0 : 1)}
              checked={row.EXP_MAILP === 1}
              disabled={!row.PROPIETARIO || !row.MAIL_PROP}
            />
          ),
        },
        {
          className: "",
          content: (
            <Input
              type="checkbox"
              form="form"
              name={`MAILI${row.ID_UNID}`}
              onChange={(e) => edit && handleChange(e, row, "EXP_MAILI", row.EXP_MAILI === 1 ? 0 : 1)}
              disabled={!row.INQUILINO || !row.MAIL_INQ}
              checked={row.EXP_MAILI === 1}
            />
          ),
        },
        {
          className: "text-center",
          content: (
            <>
              {edit && (
                <div style={{ position: "relative" }}>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      handleChange(e, row, "UF", e.target.value);
                    }}
                    value={row.UF}
                    tabIndex={1}
                  />
                </div>
              )}
              <span style={{ height: 20, display: "block" }}>{row.UF}</span>
            </>
          ),
        },
        {
          className: "text-center",
          content: (
            <>
              {edit && (
                <div style={{ position: "relative" }}>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      handleChange(e, row, "DEPTO", e.target.value);
                    }}
                    value={row.DEPTO}
                    tabIndex={1}
                  />
                </div>
              )}
              <span style={{ height: 20, display: "block" }}>{row.DEPTO}</span>
            </>
          ),
        },
        {
          className: "",
          order: row.NOMBRE_PROP,
          content: row.NOMBRE_PROP ? (
            <div
              onClick={(e) =>
                setUserCard({
                  show: true,
                  data: { mail: row.MAIL_PROP, name: row.NOMBRE_PROP },
                  position: { x: e.pageX, y: e.pageY },
                })
              }
            >
              {row.NOMBRE_PROP}
            </div>
          ) : (
            "-"
          ),
        },
        {
          order: row.NOMBRE_INQ || 999,
          className: "",
          content: row.NOMBRE_INQ ? (
            <div
              onClick={(e) =>
                setUserCard({
                  show: true,
                  data: { mail: row.MAIL_INQ, name: row.NOMBRE_INQ },
                  position: { x: e.pageX, y: e.pageY },
                })
              }
            >
              {row.NOMBRE_INQ}
            </div>
          ) : (
            "-"
          ),
        },
        ...tipoExp.map((tipo, i) => ({
          className: "text-center",
          order: parseFloat((row.PORC || "").split(";")[i]),
          content: (
            <>
              {edit && (
                <div style={{ position: "relative" }}>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      const porc = (row.PORC || "").split(";");
                      porc[i] = e.target.value;
                      handleChange(
                        e,
                        row,
                        "PORC",
                        porc.reduce((tot, porc) => (tot = tot + ";" + porc))
                      );
                    }}
                    value={parseFloat((row.PORC || "").split(";")[i]) || 0}
                    tabIndex={2}
                  />
                </div>
              )}
              <span style={{ height: 20, display: "block" }}>{(row.PORC || "").split(";")[i]}</span>
            </>
          ),
        })),
      ],
    };
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight="calc(100vh - 55px - 48px - 80px)"
          header={
            <Header
              setModal={setModal}
              setFilter={setFilter}
              edit={edit}
              handleEdit={handleEdit}
              filter={filter}
              edif={edif}
            />
          }
        >
          <Table
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Papel", sortable: !edit, filterable: true },
              { content: "Mail P", sortable: !edit, filterable: true },
              { content: "Mail I", sortable: !edit, filterable: true },
              { content: "UF", sortable: !edit, filterable: true },
              { content: "Depto", sortable: !edit, filterable: true },
              { content: "Propietario", sortable: !edit, filterable: true },
              { content: "Inquilino", sortable: !edit, filterable: true },
              ...tipoExp.map((tipo) => ({
                content: tipo,
                sortable: !edit,
                filterable: true,
              })),
            ]}
            data={dataTable}
            filterStatus={filter}
            colTotals={tipoExp.map((tipo, i) => i + 8)}
            tableTotal
            style={{ minWidth: 630 }}
          />
        </Panel>
      </div>
      <UserCard
        setUserCard={setUserCard}
        show={userCard.show}
        position={userCard.position}
        data={userCard.data}
      />
      <Modal modal={modal} setModal={setModal} handleSubmit={handleSubmit} />
      <ModalInfo modal={modalInfo} setModal={setModalInfo} />
    </>
  );
};

const Header = ({ setFilter, filter, handleEdit, edit }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Unidades</span>
        <Button size="xs" onClick={() => handleEdit(false)}>
          {edit ? "Guardar Cambios" : "Editar"}
        </Button>
        {edit && (
          <Button icon size="xs" color="secondary" style={{ marginLeft: 5 }} onClick={() => handleEdit(true)}>
            <Icon icon="close" style={{ fontSize: 10 }} />
          </Button>
        )}
      </div>

      {edit === false || (
        <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
          <Icon icon="filter" />
        </Button>
      )}
    </div>
  );
};

const Actions = ({ row, ejerState, setModal, handleInfo }) => {
  if (ejerState === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ID_UNID: row.ID_UNID,
                DEPTO: row.DEPTO,
                UF: row.UF,
                ID_PROP: row.PROPIETARIO,
                NOMBRE_PROP: row.NOMBRE_PROP,
                ID_INQ: row.INQUILINO > 0 ? row.INQUILINO : null,
                NOMBRE_INQ: row.NOMBRE_INQ,
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button icon onClick={() => handleInfo(row)} size="xs" color="green">
          <Icon icon="info" />
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button icon onClick={() => handleInfo(row)} size="xs" color="green">
          <Icon icon="info" />
        </Button>
      </>
    );
  }
};

export default Unidades;
