import React from "react";
import "../assets/styles/Panel.css";

function Panel({ header, style, minHeight = "auto", maxHeight = "auto", bordered = false, flat = false, children }) {
  return (
    <div
      className={`panel${!header ? " headless" : ""}${flat ? " panel-flat-shadow" : ""}${
        bordered ? " panel-bordered" : ""
      }`}
      style={style}
    >
      {header && <div className="panel-header">{header}</div>}
      <div className="panel-body" style={{ maxHeight, minHeight }}>
        {children}
      </div>
    </div>
  );
}

export default Panel;
