import React, { useState, useEffect } from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput, NumberInput, SelectInput } from "../../components/Input";
import CajasSelect from "../../components/CajasSelect";
import { useForm } from "react-hook-form";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Modal = ({ modal, edif, ejer, edificio = {}, ejerState, setModal, handleSubmit: handleSubmitModal }) => {
  const { addToast } = useToasts();
  const { register, errors, handleSubmit, reset } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));
  const [unidades, setUnidades] = useState([]);
  const [direct, setDirect] = useState(false);

  useEffect(() => {
    authFetch(`/unidades/${edif}`)
      .then((data) => {
        setUnidades(data);
        reset({ ID_UNID: modal.data.ID_UNID });
      })
      .catch((err) => {
        addToast("Error al cargar unidades!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif]);

  useEffect(() => {
    setDirect(false);
  }, [modal.data.ID_MOVP, modal.show]);

  const handleTipoExp = (e) => {
    if (e.target.value === "DIRECTO" && !direct) setDirect(true);
    else if (e.target.value !== "DIRECTO" && direct) setDirect(false);
  };

  const tipoExp = [];

  (edificio.TIPO_EXP || "").split(";").forEach((te) => {
    if (te && te.length >= 1) {
      tipoExp.push({ value: te, label: te });
    }
  });
  tipoExp.push({ value: "DIRECTO", label: "Directo" });

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? `Agregar Pago a ${modal.data.TITULO}` : `Editar Pago: ${modal.data.TITULO}`}
      setModal={setModal}
      handleSubmit={onSubmit}
      disabled={parseInt(ejerState) !== 1}
      size="sm"
    >
      <form onSubmit={onSubmit}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "0 10px", marginBottom: -24 }}>
          <SelectInput
            label="Tipo Expensa"
            name="TIPO_EXP"
            errors={errors}
            errMsg="Tipo Expensa Requerido"
            value={modal.data.TIPO_EXP || modal.data.TIPO_EXP_DEF || tipoExp[edificio.EXP_DEF - 1]?.value}
            onChange={handleTipoExp}
            register={register({ required: true })}
            data={tipoExp}
            disabled={parseInt(ejerState) !== 1}
          />
          {!direct && (
            <SelectInput
              label="Clasificación"
              name="CLASIFICACION"
              errors={errors}
              errMsg="Clasificación Requerida"
              value={modal.data.CLASIFICACION || modal.data.CLASIFICACION_DEF}
              register={register({ required: true })}
              disabled={parseInt(ejerState) !== 1}
              data={[
                { label: "APORTE", value: "APORTE" },
                { label: "SERVICIO", value: "SERVICIO" },
                { label: "ABONO", value: "ABONO" },
                { label: "MANTENIMIENTO", value: "MANTENIMIENTO" },
                { label: "COMPRA", value: "COMPRA" },
                { label: "ADMINISTRACION", value: "ADMINISTRACION" },
                { label: "SEGURO", value: "SEGURO" },
                { label: "OTRO", value: "OTRO" },
              ]}
            />
          )}
          {direct && (
            <SelectInput
              label="Unidad"
              name="ID_UNID"
              errors={errors}
              errMsg="Unidad Requerida"
              value={modal.data.ID_UNID}
              register={register({ required: true })}
              disabled={parseInt(ejerState) !== 1}
              data={unidades.map((un) => ({ label: `(${un.UF}) ${un.DEPTO}`, value: un.ID_UNID }))}
            />
          )}
          <CajasSelect
            errors={errors}
            reset={reset}
            value={modal.data.ID_CAJA}
            register={register}
            ejer={ejer}
            disabled={parseInt(ejerState) !== 1}
          />
          <NumberInput
            label="Monto"
            name="MONTO"
            errors={errors}
            autoFocus
            errMsg={`Monto requerido y menor que ${modal.data.MONTO_FAC}`}
            value={modal.data.MONTO}
            register={register({ required: true, max: modal.data.MONTO_FAC })}
            disabled={parseInt(ejerState) !== 1}
          />
          <DateInput
            label="Fecha"
            name="FECHA"
            errMsg="Fecha Requerido"
            autoComplete={{ mm: true, yyyy: true }}
            value={modal.data.FECHA}
            register={register({ required: true })}
            disabled={parseInt(ejerState) !== 1}
          />
          <Input
            label="Descripcion"
            name="DESCRIPCION"
            value={modal.data.DESCRIPCION}
            register={register}
            disabled={parseInt(ejerState) !== 1}
            tabIndex={-1}
          />
        </div>
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
