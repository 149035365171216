import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import CustomModal from "../../components/Modal";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import { confirmAlert } from "../../components/Confirm";
import authFetch from "../../hooks/authFetch";
import customDate from "../../hooks/customDate";
import customNumber from "../../hooks/customNumber";

const ModalInfo = ({ modal, setModal, setModalTrans, deleteTrans }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (modal.data.ID_CAJA)
      authFetch(`/cajas/info/${modal.data.ID_EJER}/${modal.data.ID_CAJA}`)
        .then((data) => setData(data))
        .catch((err) => console.error(err));
  }, [modal.show, modal.data.ID_EJER, modal.data.ID_CAJA]);

  return (
    <CustomModal show={modal.show} setModal={setModal} title={`Movimientos Manuales`} size="md">
      <Panel bordered>
        <Table
          columns={[
            { content: "Acciones", className: "actions-col" },
            { content: "Fecha" },
            { content: "Descripcion" },
            { content: "Monto" },
          ]}
          data={data.map((row) => ({
            cells: [
              {
                className: "actions-col",
                content: <Actions setModalTrans={setModalTrans} row={row} deleteTrans={deleteTrans} />,
              },
              { className: "text-center", content: customDate(row.FECHA)[0], order: row.FECHA },
              { content: row.DESCRIPCION },
              { className: "text-right", content: customNumber(row.MONTO), order: row.MONTO },
            ],
          }))}
        />
      </Panel>
    </CustomModal>
  );
};

const Actions = ({ row, setModalTrans, deleteTrans }) => {
  return (
    <>
      <Button
        icon
        size="xs"
        onClick={() =>
          setModalTrans({
            show: true,
            action: "EDIT",
            data: { ...row, TIPO: row.MONTO > 0 ? 1 : row.MONTO < 0 ? -1 : 0, MONTO: Math.abs(row.MONTO) },
          })
        }
      >
        <Icon icon="edit" />
      </Button>
      <Button
        icon
        size="xs"
        color="red"
        onClick={() =>
          confirmAlert({
            title: `Borrar Movimiento`,
            message: `Si borras el movimiento de $${row.MONTO} no lo podrás reactivar`,
            buttons: [
              {
                label: "Confirmar",
                onClick: () => deleteTrans(row.ID_MOV),
              },
              {
                label: "Cancelar",
                onClick: () => {},
                color: "transparent",
              },
            ],
          })
        }
      >
        <Icon icon="trash" />
      </Button>
    </>
  );
};

export default ModalInfo;
