import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput, NumberInput, SelectInput } from "../../components/Input";
import CajasSelect from "../../components/CajasSelect";
import { useForm } from "react-hook-form";
import authFetch from "../../hooks/authFetch";

const Modal = ({ modal, edif, ejer, setModal, handleSubmit: handleSubmitModal }) => {
  const { register, errors, handleSubmit, reset } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    if (edif) {
      authFetch(`/unidades/${edif}`).then((data) => setUnidades(data));
    }
  }, [edif]);

  useEffect(() => {
    reset({ ID_UNID: modal.data.ID_UNID });
  }, [unidades, modal.data.ID_UNID, reset]);

  return (
    <CustomModal show={modal.show} title={`Agregar Ingreso`} setModal={setModal} handleSubmit={onSubmit}>
      <form onSubmit={onSubmit}>
        <SelectInput
          label="Unidad"
          name="ID_UNID"
          autoFocus
          errors={errors}
          errMsg="Unidad requerida"
          disabled={modal.action === "EDIT"}
          data={unidades.map((ca) => ({ label: `(${ca.UF}) ${ca.DEPTO} - ${ca.NOMBRE_PROP}`, value: ca.ID_UNID }))}
          register={register({ required: true, minLength: 1 })}
        />
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          errMsg="Monto requerido"
          value={modal.data.MONTO}
          register={register({ required: true })}
        />
        <CajasSelect errors={errors} reset={reset} value={modal.data.ID_CAJA} register={register} ejer={ejer} />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha Requerido"
          value={modal.data.FECHA}
          autoComplete={{ mm: true, yyyy: true }}
          register={register({ required: true })}
        />
        <Input label="Descripcion" name="DESCRIPCION" value={modal.data.DESCRIPCION} register={register} />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
