import React from "react";
import { Redirect, Route } from "react-router";

const ProtectedRoute = ({ render, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={isAuth ? render : (props) => <Redirect to={{ pathname: "/login", state: { from: props.location } }} />}
    />
  );
};

export default ProtectedRoute;
