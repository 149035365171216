import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import customDate from "../../hooks/customDate";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import authFetch from "../../hooks/authFetch";
import Modal from "./Modal";
import { useToasts } from "react-toast-notifications";
import { confirmAlert } from "../../components/Confirm";
import customNumber from "../../hooks/customNumber";

const Ejercicios = ({ match, ejercicios, setEjercicios, edificios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);

  useEffect(() => {
    authFetch("/ejercicios")
      .then((data) => setEjercicios(data))
      .catch((err) => {
        addToast("Error cargando los ejercicios!", { appearance: "error" });
      });
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (formData, modsData) => {
    switch (modal.action) {
      case "EDIT":
        authFetch(`/ejercicios/${modal.data.ID_EJER}`, { method: "PUT", body: { ...formData, modsData } })
          .then((data) => authFetch("/ejercicios"))
          .then((data) => setEjercicios(data))
          .catch((err) => {
            addToast("Error al editar ejercicio!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      case "ADD":
        authFetch("/ejercicios", { method: "POST", body: { ID_EDIF: edif, ...formData, modsData } })
          .then((data) => authFetch("/ejercicios"))
          .then((data) => setEjercicios(data));
        setModal((prev) => ({ ...prev, show: false }));
        break;
      default:
        break;
    }
  };

  const handleDelete = (ejer) => {
    authFetch(`/ejercicios/state/${ejer}`, { method: "PUT", body: { state: -1 } })
      .then(() => authFetch("/ejercicios"))
      .then((data) => setEjercicios(data))
      .catch((err) => addToast("Error al borrar Ejercicio!", { appareance: "error" }));
  };

  const handleFinish = (ejer) => {
    authFetch(`/edificios/liquidar/${edif}/${ejer}`, { method: "POST", body: {} })
      .then(() => authFetch("/ejercicios"))
      .then((data) => setEjercicios(data))
      .catch((err) => addToast("Error al finalizar Ejercicio!", { appareance: "error" }));
    // authFetch(`/ejercicios/state/${ejer}`, { method: "PUT", body: { state: 0 } })
    //   .then((res) => handleState(ejer, 0))
  };

  const handleReactive = (ejer) => {
    authFetch(`/ejercicios/reactivar/${ejer}`)
      .then((data) => authFetch("/ejercicios"))
      .then((data) => {
        setEjercicios(data);
      })
      .catch((err) => {
        console.log(err);
        addToast("Error al reactivar Ejercicio!", { appearance: "error" });
      });
  };

  const [modal, setModal] = useState({ show: false, action: false, data: {} });
  const [filter, setFilter] = useState(false);

  const ejerciciosFiltered = ejercicios.filter((ej) => ej.ID_EDIF === edif);

  const dataTable = ejerciciosFiltered.map((row, i) => {
    const [desde, desdeOrder] = customDate(row.DESDE);
    const [hasta, hastaOrder] = customDate(row.HASTA);
    const [venc, vencOrder] = customDate(row.VENC);
    return {
      className: row.ESTADO === 0 ? "finalizado" : row.ESTADO === -1 ? "eliminado" : "",
      cells: [
        {
          className: "actions-col",
          content: (
            <Actions
              row={row}
              lastClosed={
                row.ESTADO === 0 &&
                (ejerciciosFiltered[i - 1] ? ejerciciosFiltered[i - 1].ESTADO === 1 : true)
              }
              setModal={setModal}
              handleDelete={handleDelete}
              handleFinish={handleFinish}
              handleReactive={handleReactive}
            />
          ),
        },
        { className: "", content: row.NOMBRE },
        { className: "text-center", content: desde, order: desdeOrder },
        { className: "text-center", content: hasta, order: hastaOrder },
        { className: "text-center", content: venc, order: vencOrder },
        {
          className: "text-right",
          content: row.ESTADO === 0 ? customNumber(row.EXPENSAS) : "-",
          order: vencOrder,
        },
        { className: "text-right", content: customNumber(row.GASTOS), order: vencOrder },
        {
          className: "text-right",
          content: customNumber(row.INGRESOS),
          order: vencOrder,
        },
        {
          className: "",
          content: row.ESTADO === 1 ? "Activo" : row.ESTADO === -1 ? "Eliminado" : "Finalizado",
        },
      ],
    };
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight={840}
          header={<Header setModal={setModal} setFilter={setFilter} filter={filter} edif={edif} />}
        >
          <Table
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Nombre", sortable: true, filterable: true },
              { content: "Desde", sortable: true, filterable: true },
              { content: "Hasta", sortable: true, filterable: true },
              { content: "Vencimiento", sortable: true, filterable: true },
              { content: "Expensas", sortable: true, filterable: true },
              { content: "Gastos", sortable: true, filterable: true },
              { content: "Ingresos", sortable: true, filterable: true },
              { content: "Estado", sortable: true, filterable: true },
            ]}
            data={dataTable}
            filterStatus={filter}
            style={{ minWidth: 630 }}
          />
        </Panel>
      </div>
      <Modal
        modal={modal}
        setModal={setModal}
        handleSubmit={handleSubmit}
        edificio={edificios.find((ed) => ed.ID_EDIF === edif) || []}
      />
    </>
  );
};

const Header = ({ setModal, setFilter, filter, edif }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Ejercicios</span>
        <Button
          size="xs"
          onClick={() =>
            setModal({
              show: true,
              action: "ADD",
              data: { ID_EDIF: edif, NOMBRE: "", DESDE: "", HASTA: "", VENC: "" },
            })
          }
        >
          Agregar
        </Button>
      </div>
      <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
        <Icon icon="filter" />
      </Button>
    </div>
  );
};

const Actions = ({ row, setModal, handleDelete, handleFinish, lastClosed, handleReactive }) => {
  if (row.ESTADO === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                DESDE: customDate(row.DESDE)[1],
                HASTA: customDate(row.HASTA)[1],
                VENC: customDate(row.VENC)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button icon onClick={() => handleDelete(row.ID_EJER)} size="xs" color="red">
          <Icon icon="trash" />
        </Button>
        <Button
          icon
          onClick={() =>
            confirmAlert({
              title: `Cerrar ${row.NOMBRE}`,
              message: "Se liquidarán las expensas del ejercicio.",
              buttons: [
                {
                  label: "Confirmar",
                  onClick: () => handleFinish(row.ID_EJER),
                },
                {
                  label: "Cancelar",
                  onClick: () => {},
                  color: "transparent",
                },
              ],
            })
          }
          size="xs"
          text="black"
          color="green"
        >
          <Icon icon="check" />
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          icon
          size="xs"
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                DESDE: customDate(row.DESDE)[1],
                HASTA: customDate(row.HASTA)[1],
                VENC: customDate(row.VENC)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button
          icon
          onClick={
            lastClosed
              ? () =>
                  confirmAlert({
                    title: `Reabrir ${row.NOMBRE}`,
                    message: "Todos los datos guardados se recalcularán.",
                    buttons: [
                      {
                        label: "Confirmar",
                        onClick: () => handleReactive(row.ID_EJER),
                      },
                      {
                        label: "Cancelar",
                        onClick: () => {},
                        color: "transparent",
                      },
                    ],
                  })
              : () =>
                  confirmAlert({
                    title: `Reabrir ${row.NOMBRE}`,
                    message: "Debes reabrir los ejercicios anteriores para reabrir este.",
                    buttons: [
                      {
                        label: "Cancelar",
                        onClick: () => {},
                        color: "transparent",
                      },
                    ],
                  })
          }
          size="xs"
          text="black"
          color="yellow"
        >
          <Icon icon="check" />
        </Button>
      </>
    );
  }
};

export default Ejercicios;
