import React, { useState, useEffect } from "react";
import { SelectInput } from "./Input";
import authFetch from "../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const CajasSelect = ({ ejer, errors, register, value, reset, disabled }) => {
  const { addToast } = useToasts();
  const [cajas, setCajas] = useState([]);

  useEffect(() => {
    authFetch(`/cajas/select/${ejer}`)
      .then((data) => {
        setCajas(data.sort((a, b) => (a.CAJA > b.CAJA ? 1 : -1)));
        reset({ ID_CAJA: value || data.find((ca) => ca.DEFAULT_CAJA === 1)?.ID_CAJA });
      })
      .catch((err) => {
        addToast("Error cargando las cajas!", { appearance: "error" });
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer, reset, value]);

  return (
    <SelectInput
      label="Caja"
      name="ID_CAJA"
      errors={errors}
      errMsg="Caja requerida"
      data={cajas.map((ca) => ({ label: `${ca.CAJA} (${ca.SALDO_TOTAL})`, value: ca.ID_CAJA }))}
      register={register({ required: true, minLength: 1 })}
      disabled={disabled}
    />
  );
};

export default CajasSelect;
