import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import Select from "react-select";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Modal = ({ modal, setModal, handleSubmit }) => {
  const { addToast } = useToasts();
  const [personas, setPersonas] = useState([]);

  useEffect(() => {
    authFetch("/edificios/personas")
      .then((data) => setPersonas(data.map((per) => ({ label: per.NOMBRE, value: per.ID_PER }))))
      .catch((err) => {
        addToast("Error al cargar empleados!", { appearance: "error" });
      });
    //eslint-disable-next-line
  }, []);

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? "Agregar Ejercicio" : `Editar ${modal.data.DEPTO} (${modal.data.UF})`}
      setModal={setModal}
      handleSubmit={handleSubmit}
    >
      <form>
        <div className="form-group">
          <label>Propietario</label>
          <Select
            options={personas}
            autoFocus
            isClearable
            onChange={(data) =>
              setModal((prev) => ({ ...prev, data: { ...modal.data, ID_PROP: data?.value, NOMBRE_PROP: data?.label } }))
            }
            defaultValue={personas.find((per) => per.value === modal.data.ID_PROP)}
          />
        </div>
        <div className="form-group" style={{ marginBottom: -24 }}>
          <label>Inquilino</label>
          <Select
            options={personas}
            isClearable
            onChange={(data) =>
              setModal((prev) => ({ ...prev, data: { ...modal.data, ID_INQ: data?.value, NOMBRE_INQ: data?.label } }))
            }
            defaultValue={personas.find((per) => per.value === modal.data.ID_INQ)}
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default Modal;
