import React from "react";
import CustomModal from "../../components/Modal";
import { useForm } from "react-hook-form";
import { DateInput, NumberInput, SelectInput } from "../../components/Input";

const ModalMov = ({ modalMov, setModalMov, handleSubmitMov, cajas, ejercicio }) => {
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitMov(data));
  return (
    <CustomModal show={modalMov.show} setModal={setModalMov} title="Hacer Transpaso" handleSubmit={onSubmit}>
      <form onSubmit={onSubmit}>
        <NumberInput
          label="Monto"
          name="MONTO"
          autoFocus
          errors={errors}
          errMsg="Monto requerido"
          value={modalMov.data.MONTO}
          register={register({ required: true })}
        />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha fuera de rango"
          value={modalMov.data.FECHA}
          autoComplete={{ mm: true, yyyy: true }}
          register={register({ required: true })}
        />
        <SelectInput
          label="DE"
          name="DE"
          errors={errors}
          errMsg="Caja requerida"
          value={cajas.find((ca) => ca.default)?.ID_CAJA}
          register={register({ required: true, minLength: 1 })}
          data={cajas.map((ca) => ({ label: ca.CAJA, value: ca.ID_CAJA }))}
        />
        <SelectInput
          label="A"
          name="A"
          errors={errors}
          errMsg="Caja requerida"
          value={cajas.find((ca) => ca.default)?.ID_CAJA}
          register={register({ required: true, minLength: 1 })}
          data={cajas.map((ca) => ({ label: ca.CAJA, value: ca.ID_CAJA }))}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default ModalMov;
