import React, { useEffect, useState } from "react";
import CajasDiv from "./Cajas";
import Financiero from "./Financiero";
import Reservas from "./Reservas";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import Alquileres from "./Alquileres";

const Cajas = ({ match, ejercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);

  const [cajas, setCajas] = useState([]);
  const [financiero, setFinanciero] = useState([]);

  useEffect(() => {
    authFetch(`/cajas/${ejer}`)
      .then((data) => setCajas(data.filter((ca) => ca.ESTADO === 1)))
      .catch((err) => {
        addToast("Error al cargar las cajas!", { appearance: "error" });
      });
    authFetch(`/cajas/financiero/${edif}/${ejer}`)
      .then((data) => setFinanciero(data))
      .catch((error) => {
        addToast("Error cargando Financiero!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif, ejer]);

  return (
    <div className="container">
      <CajasDiv
        edif={edif}
        ejer={ejer}
        ejercicios={ejercicios}
        cajas={cajas}
        setCajas={setCajas}
        setFinanciero={setFinanciero}
      />
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Financiero
          edif={edif}
          ejer={ejer}
          financiero={financiero}
          setFinanciero={setFinanciero}
          style={{ maxWidth: 1000, marginTop: 30, width: "100%" }}
        />
        <Alquileres
          edif={edif}
          ejer={ejer}
          ejercicios={ejercicios}
          financiero={financiero}
          setFinanciero={setFinanciero}
          style={{ maxWidth: 1000, marginTop: 30, width: "100%" }}
        />
        <Reservas
          edif={edif}
          ejer={ejer}
          cajas={cajas}
          ejercicios={ejercicios}
          setFinanciero={setFinanciero}
          style={{ maxWidth: 1000, marginTop: 30, width: "100%" }}
        />
      </div>
    </div>
  );
};

export default Cajas;
