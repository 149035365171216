import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import authFetch from "../../hooks/authFetch";
import customNumber from "../../hooks/customNumber";
import Financiero from "../Cajas/Financiero";

const Principal = ({ match }) => {
  const edif = parseInt(match.params.edif);
  const desdeId = parseInt(match.params.desde) || 0;
  const hastaId = parseInt(match.params.hasta) || 0;
  const [edificios, setEdificios] = React.useState([]);
  const [ejercicios, setEjercicios] = React.useState([]);
  const edificio = edificios.find((e) => e.ID_EDIF === edif) || {};
  const desde = ejercicios.find((ej) => ej.ID_EJER === desdeId) || {};
  const hasta = ejercicios.find((ej) => ej.ID_EJER === hastaId) || {};
  const [financiero, setFinanciero] = useState([]);
  const ejerciciosActivos = ejercicios
    .filter((ej) => ej.ID_EDIF === edificio.ID_EDIF && ej.ID_EJER >= desde.ID_EJER && ej.ID_EJER <= hasta.ID_EJER)
    .sort((a, b) => a.ID_EJER - b.ID_EJER);

  useEffect(() => {
    authFetch(`/resumenGastos/datos/`).then((data) => {
      setEdificios(data[0]);
      setEjercicios(data[1]);
    });
    authFetch(`/resumenGastos/financiero/${edif}/${hastaId}`).then((data) => setFinanciero(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif, hastaId]);

  return (
    <div className="">
      <style>
        {`
        // @media print {
        //   * {
        //     font-size: 10px !important;
        //   }
        // }
        .table td, .table th {font-size: 11px !important; padding: 1px 4px !important;}}`}
      </style>
      <h4 style={{ textAlign: "center", paddingTop: 20 }}>
        Resumen de Cuentas de {desde.NOMBRE} hasta {hasta.NOMBRE} - Consorcio Propietarios {edificio.NOMBRE}
      </h4>

      <div style={{ padding: 20 }}>
        <ResumenXRubro edificio={edificio} ejerciciosActivos={ejerciciosActivos} desde={desde} hasta={hasta} />
      </div>
      <div style={{ padding: 20 }}>
        <ResumenIngresos edificio={edificio} ejerciciosActivos={ejerciciosActivos} desde={desde} hasta={hasta} />
      </div>
      <div style={{ width: "calc(100% - 110px)", padding: 20 }}>
        <ResumenFinanciero edificio={edificio} ejerciciosActivos={ejerciciosActivos} desde={desde} hasta={hasta} />
      </div>
      <div style={{ display: "flex", justifyContent: "center", padding: 20 }}>
        <Panel bordered style={{ width: 800 }}>
          <Financiero
            edif={edif}
            ejer={hastaId}
            financiero={financiero}
            filas={["saldo_total", "reservas", "saldo_disponible"]}
          />
        </Panel>
      </div>
    </div>
  );
};

const rubros = [
  ["Sueldos", "SUELDO"],
  ["Aporte", "APORTE"],
  ["Servicio", "SERVICIO"],
  ["Abono", "ABONO"],
  ["Mantenimiento", "MANTENIMIENTO"],
  ["Compras", "COMPRA"],
  ["Administración", "ADMINISTRACION"],
  ["Seguro", "SEGURO"],
  ["Directo", "DIRECTO"],
  ["Otros", "OTRO"],
];

const tiposFinanciero = [
  ["saldo_inicial", "Saldo al Inicio"],
  ["gastos_pagados", "Gasto"],
  ["alquileres", "Alquileres"],
  ["ingresos", "Ingresos Expensas"],
  ["ingresos_extraordinarios", "Ingresos Extraordinarios"],
  ["ingresos_no_identificados", "Ingresos No Identificados"],
  ["saldo_total", "Saldo al Final"],
];

const ResumenXRubro = ({ edificio, ejerciciosActivos, desde, hasta }) => {
  const [datos, setDatos] = React.useState([]);

  useEffect(() => {
    if (edificio.ID_EDIF && ejerciciosActivos.length)
      authFetch(`/resumenGastos/xrubro/${edificio.ID_EDIF}/${desde.ID_EJER}/${hasta.ID_EJER}`).then((datos) => {
        setDatos(datos);
      });
  }, [edificio.ID_EDIF, ejerciciosActivos.length, desde.ID_EJER, hasta.ID_EJER]);

  const tableData = rubros.map((rubro) => {
    let total = 0;
    const rubroData = ejerciciosActivos.map((ej) => {
      const rubroEjercicio = datos[ej.ID_EJER]?.[rubro[1]] || 0;
      total += rubroEjercicio;
      return { content: customNumber(rubroEjercicio) || "-", className: "text-right", order: rubroEjercicio };
    });

    return {
      cells: [
        { content: rubro[0] },
        ...rubroData,
        { content: customNumber(total), order: total, className: "text-right" },
      ],
    };
  });

  return (
    Object.keys(datos).length > 0 && (
      <Panel bordered>
        <Table
          columns={[
            { content: "", style: { width: 150 } },
            ...ejerciciosActivos.map((ej) => ({
              content: `${ej.NOMBRE.substring(0, 3)}. ${ej.DESDE.split("-")[0]}`,
            })),
            { content: "Total", style: { width: 110 } },
          ]}
          data={tableData}
          colTotals={[...Array.from({ length: ejerciciosActivos.length + 1 }, (a, i) => i + 1)]}
          tableTotal
        />
      </Panel>
    )
  );
};

const ResumenIngresos = ({ edificio, ejerciciosActivos, desde, hasta }) => {
  const [datos, setDatos] = React.useState([]);

  useEffect(() => {
    if (edificio.ID_EDIF && ejerciciosActivos.length)
      authFetch(`/resumenGastos/ingresos/${edificio.ID_EDIF}/${desde.ID_EJER}/${hasta.ID_EJER}`).then((datos) => {
        setDatos(datos);
      });
  }, [edificio.ID_EDIF, ejerciciosActivos.length, desde.ID_EJER, hasta.ID_EJER]);

  let total = 0;
  const ingresos = ejerciciosActivos.map((ej) => {
    const rubroEjercicio = datos.find((a) => a.ID_EJER === ej.ID_EJER)?.MONTO || 0;
    total += rubroEjercicio;
    return { content: customNumber(rubroEjercicio) || "-", className: "text-right", order: rubroEjercicio };
  });

  const tableData = [
    {
      cells: [
        { content: "Ingresos Mensuales", style: { width: 150 } },
        ...ingresos,
        {
          content: customNumber(total),
          order: total,
          style: { fontWeight: 700 },
          className: "text-bold text-right",
        },
      ],
    },
  ];

  return (
    Object.keys(datos).length > 0 && (
      <Panel bordered>
        <Table
          columns={[
            { content: "", style: { width: 150 } },
            ...ejerciciosActivos.map((ej) => ({
              content: `${ej.NOMBRE.substring(0, 3)}. ${ej.DESDE.split("-")[0]}`,
            })),
            { content: "Total", style: { width: 110 } },
          ]}
          data={tableData}
        />
      </Panel>
    )
  );
};

const ResumenFinanciero = ({ edificio, ejerciciosActivos }) => {
  const [datos, setDatos] = React.useState([]);

  useEffect(() => {
    if (edificio.ID_EDIF && ejerciciosActivos.length) {
      const newDatos = [];
      Promise.all(
        ejerciciosActivos.map((ej) => authFetch(`/resumenGastos/financiero/${edificio.ID_EDIF}/${ej.ID_EJER}`))
      ).then((ejer) => {
        ejer.forEach((ej) => {
          const value = {
            saldo_inicial: 0,
            gastos_pagados: 0,
            ingresos: 0,
            alquileres: 0,
            ingresos_extraordinarios: 0,
            ingresos_no_identificados: 0,
            saldo_total: 0,
          };
          Object.keys(ej).forEach((e) => {
            value.saldo_inicial += ej[e].saldo_inicial || 0;
            value.gastos_pagados += ej[e].gastos_pagados || 0;
            value.ingresos += ej[e].ingresos || 0;
            value.alquileres += ej[e].alquileres || 0;
            value.ingresos_extraordinarios += ej[e].ingresos_extraordinarios || 0;
            value.ingresos_no_identificados += ej[e].ingresos_no_identificados || 0;
            value.saldo_total += ej[e].saldo_total || 0;
          });
          console.log(value);
          newDatos.push(value);
        });
        setDatos(newDatos);
      });
    }
  }, [edificio.ID_EDIF, ejerciciosActivos]);

  const financiero = tiposFinanciero.map((dato) => ({
    cells: [
      { content: dato[1], style: { width: 150 } },
      ...ejerciciosActivos.map((ej, i) => {
        const value = datos[i]?.[dato[0]] || 0;
        return {
          content: customNumber(Math.abs(value)) || "-",
          className: "text-right",
          order: Math.abs(value),
        };
      }),
    ],
  }));

  return (
    Object.keys(datos).length > 0 && (
      <Panel bordered>
        <Table
          columns={[
            { content: "" },
            ...ejerciciosActivos.map((ej) => ({
              content: `${ej.NOMBRE.substring(0, 3)}. ${ej.DESDE.split("-")[0]}`,
            })),
          ]}
          data={financiero}
        />
      </Panel>
    )
  );
};

export default Principal;
