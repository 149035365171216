import React from "react";
import CustomModal from "../../components/Modal";
import { useForm } from "react-hook-form";
import Input, { DateInput, NumberInput } from "../../components/Input";

const ModalCaja = ({ modalReserva, setModalReserva, handleSubmitReserva }) => {
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitReserva(data));

  return (
    <CustomModal
      show={modalReserva.show}
      setModal={setModalReserva}
      title={modalReserva.action === "ADD" ? "Agregar Reserva" : `Editar ${modalReserva.data.RESERVA}`}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <Input
          label="Nombre"
          name="RESERVA"
          autoFocus
          errors={errors}
          errMsg="Nombre requerido"
          value={modalReserva.data.RESERVA}
          register={register({ required: true })}
        />
        <Input label="Descripcion" name="DESCRIPCION" value={modalReserva.data.DESCRIPCION} register={register} />
        <DateInput
          label="Inicio"
          name="INICIO"
          errors={errors}
          errMsg="Inicio requerido"
          disabled={modalReserva.action === "EDIT"}
          value={modalReserva.data.INICIO}
          register={register({ required: true })}
        />
        <DateInput label="Cierre" name="CIERRE" value={modalReserva.data.CIERRE} register={register} />
        <NumberInput
          label="Objetivo"
          name="OBJ"
          errors={errors}
          errMsg="Objetivo Requerido"
          value={modalReserva.data.OBJ}
          register={register({ required: true })}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default ModalCaja;
