import React, { useState } from "react";
import CustomModal from "../../components/Modal";
import Files from "react-butterfiles";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import authFetch from "../../hooks/authFetch";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { useQueryClient } from "react-query";

const Modal = ({ modal, documents, setModal, handleSubmit: handleSubmitModal, edificio, ejercicio }) => {
  const [file, setFile] = useState(null);
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const { register, errors, reset, clearErrors, setError, handleSubmit } = useForm({
    defaultValues: modal.data,
  });

  const handleSuccess = (file) => {
    setFile(file[0]);
    clearErrors("file");
  };
  const handleErrors = (file) => {
    setError("file", { type: "fileError", message: "Archivo Invalido" });
    setFile(null);
  };

  const onSubmit = handleSubmit((data) => {
    if (!file) {
      setError("file", { type: "required", message: "Archivo Requerido" });
      return;
    }

    if (documents.map((d) => d.NOMBRE).includes(data.NOMBRE)) {
      setError("NOMBRE", { type: "duplicate", message: "Nombre Duplicado" });
      return;
    }

    setModal({ show: false, action: false, data: {} });

    authFetch(`/digitalizacion/signed/${ejercicio.ID_EJER}`, {
      method: "POST",
      body: {
        nombre: data.NOMBRE,
        filename: `${ejercicio.PUBLIC_UUID}/${data.NOMBRE}.pdf`,
        type: file.src.file.type,
      },
    })
      .then(({ uploadUrl }) =>
        axios.create().put(uploadUrl, file.src.file, {
          headers: {
            "Content-Type": file.src.file.type,
          },
        })
      )
      .then(() => {
        queryClient.invalidateQueries(["digitalizacion", ejercicio.ID_EJER]);
      })
      .catch((err) => {
        addToast("Error al subir archivo a nube!", { appearance: "error" });
        console.log(err, err?.message, err.response?.data, err.response?.message, err.response?.status);
      })
      .finally(() => {
        reset();
        setFile(null);
      });
  });

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "add" ? `Subir Archivo` : `Editar ${modal.data.NOMBRE}`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <Files
        multiple={false}
        maxSize="100mb"
        accept={["application/pdf", "image/jpg", "image/jpeg"]}
        onSuccess={handleSuccess}
        onError={handleErrors}
      >
        {({ browseFiles, getDropZoneProps }) => {
          return (
            <div style={{ marginBottom: 20 }}>
              <div
                {...getDropZoneProps({
                  style: {
                    position: "relative",
                    width: "100%",
                    minHeight: 140,
                    border: "1px lightgray dashed",
                  },
                })}
              >
                <ol>{file && <li key={file.name}>{file.name}</li>}</ol>

                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Arrastre aquí su archivo.<button onClick={browseFiles}>Abrir Archivos</button>
                </div>
              </div>
              {errors.file && <p style={{ color: "red" }}>{errors.file.message ?? "Archivo Requerido"}</p>}
            </div>
          );
        }}
      </Files>
      <form onSubmit={onSubmit}>
        <Input
          label="Nombre"
          name="NOMBRE"
          autofocus
          errMsg={errors.NOMBRE?.message || "Nombre Requerido"}
          errors={errors}
          value={modal.data.NOMBRE}
          register={register({ required: true })}
        />
      </form>
    </CustomModal>
  );
};

export default Modal;
