import React, { useState } from "react";

const Placeholder = () => {
  const [width] = useState(50 + 50 * Math.random());
  return (
    <div>
      <p style={{ width: width + "%", background: "#f6f6fb", margin: 0, height: 13 }}></p>
    </div>
  );
};

export default Placeholder;
