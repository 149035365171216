import React from "react";
import CustomModal from "../../components/Modal";
import Input, { NumberInput, SelectInput } from "../../components/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";

const Modal = ({ modal, setModal, handleSubmit: handleSubmitModal, edificio, handleDeleteVinculo }) => {
  const { register, errors, handleSubmit } = useForm({
    defaultValues: modal.data,
  });
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  const tipoExp = [];

  (edificio.TIPO_EXP || "").split(";").forEach((te) => {
    if (te && te.length >= 1) {
      tipoExp.push({ value: te, label: te });
    }
  });

  tipoExp.push({ value: "DIRECTO", label: "Directo" });

  return (
    <CustomModal show={modal.show} title={`Vincular ${modal.data.NOMBRE}`} setModal={setModal} handleSubmit={onSubmit}>
      <form onSubmit={onSubmit}>
        <Input label="Titulo Default" name="TITULO_DEF" autoFocus value={modal.data.TITULO_DEF} register={register} />
        <NumberInput label="Monto Default" name="MONTO_DEF" value={modal.data.MONTO_DEF} register={register} />
        <SelectInput
          label="Tipo Expensa Default"
          name="TIPO_EXP_DEF"
          errors={errors}
          autoFocus
          errMsg="Tipo Expensa Requerido"
          value={modal.data.TIPO_EXP_DEF}
          register={register}
          data={tipoExp}
        />
        <SelectInput
          label="Clasificación Default"
          name="CLASIFICACION_DEF"
          errors={errors}
          errMsg="Clasificación Requerida"
          value={modal.data.CLASIFICACION_DEF}
          register={register}
          data={[
            { label: "APORTE", value: "APORTE" },
            { label: "SERVICIO", value: "SERVICIO" },
            { label: "ABONO", value: "ABONO" },
            { label: "MANTENIMIENTO", value: "MANTENIMIENTO" },
            { label: "COMPRA", value: "COMPRA" },
            { label: "ADMINISTRACION", value: "ADMINISTRACION" },
            { label: "SEGURO", value: "SEGURO" },
            { label: "OTRO", value: "OTRO" },
          ]}
        />
        {modal.data.ID_EDIF && (
          <Button
            color="red"
            type="button"
            onClick={() => handleDeleteVinculo(modal.data)}
            style={{ marginTop: 10, marginBottom: -35, padding: "6px 12px" }}
          >
            Borrar
          </Button>
        )}
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
