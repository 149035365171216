import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Secondbar from "../components/Secondbar";
import Principal from "../containers/Principal/";
import Unidades from "../containers/Unidades/";
import Ejercicios from "../containers/Ejercicios/";
import Gastos from "../containers/Gastos/";
import Pagos from "../containers/Pagos/";
import Sueldos from "../containers/Sueldos/";
import Ingresos from "../containers/Ingresos/";
import Cajas from "../containers/Cajas/";
import Expensas from "../containers/Expensas/";
import Edificio from "../containers/Edificio/";
import Digitalizacion from "../containers/Digitalizacion/";
import Mail from "../containers/Mail/";
import Login from "../containers/Login/";
import Proveedores from "../containers/Proveedores/";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import authFetch from "../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import Personas from "../containers/Personas";
import ResumenGastos from "../containers/ResumenGastos";
import ProtectedRoute from "./ProtectedRoute";
import Banco from "../containers/Banco";

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${window.localStorage.getItem("token")}`;
  return config;
});

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    console.log(error.response);
    if (error.response.status === 403) {
      window.localStorage.removeItem("token");
      window.location.href = "/login";
    } else if (error.response.status === 401 && window.localStorage.getItem("refresh-token")) {
      authFetch("/auth/refresh", { method: "POST", body: window.localStorage.getItem("refresh-token") }).then(
        (response) => {
          if (response.data.token) {
            window.localStorage.setItem("token", response.data.token);
            window.localStorage.setItem("refresh-token", response.data.refreshToken);
          }
        }
      );
      // .catch((err) => {
      //   window.localStorage.removeItem("token");
      //   window.location.href = "/login";
      // });
    } else {
      return Promise.reject(error);
    }
  }
);

const App = () => {
  const { addToast } = useToasts();
  const [edificios, setEdificios] = useState([]);
  const [ejercicios, setEjercicios] = useState([]);
  const [user, setUser] = useState({ name: undefined });
  const [subbar, setSubbar] = useState(window.localStorage.getItem("subbar") || "secondbar");
  const [expanded, setExpanded] = useState(window.innerWidth > 1400);
  const [auth, setAuth] = useState(
    window.localStorage.getItem("token") &&
      Date.now() < JSON.parse(atob(window.localStorage.getItem("token").split(".")[1])).exp * 1000
      ? window.localStorage.getItem("token")
      : null
  );

  useEffect(() => {
    if (auth) {
      authFetch("/edificios")
        .then((data) => setEdificios(data))
        .catch((err) => {
          addToast("Error cargando los edificios!", { appearance: "error" });
        });
      authFetch("/ejercicios")
        .then((data) => setEjercicios(data))
        .catch((err) => {
          addToast("Error cargando los ejercicios!", { appearance: "error" });
        });
      authFetch("/user/whoami")
        .then((user) => {
          window.localStorage.setItem("token", user.accessToken);
          window.localStorage.setItem("refresh-token", user.refreshToken);
          window.localStorage.setItem("subbar", user.subbar);
          setSubbar(user.subbar);
          setUser({ name: user.name, logoText: user.logoText, role: user.role });
          setAuth(user.accessToken);
        })
        .catch((err) => {
          addToast("Error cargando el usuario!", { appearance: "error" });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const notNavbar = ["login", "resumenGastos"];

  return (
    <div
      className={
        (expanded ? (subbar === "secondbar" ? "secondbar-expanded " : "sidebar-expanded ") : "") +
        (subbar === "secondbar" ? "secondbar-container" : "sidebar-container")
      }
    >
      <BrowserRouter>
        {auth && !notNavbar.includes(window.location.pathname.split("/")[1]) && (
          <>
            <Navbar edificios={edificios} ejercicios={ejercicios} user={user}></Navbar>
            {subbar === "secondbar" ? (
              <Secondbar edificios={edificios} ejercicios={ejercicios} expanded={expanded} setExpanded={setExpanded} />
            ) : (
              <Sidebar edificios={edificios} ejercicios={ejercicios} expanded={expanded} setExpanded={setExpanded} />
            )}
          </>
        )}
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => <Login {...props} setUser={setUser} setSubbar={setSubbar} setAuth={setAuth} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/personas/:edif?/:ejer?"
            render={(props) => <Personas edificios={edificios} {...props} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/personas/:edif?/:ejer?"
            render={(props) => <Personas edificios={edificios} {...props} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/proveedores/:edif/:ejer?"
            render={(props) => <Proveedores edificios={edificios} {...props} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/principal/:edif/:ejer?"
            render={(props) => <Principal edificios={edificios} ejercicios={ejercicios} {...props} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/unidades/:edif/:ejer?"
            render={(props) => <Unidades edificios={edificios} ejercicios={ejercicios} {...props} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/ejercicios/:edif/:ejer?"
            render={(props) => (
              <Ejercicios ejercicios={ejercicios} {...props} setEjercicios={setEjercicios} edificios={edificios} />
            )}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/banco/:edif/:ejer"
            render={(props) => (
              <Banco ejercicios={ejercicios} {...props} edificios={edificios} setEjercicios={setEjercicios} />
            )}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/gastos/:edif/:ejer"
            render={(props) => (
              <Gastos ejercicios={ejercicios} {...props} edificios={edificios} setEjercicios={setEjercicios} />
            )}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/pagos/:edif/:ejer"
            render={(props) => (
              <Pagos ejercicios={ejercicios} {...props} edificios={edificios} setEjercicios={setEjercicios} />
            )}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/sueldos/:edif/:ejer"
            render={(props) => <Sueldos {...props} edificios={edificios} ejercicios={ejercicios} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/ingresos/:edif/:ejer"
            render={(props) => <Ingresos ejercicios={ejercicios} {...props} setEjercicios={setEjercicios} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/cajas/:edif/:ejer"
            render={(props) => <Cajas {...props} ejercicios={ejercicios} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/expensas/:edif/:ejer"
            render={(props) => (
              <Expensas {...props} edificios={edificios} ejercicios={ejercicios} setEjercicios={setEjercicios} />
            )}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/edificio/:edif?/:ejer?"
            render={(props) => (
              <Edificio {...props} edificios={edificios} ejercicios={ejercicios} setEdificios={setEdificios} />
            )}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/digitalizacion/:edif/:ejer"
            render={(props) => <Digitalizacion {...props} ejercicios={ejercicios} edificios={edificios} />}
          />
          <ProtectedRoute
            isAuth={auth}
            exact
            path="/mail/:edif/:ejer"
            render={(props) => <Mail {...props} ejercicios={ejercicios} />}
          />
          <Route
            exact
            path="/resumenGastos/:edif/:desde?/:hasta?"
            render={(props) => <ResumenGastos {...props} edificios={edificios} ejercicios={ejercicios} />}
          />
          {/* 404 */}
          <ProtectedRoute
            isAuth={auth}
            path="/"
            render={(props) => <div style={{ textAlign: "center", marginTop: 100 }}>Seleccione edificio</div>}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
