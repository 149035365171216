import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { stateToHTML } from "draft-js-export-html";
import Panel from "../../components/Panel";
import authFetch from "../../hooks/authFetch";
import { confirmAlert } from "../../components/Confirm";
import RichTextEditor from "../../components/RichTextEditor";
import { EditorState } from "draft-js";
import Modal from "./Modal.js";

const Mail = ({ match }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);

  const [modal, setModal] = useState({ show: false });
  const [mail, setMail] = useState({ subject: "", attachments: [] });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    authFetch(`/mails/personas/${edif}`)
      .then((data) =>
        setUnidades(data.map((unid) => ({ ...unid, send_P: true, send_I: unid.Inq ? true : false })))
      )
      .catch((err) => {
        console.log(err);
        addToast("Error al cargar las personas!", { appearance: "error" });
      });
    // eslint-disable-next-line
  }, [edif]);

  const handleMailEdit = (target) => setMail((prev) => ({ ...prev, [target.name]: target.value }));

  const sendMail = () => {
    const to = ["", ...unidades].reduce((acc, value, i) => {
      if (value.send_P) acc += value.mail_P;
      if (value.send_I) acc += value.mail_I;
      return acc;
    });

    const mailToSend = {
      edif,
      to,
      subject: mail.subject,
      message: stateToHTML(editorState.getCurrentContent()),
    };

    authFetch("/mails/send", { method: "POST", body: mailToSend })
      .then((data) => addToast("Mail enviado!", { appearance: "success" }))
      .catch((err) => {
        if (err.response.data.err.code === "EAUTH" && err.response.data.err.command === "API") {
          addToast("No estan bien el Usuario y Contraseña!", { appearance: "error" });
        } else if (err.response.data.err.code === "EAUTH" && err.response.data.err.command === "AUTH PLAIN") {
          addToast("No esta activado el modo no seguro!", { appearance: "error" });
          window.open("https://myaccount.google.com/lesssecureapps", "_blank");
        } else {
          console.log("Error enviando mail!", err.response);
          addToast("Error enviando mail!", { appearance: "error" });
        }
      });
    setMail({ subject: "", attachments: [] });
    setEditorState(EditorState.createEmpty());
  };

  const handleMailSubmit = (editorState) => {
    confirmAlert({
      title: `Enviar Mail: ${mail.subject}`,
      message: (
        <>
          <b>El mail se enviara por CCO a </b>
          <br />
          {["", ...unidades].reduce((acc, value, i) => {
            if (value.send_P && value.send_I)
              return (acc += `${value.DEPTO} (Prop y Inq)${unidades.length > i ? "; " : ""}`);
            if (value.send_P)
              return (acc += `${value.DEPTO} (Propietario)${unidades.length > i ? "; " : ""}`);
            if (value.send_I) return (acc += `${value.DEPTO} (Inquilino)${unidades.length > i ? "; " : ""}`);
            return acc;
          })}
        </>
      ),
      buttons: [
        {
          label: "Confirmar",
          onClick: () => sendMail(editorState),
        },
        {
          label: "Cancelar",
          onClick: () => {},
          color: "transparent",
        },
      ],
    });
  };

  return (
    <div className="container">
      <Panel style={{ maxWidth: 1200, margin: "auto" }}>
        <div style={{ padding: 15 }}>
          <div style={{ display: "grid", alignItems: "center", gridTemplateColumns: "60px 1fr", gap: 5 }}>
            <label style={{ fontWeight: 600 }}>Asunto</label>
            <input name="subject" value={mail.subject} onChange={(e) => handleMailEdit(e.target)} />
            <label style={{ fontWeight: 600 }}>Bcc</label>
            <div
              style={{
                border: "1px solid #e5e5ea",
                borderRadius: 6,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => setModal({ show: true })}
            >
              {/* {["", ...unidades].reduce((acc, value, i) => {
                if (value.send_P && value.send_I)
                  return (acc += `${value.DEPTO} (Prop y Inq)${unidades.length > i ? "; " : ""}`);
                if (value.send_P)
                  return (acc += `${value.DEPTO} (Propietario)${unidades.length > i ? "; " : ""}`);
                if (value.send_I)
                  return (acc += `${value.DEPTO} (Inquilino)${unidades.length > i ? "; " : ""}`);
                return acc;
              })} */}
              {["", ...unidades].reduce((acc, value, i) => {
                if (value.send_P) acc += value.mail_P;
                if (value.send_I) acc += value.mail_I;
                return acc;
              })}
            </div>
          </div>

          <RichTextEditor
            handleSubmit={handleMailSubmit}
            setEditorState={setEditorState}
            editorState={editorState}
          />
        </div>
      </Panel>
      <Modal modal={modal} setModal={setModal} unidades={unidades} setUnidades={setUnidades} />
    </div>
  );
};

export default Mail;
