import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Modal from "./Modal";
import ModalPago from "../Pagos/Modal";
import customNumber from "../../hooks/customNumber";
import customDate from "../../hooks/customDate";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import { confirmAlert } from "../../components/Confirm";
import "../../assets/styles/Gastos.css";

const Gastos = ({ match, ejercicios, edificios, setEjercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);
  const ejerState = ejercicios.find((ej) => ej.ID_EJER === ejer)?.ESTADO || 0;
  const edificio = edificios.find((ed) => ed.ID_EDIF === edif);

  const [gastos, setGastos] = useState([]);
  const [modal, setModal] = useState({ show: false, action: false, data: {} });
  const [modalPago, setModalPago] = useState({ show: false, action: false, data: {} });
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    authFetch(`/gastos/${ejer}`)
      .then((data) => setGastos(data))
      .catch((err) => {
        addToast("Error al cargar los gastos!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif, ejer]);

  const handleSubmitGasto = (formData) => {
    switch (modal.action) {
      case "ADD":
        authFetch(`/gastos`, {
          method: "POST",
          body: { ID_EDIF: edif, ID_EJER: ejer, ...formData },
        })
          .then((res) => authFetch(`/gastos/${ejer}`))
          .then((data) => {
            setGastos(data);
            if (formData.payNow) {
              const gasto = data.find(
                (g) =>
                  (formData.TITULO.includes("{") || g.TITULO === formData.TITULO) &&
                  g.MONTO === parseFloat(formData.MONTO) &&
                  g.FECHA === formData.FECHA &&
                  g.ID_PROV === parseFloat(formData.ID_PROV)
              );
              if (!gasto) return;
              setModalPago({
                show: true,
                action: "ADD",
                data: {
                  ID_MOVP: gasto.ID_MOVP,
                  TITULO: gasto.TITULO,
                  MONTO: gasto.PAGO_REST,
                  FECHA: gasto.FECHA,
                  MONTO_FAC: gasto.PAGO_REST,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            addToast("Error agregando gasto!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/gastos/${modal.data.ID_MOVP}`, {
          method: "PUT",
          body: { ...formData },
        })
          .then((res) => {
            const gastoIndex = gastos.findIndex((gasto) => gasto.ID_MOVP === modal.data.ID_MOVP);
            if (gastoIndex > -1) {
              const prevGastos = [...gastos];
              prevGastos[gastoIndex] = {
                ...prevGastos[gastoIndex],
                ...formData,
                MONTO: parseFloat(formData.MONTO),
                ID_PROV: parseInt(formData.ID_PROV),
                PAGO_REST:
                  prevGastos[gastoIndex].PAGO_REST +
                  parseFloat(formData.MONTO) -
                  prevGastos[gastoIndex].MONTO,
              };
              setGastos(prevGastos);
            }
          })
          .catch((err) => {
            addToast("Error editando gasto!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      default:
        break;
    }
  };

  const handleSubmitPago = (formData) => {
    authFetch(`/pagos`, {
      method: "POST",
      body: { ID_EDIF: edif, ID_EJER: ejer, ID_MOVP: modalPago.data.ID_MOVP, ...formData },
    })
      .then((res) => {
        const gastoIndex = gastos.findIndex((gasto) => gasto.ID_MOVP === modalPago.data.ID_MOVP);
        if (gastoIndex > -1) {
          const prevGastos = [...gastos];
          prevGastos[gastoIndex] = {
            ...prevGastos[gastoIndex],
            PAGO_REST: prevGastos[gastoIndex].PAGO_REST - parseFloat(formData.MONTO),
          };
          setGastos(prevGastos);
        }
      })
      .catch((err) => {
        addToast("Error agregando pago!", { appearance: "error" });
      });
    setModalPago((prev) => ({ ...prev, show: false }));
  };

  const handleDeleteGasto = (data) => {
    console.log("delete gasto " + data.ID_MOVP);
    authFetch(`/gastos/${data.ID_MOVP}`, { method: "DELETE" })
      .then((res) => {
        const gastoIndex = gastos.findIndex((gasto) => gasto.ID_MOVP === data.ID_MOVP);
        if (gastoIndex > -1) {
          const prevGastos = [...gastos];
          prevGastos.splice(gastoIndex, 1);
          setGastos(prevGastos);
        }
      })
      .catch((err) => {
        addToast("Error borrando gasto!", { appearance: "error" });
      });
  };

  const dataTable = [];
  let EJERCICIO;
  let otroEjer = false;
  gastos.forEach((row, i) => {
    EJERCICIO = row.EJERCICIO;
    if (row.ID_EJER !== ejer && !otroEjer) {
      dataTable.push({
        id: 0,
        className: "separator",
        position: "top",
        header: true,
        title: EJERCICIO,
      });
      otroEjer = true;
    }
    dataTable.push({
      className: row.PAGO_REST < 1 && ejerState === 1 && "gasto-pagado",
      cells: [
        {
          className: "actions-col",
          content: (
            <Actions
              row={row}
              modal={modal}
              setModal={setModal}
              handleDeleteGasto={handleDeleteGasto}
              setModalPago={setModalPago}
              ejerState={ejerState}
            />
          ),
        },
        { className: "text-truncate", content: row.TITULO, title: row.TITULO },
        { className: "text-truncate", content: row.PROVEEDOR },
        { content: row.FACTURA ? "Fac. " + row.FACTURA : "" },
        { className: "text-right", content: customNumber(row.MONTO), order: row.MONTO },
        { className: "text-center", content: customDate(row.FECHA)[0], order: customDate(row.FECHA)[1] },
        { className: "text-right", content: customNumber(row.PAGO_REST), order: row.PAGO_REST },
      ],
    });
  });

  dataTable.push({
    id: 1,
    className: "separator",
    position: "top",
    header: true,
    title: "Otros Ejercicios",
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight="calc(100vh - 55px - 48px - 80px)"
          header={<Header ejerState={ejerState} setFilter={setFilter} filter={filter} setModal={setModal} />}
        >
          <Table
            separator
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filterable: false },
              { content: "Titulo", sortable: true, filterable: true },
              { content: "Proveedor", sortable: true, filterable: true },
              { style: { minWidth: 100 }, content: "Factura", sortable: true, filterable: true },
              { style: { minWidth: 100 }, content: "Monto", sortable: true, filterable: true },
              { style: { minWidth: 100 }, content: "Fecha", sortable: true, filterable: true },
              { style: { minWidth: 125 }, content: "Pago Restante", sortable: true, filterable: true },
            ]}
            data={dataTable}
            filterStatus={filter}
            tableTotal
            colTotals={[4, 6]}
            style={{ minWidth: 630 }}
          />
        </Panel>
      </div>
      <Modal
        modal={modal}
        edif={edif}
        ejerState={ejerState}
        setModal={setModal}
        handleSubmit={handleSubmitGasto}
      />
      <ModalPago
        modal={modalPago}
        setModal={setModalPago}
        edif={edif}
        ejer={ejer}
        ejerState={ejerState}
        edificio={edificio}
        handleSubmit={handleSubmitPago}
      />
    </>
  );
};

const Header = ({ ejerState, setFilter, filter, setModal }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Gastos</span>
        {ejerState === 1 && (
          <Button
            size="xs"
            onClick={() =>
              setModal({
                show: true,
                action: "ADD",
                data: {},
              })
            }
          >
            Agregar
          </Button>
        )}
      </div>
      <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
        <Icon icon="filter" />
      </Button>
    </div>
  );
};

const Actions = ({ row, ejerState, setModal, setModalPago, handleDeleteGasto }) => {
  if (ejerState === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: row,
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button
          size="xs"
          icon
          color="green"
          onClick={() =>
            setModalPago({
              show: true,
              action: "ADD",
              data: {
                ID_MOVP: row.ID_MOVP,
                TITULO: row.TITULO,
                MONTO: row.PAGO_REST,
                FECHA: row.FECHA,
                MONTO_FAC: row.PAGO_REST,
                CLASIFICACION_DEF: row.CLASIFICACION_DEF,
                TIPO_EXP_DEF: row.TIPO_EXP_DEF,
              },
            })
          }
        >
          <Icon icon="cash" />
        </Button>
        <Button
          size="xs"
          icon
          color="red"
          onClick={() =>
            confirmAlert({
              title: `Eliminar ${row.TITULO}`,
              message: "Borrando el gasto se borrarán todos los pagos asociados.",
              buttons: [
                {
                  label: "Confirmar",
                  onClick: () => handleDeleteGasto(row),
                },
                {
                  label: "Cancelar",
                  onClick: () => {},
                  color: "transparent",
                },
              ],
            })
          }
        >
          <Icon icon="trash" />
        </Button>
      </>
    );
  } else {
    return (
      <Button
        size="xs"
        icon
        color="green"
        onClick={() =>
          setModal({
            show: true,
            action: "EDIT",
            data: row,
          })
        }
      >
        <Icon icon="info" />
      </Button>
    );
  }
};

export default Gastos;
