import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import customDate from "../../hooks/customDate";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import authFetch from "../../hooks/authFetch";
import Modal from "./Modal";
import { useToasts } from "react-toast-notifications";
import Input from "../../components/Input";

const Personas = ({ match, edificios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);

  const [personas, setPersonas] = useState([]);
  const [modal, setModal] = useState({ show: false, action: false, data: {} });
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    authFetch(`/personas`).then((data) => setPersonas(data));
  }, [edif]);

  const handleSubmitPersona = (formData) => {
    switch (modal.action) {
      case "ADD":
        authFetch("/personas", { method: "POST", body: formData })
          .then((data) => authFetch(`/personas`))
          .then((data) => setPersonas(data))
          .catch((err) => {
            console.log(err);
            addToast("Error al crear Persona!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      case "EDIT":
        authFetch(`/personas/${modal.data.ID_PER}`, { method: "PUT", body: formData })
          .then((data) => authFetch(`/personas`))
          .then((data) => setPersonas(data))
          .catch((err) => {
            console.log(err);
            addToast("Error al editar Persona!", { appearance: "error" });
          });
        setModal((prev) => ({ ...prev, show: false }));
        break;
      default:
        break;
    }
  };

  const handleDelete = (row) => {
    authFetch(`/personas/${row.ID_PER}`, { method: "DELETE" })
      .then((data) => authFetch(`/personas`))
      .then((data) => setPersonas(data))
      .catch((err) => {
        console.log(err);
        addToast("Error al borrar Persona!", { appearance: "error" });
      });
  };

  const dataTable = (search
    ? personas.filter(
        (i) =>
          (i.NOMBRE || "").toString().toLowerCase().includes(search.toLowerCase()) ||
          (i.CUIT || "").toString().toLowerCase().includes(search.toLowerCase()) ||
          (i.MAIL || "").toString().toLowerCase().includes(search.toLowerCase()) ||
          (i.TELEFONO || "").toString().toLowerCase().includes(search.toLowerCase())
      )
    : personas
  ).map((row, i) => {
    return {
      className: row.ESTADO === 0 ? "finalizado" : row.ESTADO === -1 ? "eliminado" : "",
      cells: [
        {
          className: "actions-col",
          content: <Actions row={row} setModal={setModal} handleDelete={handleDelete} />,
        },
        { className: "", content: row.NOMBRE },
        { className: "text-center", content: row.CUIT === "0" ? "" : row.CUIT },
        { className: "", content: row.MAIL },
        { className: "text-center", content: row.TELEFONO === "0" ? "" : row.TELEFONO },
        { className: "text-center", content: row.CELULAR === "0" ? "" : row.CELULAR },
      ],
    };
  });

  return (
    <>
      <div className="container">
        <Panel
          maxHeight={830}
          header={
            <Header
              setModal={setModal}
              setFilter={setFilter}
              filter={filter}
              edif={edif}
              search={search}
              setSearch={setSearch}
            />
          }
        >
          <Table
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Nombre", sortable: true, filterable: true },
              { content: "CUIT", sortable: true, filterable: true },
              { content: "Mail", sortable: true, filterable: true },
              { content: "Telefono", sortable: true, filterable: true },
              { content: "Celular", sortable: true, filterable: true },
            ]}
            data={dataTable}
            filterStatus={filter}
            style={{ minWidth: 630 }}
          />
        </Panel>
      </div>
      <Modal modal={modal} setModal={setModal} handleSubmit={handleSubmitPersona} />
    </>
  );
};

const Header = ({ setModal, setFilter, filter, edif, search, setSearch }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Personas</span>
        <Button
          size="xs"
          onClick={() =>
            setModal({ show: true, action: "ADD", data: { ID_EDIF: edif, NOMBRE: "", DESDE: "", HASTA: "", VENC: "" } })
          }
        >
          Agregar
        </Button>
        <Input
          type="search"
          value={search}
          style={{ lineHeight: 1, padding: "5px 11px", marginLeft: 15 }}
          placeholder="Busqueda"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
        <Icon icon="filter" />
      </Button>
    </div>
  );
};

const Actions = ({ row, setModal, handleDelete }) => {
  if (row.ESTADO === 1) {
    return (
      <>
        <Button
          size="xs"
          icon
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                DESDE: customDate(row.DESDE)[1],
                HASTA: customDate(row.HASTA)[1],
                VENC: customDate(row.VENC)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>

        <Button icon onClick={() => handleDelete(row)} size="xs" color="red">
          <Icon icon="trash" />
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          icon
          size="xs"
          onClick={() =>
            setModal({
              show: true,
              action: "EDIT",
              data: {
                ...row,
                DESDE: customDate(row.DESDE)[1],
                HASTA: customDate(row.HASTA)[1],
                VENC: customDate(row.VENC)[1],
              },
            })
          }
        >
          <Icon icon="edit" />
        </Button>
        <Button icon size="xs" text="black" color="yellow">
          <Icon icon="check" />
        </Button>
      </>
    );
  }
};

export default Personas;
