import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import { Table } from "../../components/Table";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import customDate from "../../hooks/customDate";
import customNumber from "../../hooks/customNumber";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Modal = ({
  ejer,
  ejerState,
  modal,
  setModal,
  setModalIngreso,
  setModalAjuste,
  setModalAmenities,
  handleDeleteIngreso,
  handleDeleteAjuste,
  handleDeleteAmenities,
}) => {
  const { addToast } = useToasts();
  const [data, setData] = useState({});
  useEffect(() => {
    if (modal.show)
      authFetch(`/ingresos/info/${ejer}/${modal.data.unid}`)
        .then((data) => setData(data))
        .catch((err) => {
          addToast("Error al cargar la info!", { appearance: "error" });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show, ejer, modal.data.unid]);
  return (
    <CustomModal
      size="lg"
      disabled={true}
      show={modal.show}
      title={modal.action === "ADD" ? `Agregar Ajuste a ${modal.data.depto}` : `Editar Ajuste de ${modal.data.depto}`}
      setModal={setModal}
    >
      {data.pagos && (
        <>
          <h4 style={{ color: "#575757", margin: "10px 0 5px 0" }}>Pagos</h4>
          <Panel>
            <Table
              columns={[
                {
                  className: "actions-col",
                  content: "Acción",
                  sortable: false,
                  filtable: false,
                },
                { content: "Unidad", sortable: false, filtable: false },
                { content: "Fecha", sortable: false, filtable: false },
                { content: "Caja", sortable: false, filtable: false },
                { content: "Detalle", sortable: false, filtable: false },
                { content: "Monto", sortable: false, filtable: false },
              ]}
              data={data.pagos.map((row) => ({
                cells: [
                  {
                    content:
                      ejerState === 1 ? (
                        <>
                          <Button
                            icon
                            color="green"
                            size="xs"
                            onClick={() =>
                              setModalIngreso({
                                show: true,
                                action: "EDIT",
                                data: { ...row, depto: modal.data.depto },
                              })
                            }
                          >
                            <Icon icon="edit" />
                          </Button>
                          <Button icon color="red" size="xs" onClick={() => handleDeleteIngreso(row)}>
                            <Icon icon="trash" />
                          </Button>
                        </>
                      ) : (
                        "-"
                      ),
                  },
                  { content: modal.data.depto },
                  { className: "text-center", content: customDate(row.FECHA)[0] },
                  { content: row.CAJA },
                  { content: row.DESCRIPCION },
                  { className: "text-right", content: customNumber(row.MONTO) },
                ],
              }))}
            />
          </Panel>
        </>
      )}
      {data.ajustes && (
        <>
          <h4 style={{ color: "#575757", margin: "10px 0 5px 0" }}>Ajustes</h4>
          <Panel>
            <Table
              columns={[
                { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
                { content: "Unidad", sortable: false, filtable: false },
                { content: "Fecha", sortable: false, filtable: false },
                { content: "Detalle", sortable: false, filtable: false },
                { content: "Monto", sortable: false, filtable: false },
              ]}
              data={data.ajustes.map((row) => ({
                cells: [
                  {
                    content:
                      ejerState === 1 ? (
                        <>
                          <Button
                            icon
                            color="green"
                            size="xs"
                            onClick={() =>
                              setModalAjuste({
                                show: true,
                                action: "EDIT",
                                data: { ...row, depto: modal.data.depto },
                              })
                            }
                          >
                            <Icon icon="edit" />
                          </Button>
                          <Button icon color="red" size="xs" onClick={() => handleDeleteAjuste(row)}>
                            <Icon icon="trash" />
                          </Button>
                        </>
                      ) : (
                        "-"
                      ),
                  },
                  { content: modal.data.depto },
                  { className: "text-center", content: customDate(row.FECHA)[0] },
                  { content: row.DETALLE },
                  { className: "text-right", content: customNumber(row.MONTO) },
                ],
              }))}
            />
          </Panel>
        </>
      )}
      {data.amenities && (
        <>
          <h4 style={{ color: "#575757", margin: "10px 0 5px 0" }}>Amenities</h4>
          <Panel>
            <Table
              columns={[
                { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
                { content: "Unidad", sortable: false, filtable: false },
                { content: "Fecha", sortable: false, filtable: false },
                { content: "Detalle", sortable: false, filtable: false },
                { content: "Monto", sortable: false, filtable: false },
              ]}
              data={data.amenities.map((row) => ({
                cells: [
                  {
                    content:
                      ejerState === 1 ? (
                        <>
                          <Button
                            icon
                            color="green"
                            size="xs"
                            onClick={() =>
                              setModalAmenities({
                                show: true,
                                action: "EDIT",
                                data: { ...row, depto: modal.data.depto },
                              })
                            }
                          >
                            <Icon icon="edit" />
                          </Button>
                          <Button icon color="red" size="xs" onClick={() => handleDeleteAmenities(row)}>
                            <Icon icon="trash" />
                          </Button>
                        </>
                      ) : (
                        "-"
                      ),
                  },
                  { content: modal.data.depto },
                  { className: "text-center", content: customDate(row.FECHA)[0] },
                  { content: row.DETALLE },
                  { className: "text-right", content: customNumber(row.MONTO) },
                ],
              }))}
            />
          </Panel>
        </>
      )}
    </CustomModal>
  );
};

export default Modal;
