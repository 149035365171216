import React, { memo, useEffect, useState } from "react";
import "../assets/styles/Table.css";
import Placeholder from "./Placeholder";
import Icon from "./Icon";
import customNumber from "../hooks/customNumber";

export const Table = React.memo(
  ({
    columns,
    data,
    separator = false,
    filterStatus = false,
    style,
    loading = false,
    loadingHeight = 10,
    tableTotal,
    colTotals = [],
  }) => {
    const [sort, setSort] = useState({
      colIndex: false,
      dir: 0,
    });

    const [filter, setFilter] = useState({});

    useEffect(() => {
      if (!filterStatus) setFilter({});
    }, [filterStatus]);

    const handleSort = (e, colIndex, el) => {
      if (!e.target.className.includes("table-th")) return;
      if (!columns[colIndex].sortable) return;
      let newSort = { colIndex };
      if (sort.colIndex === colIndex) {
        newSort.dir = sort.dir === 1 ? -1 : sort.dir === -1 ? 0 : 1;
      } else {
        newSort.dir = 1;
      }
      setSort(newSort);
    };

    const handleFilter = (value, colIndex) => {
      setFilter((prev) => ({ ...prev, [colIndex]: value }));
    };

    let renderData = [...data];

    if (filterStatus) {
      renderData = renderData.filter((row) => {
        if (row.header) return true;
        let status = true;
        Object.keys(filter).forEach((filt) => {
          if (
            !(row.cells[filt].content.type === "span" ? row.cells[filt].order : row.cells[filt].content)
              .toString()
              .toLowerCase()
              .includes(filter[filt].toLowerCase())
          )
            status = false;
        });
        return status;
      });
    }

    const allTotal = colTotals.map((tot) => 0);

    if (separator && sort.dir === 0) {
      const sectionedData = [];
      let lastSection = [];
      const sectionTotal = colTotals.map((tot) => 0);

      renderData.forEach((row) => {
        if (row.header) {
          if (colTotals) {
            row.cells = [{ content: row.title, colspan: colTotals[0] }];
            for (let i = colTotals[0]; i < columns.length; i++) {
              const colTotIndex = colTotals.findIndex((tot) => tot === i);
              if (colTotIndex > -1) {
                row.cells.push({
                  className: "text-right",
                  content: customNumber(sectionTotal[colTotIndex]),
                  order: sectionTotal[colTotIndex],
                });
                sectionTotal[colTotIndex] = 0;
              } else {
                row.cells.push({ className: "text-right", content: "", order: 0 });
              }
            }
          } else {
            row.cells = [{ content: row.title, colspan: columns.length }];
          }
          if (lastSection.length < 1) return;
          if (row.position === "top") sectionedData.push(row, ...lastSection);
          else sectionedData.push(...lastSection, row);
          lastSection = [];
        } else {
          lastSection.push(row);
          colTotals.forEach((col, i) => {
            sectionTotal[i] += row.cells[col].order;
            allTotal[i] += row.cells[col].order;
          });
        }
      });

      renderData = sectionedData;
    } else {
      if (sort.dir !== 0) {
        renderData = renderData
          .filter((row) => !row.header)
          .sort((a, b) => {
            const validation = a.cells[sort.colIndex].order
              ? a.cells[sort.colIndex].order > b.cells[sort.colIndex].order
              : a.cells[sort.colIndex].content > b.cells[sort.colIndex].content;

            if (validation) {
              return sort.dir;
            } else {
              return -sort.dir;
            }
          });
      }
      renderData.forEach((row) => {
        if (row.header) return;
        colTotals.forEach((col, i) => {
          allTotal[i] += row.cells[col].order;
        });
      });
    }

    if (tableTotal) {
      const totalRow = { className: "total", cells: [{ content: "Totales", colspan: colTotals[0] }] };

      for (let i = colTotals[0]; i < columns.length; i++) {
        const colTotIndex = colTotals.findIndex((tot) => tot === i);
        if (colTotIndex > -1) {
          totalRow.cells.push({
            className: "text-right",
            content: customNumber(allTotal[colTotIndex]),
            order: allTotal[colTotIndex],
          });
        } else {
          totalRow.cells.push({ className: "text-right", content: "", order: 0 });
        }
      }
      renderData.push(totalRow);
    }

    if (loading)
      return (
        <>
          <table className="table" style={style}>
            <thead>
              <tr>
                {columns.map((col, i) => (
                  <th
                    className={`table-th ${col.className || ""}`}
                    style={col.style || {}}
                    key={i}
                    onClick={(e) => handleSort(e, i)}
                  >
                    {col.content}
                    {col.sortable && sort.colIndex !== i && (
                      <span className="sort-icon">
                        <Icon icon="sequence" />
                      </span>
                    )}
                    {col.sortable && sort.colIndex === i && (
                      <span className="sort-icon">
                        <Icon icon={`sequence${sort.dir === 1 ? "-down" : sort.dir === -1 ? "-up" : ""}`} />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...Array(loadingHeight)].map((row, i) => {
                return (
                  <tr key={i}>
                    {columns.map((col, j) => (
                      <Cell key={j}>
                        <Placeholder />
                      </Cell>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    return (
      <table className="table" style={style}>
        <thead>
          <tr>
            {columns.map((col, i) => (
              <th
                className={`table-th${col.className ? " " + col.className : ""}`}
                style={col.style || {}}
                key={i}
                onClick={(e) => handleSort(e, i, this)}
              >
                {col.content}
                {col.sortable && sort.colIndex !== i && (
                  <span className="sort-icon">
                    <Icon icon="sequence" />
                  </span>
                )}
                {col.sortable && sort.colIndex === i && (
                  <span className="sort-icon">
                    <Icon icon={`sequence${sort.dir === 1 ? "-down" : sort.dir === -1 ? "-up" : ""}`} />
                  </span>
                )}
              </th>
            ))}
          </tr>
          <tr>
            {filterStatus &&
              columns.map((col, i) =>
                col.filterable ? (
                  <th key={i} className="th-filter">
                    <div>
                      <input
                        size="sm"
                        placeholder="filtrar"
                        onChange={(e) => handleFilter(e.target.value, i)}
                        value={filter[i] || ""}
                      />
                    </div>
                  </th>
                ) : (
                  <th key={i} className="th-filter">
                    <div></div>
                  </th>
                )
              )}
          </tr>
        </thead>
        <tbody>
          {renderData.map((tr, i) => (
            <tr className={tr.className || ""} key={i}>
              {tr.cells.map((td, j) => (
                <Cell
                  className={td.className}
                  onClick={td.onClick}
                  title={td.title}
                  style={td.style}
                  colspan={td.colspan}
                  key={j}
                >
                  {td.content}
                </Cell>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
);

export const Cell = memo(({ children, style, className = "", title, colspan = 1, onClick }) => {
  return (
    <td className={`cell ${className}`} title={title} style={style} onClick={onClick} colSpan={colspan}>
      {children}
    </td>
  );
});

// LAST MANAGER

// if (separator) {
//   renderData.forEach((row) => {
//     if (row.header) {
//       if (sort.dir !== 0) {
//         lastSection = lastSection.sort((a, b) => {
//           const validation = a.cells[sort.colIndex].order
//             ? a.cells[sort.colIndex].order > b.cells[sort.colIndex].order
//             : a.cells[sort.colIndex].content > b.cells[sort.colIndex].content;
//           if (validation) {
//             return sort.dir;
//           } else {
//             return -sort.dir;
//           }
//         });
//       }
//       if (row.total) {
//         row.cells = [{ className: "separator " + row.position, content: row.title, colspan: row.total[0] }];
//         for (let i = 0; i < columns.length - row.total[0]; i++) {
//           if (row.total.findIndex((tot) => tot === i) > -1) {
//             row.cells.push({ className: "text-right", content: customNumber(0), order: 0 });
//           } else {
//             row.cells.push({ className: "text-right", content: "", order: 0 });
//           }
//         }
//         lastSection.forEach((a) => {
//           row.total.forEach((tot) => {
//             row.cells[tot - (row.total[0] - 1)].order += a.cells[tot].order;
//             row.cells[tot - (row.total[0] - 1)].content = customNumber(row.cells[tot - (row.total[0] - 1)].order);
//           });
//         });
//       } else {
//         row.cells = [{ className: "separator" + row.position, content: row.title, colspan: columns.length }];
//       }
//       if (row.position === "top") sectionedData.push(row, ...lastSection);
//       else sectionedData.push(...lastSection, row);
//       lastSection = [];
//     } else {
//       lastSection.push(row);
//     }
//   });

//   renderData = sectionedData;
// }
