import React, { useState, useEffect, useRef } from "react";
import CustomModal from "../../components/Modal";
import Input, { SelectInput } from "../../components/Input";
import { useForm } from "react-hook-form";
import ProvSelect from "../Gastos/ProvSelect";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Modal = ({ modal, edif, ejerState, setModal, handleSubmit: handleSubmitModal }) => {
  const { addToast } = useToasts();
  const { register, setValue, getValues, errors, handleSubmit, reset } = useForm();
  const onSubmit = handleSubmit((data) =>
    handleSubmitModal({ ...data, GLOBAL: globalInput?.current?.checked ?? false })
  );
  const [proveedores, setProveedores] = useState([]);
  const [unidades, setUnidades] = useState([]);

  const [type, setType] = useState("PAGO");
  const globalInput = useRef(undefined);

  useEffect(() => {
    Promise.all([
      authFetch(`/unidades/${edif}`)
        .then((data) => {
          setUnidades(data);
          reset({ ID_UNID: modal.data.ID_UNID });
        })
        .catch((err) => {
          addToast("Error al cargar unidades!", { appearance: "error" });
        }),
      authFetch(`/proveedores/select/${edif}`)
        .then((data) => {
          setProveedores(data);
          reset({ ID_PROV: modal.data.ID_PROV });
        })
        .catch((err) => {
          addToast("Error al cargar proveedores!", { appearance: "error" });
        }),
    ]);

    setType(modal.data.TIPO || "PAGO");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif]);

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? `Agregar Regla` : `Editar Regla`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit} style={{ marginBottom: -15 }}>
        <SelectInput
          name="TIPO"
          label="Tipo"
          data={[
            { label: "Pago", value: "PAGO" },
            { label: "Ingreso", value: "INGRESO" },
          ]}
          value={type}
          register={register}
          onChange={(e) => setType(e.target.value)}
        />
        {type === "PAGO" && (
          <ProvSelect
            data={proveedores.map((prov) => ({
              ...prov,
              label: `${prov.NOMBRE}${(prov.DESCRIPCION || "").length > 0 ? ` (${prov.DESCRIPCION})` : ""}`,
              value: prov.ID_PROV,
            }))}
            name="ID_PROV"
            label="Proveedor"
            autoFocus
            setValue={setValue}
            getValues={getValues}
            value={modal.data.ID_PROV}
          />
        )}
        {type === "INGRESO" && (
          <SelectInput
            label="Unidad"
            name="ID_UNID"
            errors={errors}
            errMsg="Unidad Requerida"
            value={modal.data.ID_UNID}
            register={register}
            data={unidades.map((un) => ({ label: `(${un.UF}) ${un.DEPTO}`, value: un.ID_UNID }))}
          />
        )}
        <input style={{ display: "none" }} defaultValue={modal.data.ID_PROV} name="ID_PROV" ref={register} />
        <input style={{ display: "none" }} defaultValue={modal.data.PROVEEDOR} name="PROVEEDOR" ref={register} />
        <Input label="Descripcion" name="DESCRIPCION" value={modal.data.DESCRIPCION} register={register} />
        <Input label="Leyendas" name="LEYENDAS" value={modal.data.LEYENDAS} register={register} />
        <Input
          label="El titulo sería"
          name="TITULO"
          errors={errors}
          errMsg={`Titulo requerido`}
          value={modal.data.TITULO}
          register={register}
          disabled={true}
        />
        {type === "PAGO" && (
          <Input
            label="Todos los edificios"
            register={globalInput}
            name="GLOBAL"
            type="checkbox"
            defaultChecked={modal.action === "EDIT" && modal.data.ID_EDIF === null}
          />
        )}
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
