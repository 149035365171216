import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import customNumber from "../../hooks/customNumber";
import customDate from "../../hooks/customDate";

const ModalImplementacion = ({
  modal,
  setModal,
  ejer,
  movimientos: movimientosAPI,
  grupos,
  handleImplementacion,
}) => {
  const { addToast } = useToasts();
  const [movements, setMovements] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (modal.show)
      authFetch(`/banco/movimientos/${ejer}/${modal.data.TIPO}`)
        .then((data) => {
          setMovements(data);
          setSelected(
            data.filter((a) =>
              (grupos.find((g) => g.extractos.includes(modal.data.ID_EXTRACTO))?.movimientos ?? []).includes(
                a.ID_MOV
              )
            )
          );
        })
        .catch((err) => {
          console.log(err);
          addToast("Error al cargar reglas!", { appearance: "error" });
        });

    //eslint-disable-next-line
  }, [ejer, modal.show, modal.data.TIPO]);

  movimientosAPI =
    movimientosAPI?.filter(
      (m) => m.TIPO === (["PAGO", "SUELDO"].includes(modal.data.TIPO) ? "EGRESO" : "INGRESO")
    ) ?? [];

  let columns = [];
  let dataTable = [];

  const handleSelected = (mov) => {
    setSelected((prev) => {
      if (prev.findIndex((a) => a.ID_MOV === mov.ID_MOV) >= 0) {
        return prev.filter((a) => a.ID_MOV !== mov.ID_MOV);
      } else {
        return [...prev, mov];
      }
    });
  };

  if (modal.data.TIPO === "INGRESO") {
    columns = [
      { content: "#", style: { width: 40 } },
      { content: "Unidad", sortable: true },
      { content: "Inquilino", sortable: true },
      { content: "Propietario", sortable: true },
      { content: "Fecha", sortable: true },
      { content: "Monto", sortable: true },
    ];
    dataTable = movements
      .filter((mov) => movimientosAPI.find((a) => mov.ID_MOV === a.ID_MOV)?.IMPLEMENTACION !== "SI" ?? true)
      .map((row) => {
        return {
          cells: [
            {
              content: (
                <input
                  type="checkbox"
                  checked={selected.map((a) => a.ID_MOV).includes(row.ID_MOV)}
                  onChange={() => handleSelected(row)}
                />
              ),
            },
            { content: `${row.DEPTO} (${row.UF})`, order: row.UF },
            { content: row.PROPIETARIO, order: row.PROPIETARIO },
            { content: row.INQUILINO, order: row.INQUILINO },
            { className: "text-center", content: customDate(row.FECHA)[0], order: row.FECHA },
            { className: "text-right", content: customNumber(row.MONTO), order: row.MONTO },
          ],
        };
      });
  } else {
    columns = [
      { content: "#", style: { width: 40 } },
      { content: "Proveedor", sortable: true },
      { content: "Titulo", sortable: true },
      { content: "Fecha", sortable: true },
      { content: "Tipo Exp", sortable: true },
      { content: "Monto", sortable: true },
    ];
    dataTable = movements
      .filter((mov) => movimientosAPI.find((a) => mov.ID_MOV === a.ID_MOV)?.IMPLEMENTACION !== "SI" ?? true)
      .map((row) => {
        return {
          cells: [
            {
              content: (
                <input
                  type="checkbox"
                  checked={selected.map((a) => a.ID_MOV).includes(row.ID_MOV)}
                  onChange={() => handleSelected(row)}
                />
              ),
            },
            { content: row.PROVEEDOR, order: row.PROVEEDOR },
            { content: row.TITULO, order: row.TITULO },
            { className: "text-center", content: customDate(row.FECHA)[0], order: row.FECHA },
            { className: "text-center", content: row.TIPO_EXP, order: row.TIPO_EXP },
            { className: "text-right", content: customNumber(row.MONTO), order: row.MONTO },
          ],
        };
      });
  }

  return (
    <CustomModal
      show={modal.show}
      title={`Seleccionar Movimientos`}
      setModal={setModal}
      size="lg"
      handleSubmit={() => handleImplementacion(selected)}
    >
      <Panel flat bordered header={<Header row={modal.data} />}>
        <Table columns={columns} data={dataTable} style={{ minWidth: 630 }} />
      </Panel>
    </CustomModal>
  );
};

const Header = ({ row }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <span style={{ marginRight: 15, fontWeight: 500 }}>
          {row.TIPO === "PAGO" ? "Pagos" : row === "SUELDO" ? "Sueldos" : "Ingresos"}
        </span>
        <span style={{ fontSize: 14, marginRight: 15 }}>Fecha: {customDate(row.FECHA)[0]}</span>
        <span style={{ fontSize: 14, marginRight: 15 }}>
          Monto: {customNumber(row.DEBITO || row.CREDITO)}
        </span>
        <span style={{ fontSize: 14, marginRight: 15 }}>LEYENDAS: {row.LEYENDAS}</span>
      </div>
    </div>
  );
};

export default ModalImplementacion;
