import React from "react";

const customNumber = (number) => {
  if (number === 0) return "";
  let formattedNumber = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
    number
  );
  if (number < 0) return <span className="neg">{formattedNumber}</span>;
  else return <span>{formattedNumber}</span>;
};

export default customNumber;
