import React from "react";
import Card from "../../components/Card";
import Progress from "../../components/Progress";

const Principal = ({ match }) => {
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);

  console.log(edif, ejer);

  return (
    <div className="container">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridGap: 20,
          maxWidth: 950,
          margin: "0 auto",
        }}
      >
        <Card style={{ height: 240, padding: "20px 0" }}>
          <Progress
            radius={70}
            progress={40}
            steps={64}
            color="#FFCE54"
            title="Unidades que pagaron"
            style={{ margin: "0 auto" }}
          >
            {40}
            <span style={{ fontSize: 18, color: "#a7a7a7" }}>/{64}</span>
          </Progress>
        </Card>
        <Card style={{ height: 240, padding: "20px 0" }}>
          <Progress
            radius={70}
            progress={500000}
            steps={640220}
            color="#A0D468"
            title="Expensas cubiertas"
            style={{ margin: "0 auto" }}
          >
            {parseInt((500000 / 640220) * 100)}
            <span style={{ fontSize: 18, color: "#a7a7a7" }}>%</span>
          </Progress>
        </Card>
        <Card style={{ padding: "20px 30px", gridColumn: "3/-1" }}>
          <Progress
            radius={70}
            progress={40}
            steps={64}
            color="#5D9CEC"
            title="Recaudacion por Ejercicio "
            style={{ margin: "0 auto" }}
          >
            {parseInt((40 / 64) * 100)}
            <span style={{ fontSize: 18, color: "#a7a7a7" }}>%</span>
          </Progress>
        </Card>
      </div>
    </div>
  );
};

export default Principal;
