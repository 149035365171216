import React, { useState } from "react";
import Button from "../../components/Button";
import authFetch from "../../hooks/authFetch";
import { useQuery, useQueryClient } from "react-query";
import Modal from "./Modal";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import Icon from "../../components/Icon";
import customDate from "../../hooks/customDate";

const Digitalizacion = ({ match, ejercicios, edificios }) => {
  const ejer = parseInt(match.params.ejer);
  const ejercicio = ejercicios.find((ej) => ej.ID_EJER === ejer);

  const [modal, setModal] = useState({ show: false, action: false, data: {} });

  const { data } = useQuery(["digitalizacion", ejer], () => authFetch(`/digitalizacion/${ejer}`));

  const dataTable =
    data?.map((row, i) => {
      return {
        cells: [
          {
            className: "actions-col",
            content: <Actions row={row} />,
          },
          { className: "", content: row.NOMBRE },
          { className: "text-center", content: customDate(row.FECHA)[0] },
          {
            className: "",
            content: (
              <a target="_blank" href={`${row.url}`} style={{ color: "blue" }} rel="noreferrer">{`${row.url}`}</a>
            ),
          },
        ],
      };
    }) ?? [];

  return (
    <>
      <div className="container">
        <Modal modal={modal} setModal={setModal} ejercicio={ejercicio} documents={data} />
        <Panel
          maxHeight="calc(100vh - 55px - 48px - 80px)"
          header={<Header setModal={setModal} ejercicio={ejercicio} />}
        >
          <Table
            columns={[
              { className: "actions-col", content: "Acciones", sortable: false, filtable: false },
              { content: "Nombre", sortable: true, filterable: true },
              { content: "Fecha", sortable: true, filterable: true },
              { content: "Link", sortable: true, filterable: true },
            ]}
            data={dataTable}
          />
        </Panel>
      </div>
    </>
  );
};

function Actions({ row }) {
  const queryClient = useQueryClient();
  return (
    <Button
      size="xs"
      icon
      color="red"
      onClick={() => {
        authFetch(`/digitalizacion/${row.ID_DOC}`, {
          method: "DELETE",
        }).then(() => queryClient.invalidateQueries(["digitalizacion", row.ID_EJER]));
      }}
    >
      <Icon icon="trash" />
    </Button>
  );
}

const Header = ({ setModal, ejercicio }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <span style={{ marginRight: 10 }}>Digitalizacion</span>

        <Button size="xs" onClick={() => setModal({ show: true, action: "add", data: {} })}>
          Agregar
        </Button>
        <Button
          size="xs"
          onClick={() => window.open(process.env.REACT_APP_DOCUMENTOS_URL + "/" + ejercicio.PUBLIC_UUID, "_blank")}
        >
          Ver Sitio
        </Button>
      </div>
    </div>
  );
};

export default Digitalizacion;
