import React from "react";
import CustomModal from "../../components/Modal";
import { useForm } from "react-hook-form";
import Input, { DateInput, NumberInput, SelectInput } from "../../components/Input";
import { useRef } from "react";

const ModalTrans = ({ modalTrans, setModalTrans, handleSubmitTrans }) => {
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitTrans({ ...data, MOSTRAR: showInput.current.checked }));

  const showInput = useRef(undefined);

  return (
    <CustomModal
      show={modalTrans.show}
      setModal={setModalTrans}
      title="Ingresar/Retirar"
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit} style={{ marginBottom: -15 }}>
        <SelectInput
          label="Direccion"
          name="TIPO"
          errors={errors}
          autoFocus
          errMsg="Direccion requerida"
          value={modalTrans.data.TIPO}
          register={register({ required: true })}
          data={[
            { label: "Ingresar", value: 1 },
            { label: "Retirar", value: -1 },
          ]}
        />
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          errMsg="Monto requerido"
          value={modalTrans.data.MONTO}
          register={register({ required: true })}
        />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha fuera de rango"
          value={modalTrans.data.FECHA}
          register={register({ required: true })}
          autoComplete={{ mm: true, yyyy: true }}
        />
        <Input
          label="Descripcion"
          name="DESCRIPCION"
          value={modalTrans.data.DESCRIPCION}
          register={register}
        />
        <Input
          label="Mostrar en expensas"
          register={showInput}
          name="MOSTRAR"
          type="checkbox"
          defaultChecked={true}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default ModalTrans;
