import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import authFetch from "../../hooks/authFetch";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { confirmAlert } from "../../components/Confirm";
import Modal from "./Modal";
import CustomModal from "../../components/Modal";
import { SelectInput } from "../../components/Input";

const Expensas = ({ match, setEjercicios, edificios, ejercicios }) => {
  const { addToast } = useToasts();
  const edif = parseInt(match.params.edif);
  const ejer = parseInt(match.params.ejer);
  const [, setEnvio] = useState(false);
  const [iframeKey, setIframeKey] = useState(1);
  const [modal, setModal] = useState({ show: false, data: {}, action: "EDIT" });
  const edificio = edificios.find((ed) => ed.ID_EDIF === edif) || {};
  const ejercicio = ejercicios.find((ej) => ej.ID_EJER === ejer) || {};
  const ejerciciosFiltered = ejercicios.filter((ej) => ej.ID_EDIF === edif);

  useEffect(() => {
    authFetch(`/edificios/prorrateo/${edif}/${ejer}`).then((data) => console.log(data));
  }, [edif, ejer]);

  const handleSubmit = (value) => {
    console.log(value);
    authFetch("/edificios/opciones/" + ejer, { method: "PUT", body: value })
      .then((data) => authFetch("/ejercicios"))
      .then((data) => {
        setEjercicios(data);
        setIframeKey((prev) => prev + 1);
      })
      .catch((err) => {
        console.log(err);
        addToast("Error al actualizar opciones", { appearance: "error" });
      });
    setModal((prev) => ({ ...prev, show: false }));
  };

  return (
    <>
      <div className="container">
        <Panel
          header={
            <Header
              edif={edif}
              ejer={ejer}
              edificio={edificio}
              ejercicio={ejercicio}
              ejerciciosFiltered={ejerciciosFiltered}
              setEnvio={setEnvio}
              setModal={setModal}
              setEjercicios={setEjercicios}
            />
          }
        >
          <div style={{ backgroundColor: "#535659" }}>
            <iframe
              key={iframeKey}
              title="Expensas"
              src={`https://rafo.aldazabal.com.ar/admin/expensasEmbebed.php?edif=${edif}&ejer=${ejer}`}
              style={{ height: "calc(100vh - 55px - 48px - 80px)", width: "100%", border: "none" }}
            />
          </div>
        </Panel>
      </div>
      <Modal modal={modal} setModal={setModal} handleSubmit={handleSubmit} />
    </>
  );
};

const Header = ({
  edif,
  ejer,
  edificio,
  ejerciciosFiltered,
  ejercicio,
  setEjercicios,
  desdeEjer,
  setEnvio,
  setModal,
}) => {
  console.log(ejercicio.MAIL_ENVIADO);
  const { addToast } = useToasts();
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 10 }}>Expensas</span>
          <Button size="xs" onClick={() => setModal({ show: true, action: "EDIT", data: ejercicio })}>
            Opciones
          </Button>
          <Button
            size="xs"
            style={{ marginLeft: 7 }}
            onClick={() =>
              axios
                .get(`https://extras.aldazabal.com.ar/expensas/download/false/${edif}/${ejer}`, {
                  responseType: "blob",
                })
                .then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${edificio.NOMBRE} - ${ejercicio.NOMBRE} (sin personalizar).pdf`);
                  document.body.appendChild(link);
                  link.click();
                })
            }
          >
            Imprimir Genérico
          </Button>
          <Button
            size="xs"
            style={{ marginLeft: 7 }}
            onClick={() =>
              axios
                .get(`https://extras.aldazabal.com.ar/expensas/download/recComp/${edif}/${ejer}`, {
                  responseType: "blob",
                })
                .then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `${edificio.NOMBRE} - ${ejercicio.NOMBRE} (rec y comp).pdf`);
                  document.body.appendChild(link);
                  link.click();
                })
            }
          >
            Imprimir Rec y Comp
          </Button>

          {ejercicio?.ESTADO === 0 && (
            <Button
              size="xs"
              style={{ marginLeft: 7 }}
              color={ejercicio.MAIL_ENVIADO === 1 ? "green" : "primary"}
              onClick={() =>
                confirmAlert({
                  title: `Enviar mails`,
                  message: `Confirmar el envio de mails del ejericio ${ejercicio.NOMBRE} del edificio ${edificio.NOMBRE}`,
                  confirmText: ejercicio.NOMBRE,
                  confirmTextLabel: `Ingresar el nombre del ejercicio`,
                  buttons: [
                    {
                      label: "Confirmar",
                      onClick: () => {
                        setEnvio(true);
                        axios
                          .get(`https://extras.aldazabal.com.ar/mail/expensas/${edif}/${ejer}`)
                          .then((response) => {
                            console.log(response);
                            addToast(`${response.enviosCorrectos} Mails enviados! ${response.enviosError} errores`, {
                              appearance: "success",
                              autoDismiss: false,
                            });
                            setEnvio(false);
                          })
                          .then((res) => authFetch("/ejercicios"))
                          .then((res) => setEjercicios(res))
                          .catch((err) => {
                            console.log(err);
                            if (!err.enviosCorrectos) {
                              addToast(`Error en la respuesta del servidor, verificar envio`, {
                                appearance: "error",
                                autoDismiss: false,
                              });
                            } else {
                              addToast(`${err.enviosError} errores! ${err.enviosCorrectos} Mails enviados!`, {
                                appearance: "error",
                                autoDismiss: false,
                              });
                            }
                          });
                      },
                    },
                    {
                      label: "Cancelar",
                      onClick: () => {},
                      color: "transparent",
                    },
                  ],
                })
              }
            >
              {ejercicio.MAIL_ENVIADO === 1 ? "Enviado!" : "Enviar Mails"}
            </Button>
          )}
        </div>
        {ejerciciosFiltered.length > 0 && edificio && (
          <ModalResumen edificio={edificio} ejercicios={ejerciciosFiltered} edif={edif} />
        )}
      </div>
    </>
  );
};

function ModalResumen({ edificio, ejercicios, edif }) {
  const [desdeEjer, setDesdeEjer] = useState(
    ejercicios.length > 12 ? ejercicios[12].ID_EJER : ejercicios[ejercicios.length - 1].ID_EJER
  );
  const [hastaEjer, setHastaEjer] = useState(ejercicios[0].ID_EJER);

  const desdeEjercicio = ejercicios.find((ej) => ej.ID_EJER === desdeEjer);
  const hastaEjercicio = ejercicios.find((ej) => ej.ID_EJER === hastaEjer);

  useEffect(() => {
    setDesdeEjer(ejercicios.length > 12 ? ejercicios[12].ID_EJER : ejercicios[ejercicios.length - 1].ID_EJER);
    setHastaEjer(ejercicios[0].ID_EJER);
  }, [edificio.ID_EDIF, ejercicios]);

  const [modal, setModal] = useState({ show: false });

  function onSubmit() {
    axios
      .get(`https://extras.aldazabal.com.ar/resumenGastos/${edif}/${desdeEjer}/${hastaEjer}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${edificio.NOMBRE} - ${desdeEjercicio.NOMBRE} a ${hastaEjercicio.NOMBRE} (rec y comp).pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
    setModal({ show: false });
  }

  return (
    <>
      <Button size="xs" onClick={() => setModal({ show: true })}>
        Resumen Gastos
      </Button>
      <CustomModal show={modal.show} title="Resumen de Gastos" setModal={setModal} handleSubmit={onSubmit}>
        <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
          <SelectInput
            label="Desde"
            data={ejercicios.map((ej) => ({ label: ej.NOMBRE, value: ej.ID_EJER }))}
            value={desdeEjer}
            onChange={(e) => setDesdeEjer(ejercicios.find((ej) => ej.ID_EJER === Number(e.target.value)).ID_EJER)}
          />
          <SelectInput
            label="Hasta"
            data={ejercicios.map((ej) => ({ label: ej.NOMBRE, value: ej.ID_EJER }))}
            value={hastaEjer}
            onChange={(e) => setHastaEjer(ejercicios.find((ej) => ej.ID_EJER === Number(e.target.value)).ID_EJER)}
          />
        </div>
      </CustomModal>
    </>
  );
}

export default Expensas;
