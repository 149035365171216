import React from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput, NumberInput } from "../../components/Input";
import CajasSelect from "../../components/CajasSelect";
import { useForm } from "react-hook-form";

const Modal = ({ modal, ejer, setModal, handleSubmit: handleSubmitModal }) => {
  const { register, errors, handleSubmit, reset } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal(data));

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? `Agregar Ingreso a ${modal.data.depto}` : `Editar Ingreso de ${modal.data.depto}`}
      setModal={setModal}
      handleSubmit={onSubmit}
    >
      <form onSubmit={onSubmit}>
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          autoFocus
          errMsg="Monto requerido"
          value={modal.data.MONTO}
          register={register({ required: true })}
        />
        <CajasSelect errors={errors} reset={reset} value={modal.data.ID_CAJA} register={register} ejer={ejer} />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha Requerido"
          value={modal.data.FECHA}
          autoComplete={{ mm: true, yyyy: true }}
          register={register({ required: true })}
        />
        <Input label="Descripcion" name="DESCRIPCION" value={modal.data.DESCRIPCION} register={register} />
        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
