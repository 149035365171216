import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import Icon from "../../components/Icon";
import customNumber from "../../hooks/customNumber";
import ModalReserva from "./ModalReserva";
import ModalTrasRes from "./ModalTrasRes";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Reservas = ({ ejer, edif, ejercicios, style, setFinanciero }) => {
  const { addToast } = useToasts();
  const ejercicio = ejercicios.find((ej) => ej.ID_EJER === ejer) || {};
  const [modalReserva, setModalReserva] = useState({ show: false, action: false, data: {} });
  const [modalTrasRes, setModalTrasRes] = useState({ show: false, action: false, data: {} });
  const [reservas, setReservas] = useState([]);

  useEffect(() => {
    authFetch(`/cajas/reservas/${ejer}`)
      .then((data) => setReservas(data))
      .catch((err) => {
        addToast("Error al cargar las reservas!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejer]);

  const handleSubmitReserva = (formData) => {
    switch (modalReserva.action) {
      case "EDIT":
        authFetch(`/cajas/reservas/${modalReserva.data.ID_RES}`, {
          method: "PUT",
          body: {
            ID_EJER: ejer,
            ID_EDIF: edif,
            ...formData,
            CAMBIO: formData.OBJ - modalReserva.data.OBJ !== 0,
          },
        })
          .then((data) => {
            return Promise.all([authFetch(`/cajas/reservas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)]);
          })
          .then(([dataReserva, dataFinanciero]) => {
            setReservas(dataReserva);
            setFinanciero(dataFinanciero);
          })
          .catch((err) => addToast("Error editando reservas", { appearance: "error" }));
        setModalReserva((prev) => ({ ...prev, show: false }));
        break;
      case "ADD":
        authFetch(`/cajas/reservas`, {
          method: "POST",
          body: { ID_EJER: ejer, ID_EDIF: edif, ...formData },
        })
          .then((data) => {
            return Promise.all([authFetch(`/cajas/reservas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)]);
          })
          .then(([dataReserva, dataFinanciero]) => {
            setReservas(dataReserva);
            setFinanciero(dataFinanciero);
          })
          .catch((err) => addToast("Error agregando reservas", { appearance: "error" }));
        setModalReserva((prev) => ({ ...prev, show: false }));
        break;

      default:
        break;
    }

    setModalReserva((prev) => ({ ...prev, show: false }));
  };
  const handleSubmitTrasRes = (formData) => {
    authFetch(`/cajas/reservas/trasRes`, {
      method: "POST",
      body: { ID_EJER: ejer, ID_EDIF: edif, ...formData },
    })
      .then((data) => {
        return Promise.all([authFetch(`/cajas/reservas/${ejer}`), authFetch(`/cajas/financiero/${edif}/${ejer}`)]);
      })
      .then(([dataReserva, dataFinanciero]) => {
        setReservas(dataReserva);
        setFinanciero(dataFinanciero);
      })
      .catch((err) => addToast("Error traspasando a reservas", { appearance: "error" }));
    setModalTrasRes((prev) => ({ ...prev, show: false }));
  };

  const tableData = [];
  const totalRes = {};
  const cajas = [];

  window.reserva = reservas;

  reservas.forEach((res) => {
    const resIndex = tableData.findIndex((a) => a.ID_RES === res.ID_RES);
    const cajaIndex = cajas.findIndex((a) => a.ID_CAJA === res.ID_CAJA);
    if (cajaIndex < 0 && res.ID_CAJA) cajas.push({ ID_CAJA: res.ID_CAJA, CAJA: res.CAJA });
    if (resIndex < 0) {
      totalRes[res.ID_RES] = 0;
      tableData.push({
        ID_RES: res.ID_RES,
        cells: [
          { content: <Actions data={res} setModalReserva={setModalReserva} ejerState={ejercicio.ESTADO} /> },
          { content: res.RESERVA },
          { className: "text-right", content: res.OBJ, order: res.OBJ },
        ],
      });
    }
  });

  tableData.forEach((res) => {
    cajas.forEach((caja) => {
      const mov = reservas.find((saldo) => saldo.ID_CAJA === caja.ID_CAJA && saldo.ID_RES === res.ID_RES);
      res.cells.push({ className: "text-right", content: customNumber(mov?.MONTO || 0), order: mov?.MONTO || 0 });
      totalRes[res.ID_RES] += mov?.MONTO || 0;
    });
  });

  Object.keys(totalRes).forEach((tot) => {
    const reserva = tableData.findIndex((res) => res.ID_RES === parseInt(tot));
    if (reserva > -1) tableData[reserva].cells.push({ className: "text-right", content: customNumber(totalRes[tot]) });
  });

  return (
    <div style={style}>
      <Panel
        header={<Header setModalReserva={setModalReserva} setModalTrasRes={setModalTrasRes} ejer={ejer} edif={edif} />}
      >
        <Table
          data={tableData}
          columns={[
            { content: "Acciones", className: "actions-col" },
            { content: "Reservas", className: "" },
            { content: "Objetivo", className: "" },
            ...cajas.map((ca) => ({ content: ca.CAJA })),
            { content: "Constituido", className: "" },
          ]}
        />
      </Panel>
      <ModalReserva
        modalReserva={modalReserva}
        setModalReserva={setModalReserva}
        handleSubmitReserva={handleSubmitReserva}
      />
      <ModalTrasRes
        modalTrasRes={modalTrasRes}
        setModalTrasRes={setModalTrasRes}
        handleSubmitTrasRes={handleSubmitTrasRes}
        ejer={ejer}
        reservas={reservas}
      />
    </div>
  );
};

const Actions = ({ ejerState, setModalReserva, data }) => {
  if (ejerState === 1) {
    return (
      <Button
        icon
        size="xs"
        onClick={() =>
          setModalReserva({
            show: true,
            action: "EDIT",
            data,
          })
        }
      >
        <Icon icon="edit" />
      </Button>
    );
  } else {
    return null;
  }
};

const Header = ({ setModalReserva, setModalTrasRes, edif, ejer }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Estado Reserva</span>
        <Button
          size="xs"
          onClick={() =>
            setModalReserva({
              show: true,
              action: "ADD",
              data: {},
            })
          }
        >
          Agregar
        </Button>
        <Button
          size="xs"
          style={{ marginLeft: 5 }}
          onClick={() =>
            setModalTrasRes({
              show: true,
              action: "ADD",
              data: {},
            })
          }
        >
          Transferir
        </Button>
      </div>
    </div>
  );
};

export default Reservas;
