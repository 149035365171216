import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";
import Panel from "../../components/Panel";
import { Table } from "../../components/Table";
import CheckedPills from "../../components/CheckedPills";
import Icon from "../../components/Icon";
import Button from "../../components/Button";

const ModalRules = ({ modal, setModal, edif, setModalEdit, deleteRule }) => {
  const { addToast } = useToasts();
  const [info, setInfo] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filters, setFilters] = useState({ PAGOS: true, UNIDADES: true, WORKING: true });

  useEffect(() => {
    if (modal.show)
      authFetch(`/banco/rules/${edif}`)
        .then((data) => setInfo(data))
        .catch((err) => {
          addToast("Error al cargar reglas!", { appearance: "error" });
        });
    //eslint-disable-next-line
  }, [edif, modal.show, modal.reset]);

  const changeFilter = (key) => {
    const newFilter = { ...filters, [key]: !filters[key] };
    window.localStorage.setItem("banco-filters", JSON.stringify(newFilter));
    setFilters(newFilter);
  };

  const dataTableProv = info
    .sort((a, b) => (!(a.TITULO_DEF || a.TITULO) && (a.TITULO_DEF || a.TITULO) ? -1 : a.NOMBRE >= b.NOMBRE ? 1 : -1))
    .filter((a) => a.TIPO === "PAGO" && (a.TITULO_DEF || a.TITULO || !filters.WORKING))
    .map((row) => {
      return {
        cells: [
          {
            className: "actions-col",
            content: <Actions setModalEdit={setModalEdit} row={row} deleteRule={deleteRule} />,
          },
          { content: row.NOMBRE, order: row.NOMBRE },
          { content: row.DESCRIPCION, order: row.DESCRIPCION },
          { content: row.LEYENDAS, order: row.LEYENDAS },
          { content: row.TITULO_DEF || row.TITULO, order: row.TITULO_DEF || row.TITULO },
        ],
      };
    });

  const dataTableUnid = info
    .filter((a) => a.TIPO === "INGRESO")
    .map((row) => {
      return {
        cells: [
          {
            className: "actions-col",
            content: <Actions setModalEdit={setModalEdit} row={row} deleteRule={deleteRule} />,
          },
          { content: `${row.DEPTO} (${row.UF})`, order: row.UF },
          { content: row.DESCRIPCION, order: row.DESCRIPCION },
          { content: row.LEYENDAS, order: row.LEYENDAS },
        ],
      };
    });

  return (
    <CustomModal disabled show={modal.show} title={`Ver Reglas`} setModal={setModal} size="lg">
      <Panel
        flat
        bordered
        header={
          <Header
            filter={filter}
            setFilter={setFilter}
            filters={filters}
            changeFilter={changeFilter}
            setModalEdit={setModalEdit}
          />
        }
      >
        {filters.PAGOS && (
          <Table
            filterStatus={filter}
            columns={[
              { content: "Acciones", className: "actions-col", sortable: false, filterable: false },
              { content: "Proveedor", sortable: true, filterable: true },
              { content: "Descripcion", sortable: true, filterable: true },
              { content: "Leyenda", sortable: true, filterable: true },
              { content: "Titulo default", sortable: true, filterable: true },
            ]}
            data={dataTableProv}
            style={{ minWidth: 630 }}
          />
        )}
        {filters.PAGOS && filters.UNIDADES && (
          <div style={{ height: 20, border: "1px solid #f2f2f5", borderWidth: "1px 0" }} />
        )}
        {filters.UNIDADES && (
          <Table
            filterStatus={filter}
            columns={[
              { content: "Acciones", className: "actions-col", sortable: false, filterable: false },
              { content: "Unidad", sortable: true, filterable: true },
              { content: "Descripcion", sortable: true, filterable: true },
              { content: "Leyenda", sortable: true, filterable: true },
            ]}
            data={dataTableUnid}
            style={{ minWidth: 630 }}
          />
        )}
      </Panel>
    </CustomModal>
  );
};

const Header = ({ filters, changeFilter, setFilter, filter, setModalEdit }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 10 }}>Reglas</span>
        <CheckedPills checked={filters.PAGOS} handleClick={() => changeFilter("PAGOS")}>
          Pagos
        </CheckedPills>
        <CheckedPills checked={filters.UNIDADES} handleClick={() => changeFilter("UNIDADES")}>
          Unidades
        </CheckedPills>
        <CheckedPills checked={filters.WORKING} handleClick={() => changeFilter("WORKING")}>
          Activas
        </CheckedPills>
      </div>
      <div>
        <Button
          size="xs"
          style={{ marginRight: 5 }}
          onClick={() => setModalEdit((prev) => ({ ...prev, action: "ADD", show: true }))}
          active={filter}
        >
          Agregar
        </Button>
        <Button size="xs" color="secondary" onClick={() => setFilter((prev) => !prev)} active={filter}>
          <Icon icon="filter" />
        </Button>
      </div>
    </div>
  );
};

const Actions = ({ setModalEdit, deleteRule, row }) => {
  return (
    <>
      <Button size="xs" icon color="green" onClick={() => setModalEdit({ show: true, data: row, action: "EDIT" })}>
        <Icon icon="edit" />
      </Button>
      <Button size="xs" color="red" icon onClick={() => deleteRule(row)}>
        <Icon icon="trash" />
      </Button>
    </>
  );
};

export default ModalRules;
