import React, { useState, useEffect, useRef } from "react";
import CustomModal from "../../components/Modal";
import Input, { DateInput, NumberInput } from "../../components/Input";
import { useForm } from "react-hook-form";
import ProvSelect from "./ProvSelect";
import authFetch from "../../hooks/authFetch";
import { useToasts } from "react-toast-notifications";

const Modal = ({ modal, edif, ejerState, setModal, handleSubmit: handleSubmitModal }) => {
  const { addToast } = useToasts();
  const { register, setValue, getValues, errors, handleSubmit, reset } = useForm();
  const onSubmit = handleSubmit((data) => handleSubmitModal({ ...data, payNow: payNowInput.current?.checked }));
  const [proveedores, setProveedores] = useState([]);

  const payNowInput = useRef(undefined);

  useEffect(() => {
    authFetch(`/proveedores/select/${edif}`)
      .then((data) => {
        setProveedores(data);
        reset({ ID_PROV: modal.data.ID_PROV });
      })
      .catch((err) => {
        addToast("Error al cargar proveedores!", { appearance: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edif]);

  useEffect(() => {
    reset({ ID_PROV: modal.data.ID_PROV });
  }, [modal.data.ID_PROV, reset]);

  return (
    <CustomModal
      show={modal.show}
      title={modal.action === "ADD" ? `Agregar Gasto` : `Editar Gasto: ${modal.data.TITULO}`}
      setModal={setModal}
      handleSubmit={onSubmit}
      disabled={parseInt(ejerState) !== 1}
    >
      <form onSubmit={onSubmit} style={{ marginBottom: -15 }}>
        <ProvSelect
          data={proveedores.map((prov) => ({
            ...prov,
            label: `${prov.NOMBRE}${(prov.DESCRIPCION || "").length > 0 ? ` (${prov.DESCRIPCION})` : ""}`,
            value: prov.ID_PROV,
          }))}
          name="ID_PROV"
          label="Proveedor"
          autoFocus
          setValue={setValue}
          getValues={getValues}
          value={modal.data.ID_PROV}
        />
        <input
          style={{ display: "none" }}
          defaultValue={modal.data.ID_PROV}
          name="ID_PROV"
          ref={register({ required: true })}
        />
        <input
          style={{ display: "none" }}
          defaultValue={modal.data.PROVEEDOR}
          name="PROVEEDOR"
          ref={register({ required: true })}
        />
        <Input
          label="Factura"
          name="FACTURA"
          value={modal.data.FACTURA}
          register={register}
          disabled={parseInt(ejerState) !== 1}
        />
        <Input
          label="Titulo"
          name="TITULO"
          errors={errors}
          errMsg={`Titulo requerido`}
          value={modal.data.TITULO}
          register={register({ required: true })}
          disabled={parseInt(ejerState) !== 1}
        />
        <NumberInput
          label="Monto"
          name="MONTO"
          errors={errors}
          errMsg={`Monto requerido`}
          value={modal.data.MONTO}
          register={register({ required: true })}
          disabled={parseInt(ejerState) !== 1}
        />
        <DateInput
          label="Fecha"
          name="FECHA"
          errors={errors}
          errMsg="Fecha Requerido"
          value={modal.data.FECHA}
          autoComplete={{ mm: true, yyyy: true }}
          register={register({ required: true })}
          disabled={parseInt(ejerState) !== 1}
        />
        <Input
          label="Descripcion"
          name="DESCRIPCION"
          value={modal.data.DESCRIPCION}
          register={register}
          disabled={parseInt(ejerState) !== 1}
          tabIndex={-1}
        />
        {modal.action === "ADD" && !modal.hasToPayNow && (
          <Input label="Pagar Ahora" register={payNowInput} name="MOSTRAR" type="checkbox" defaultChecked={true} />
        )}

        <input type="submit" style={{ display: "none" }} />
      </form>
    </CustomModal>
  );
};

export default Modal;
